<template>
  <Menu as="div" class="relative inline-block text-left min-w-44">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="md:inline-flex justify-center w-full  shadow-sm px-3
        py-1 text-sm font-medium text-white hover:bg-white-50 hidden">
        <template class="flex" v-if="activeCompany">
          <img class="w-10 h-10 rounded-lg mr-2" :src="'https://leadbox.ec/storage/'+activeCompany.logo"
               :alt="activeCompany.name + ' logo'" @error="useCompanyFallbackImage">
          <div class="flex flex-col">
            <p class="ml-2 text-company text-left  w-36 mt-2">{{ activeCompany.name }}</p>
<!--            <p class="ml-2 text-company text-left  w-36" v-if="type === 'leads'">-->
<!--              Leads: <span>{{ activeCompany.active_leads }}</span> / {{ activeCompany.closed_leads }}</p>-->
<!--            <p class="ml-2 text-company text-left  w-36" v-if="type === 'chat'">-->
<!--              Chats: {{ activeCompany.active_conversations }}</p>-->
          </div>

        </template>
        <template v-else>
          <p>Empresas</p>
        </template>
        <ChevronDownIcon class="-mr-1 md:ml-2 ml-1 my-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
      <MenuButton
        class="inline-flex justify-center w-full  shadow-sm px-1
        py-3 text-sm font-medium text-white hover:bg-white-50 md:hidden">
        <template class="flex" v-if="activeCompany">
          <img class="w-10 h-10 rounded-lg md:mr-2 mr-0 logo-company" :src="'https://leadbox.ec/storage/'+activeCompany.logo"
               :alt="activeCompany.name + ' logo'" @error="useCompanyFallbackImage">
        </template>
        <template v-else>
          <p>Empresas</p>
        </template>
        <ChevronDownIcon class="-mr-1 ml-2 my-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute left-0 md:mt-2 md:w-80 w-52 max-h-96 z-20 overflow-auto shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none h-max-screen overflow-auto company-name overflow-x-hidden">
        <div class="py-1 w-full">
          <MenuItem v-for="(company, key) in companies" @click="selectActiveCompany(company)">
            <a href="#"
               :class="[company.uuid === activeCompany?.uuid ? 'bg-gray-900 text-white' : 'text-white', 'flex px-4 py-2 text-sm flex border-b border-gray-600']">
              <img class="mt-0 w-10 h-10 rounded-lg mr-4" :src="'https://leadbox.ec/storage/'+company.logo"
                   :alt="company.name+' logo'" @error="useCompanyFallbackImage">
              <div class="flex flex-col text-ellipsis overflow-hidden">
                <p class="m-2 my-1 break-normal text-company w-fill mt-2">{{ company.name }}</p>
<!--                <p class="ml-2 break-normal text-company w-fill" v-if="type === 'leads'">-->
<!--                  Leads: <span>{{ company.active_leads }}</span> / {{ company.closed_leads }}</p>-->
<!--                <p class="ml-2 break-normal text-company w-fill" v-if="type === 'chat'">-->
<!--                  Chats: {{ company.active_conversations }}</p>-->
              </div>
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  ChevronDownIcon,
} from '@heroicons/vue/solid';
import { useCompanyFallbackImage } from '@/utils/mixins';

export default {
  name: 'CompanyDropDown',
  mixins: [useCompanyFallbackImage],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  props: {
    type: String,
  },
  methods: {
    selectActiveCompany(company) {
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.$emit('changed-company');
        }, (error) => {
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
  },
  computed: {
    companies() {
      return this.$store.state.companies;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>
<style>
  .company-name{
    background: #2a323c;
    color: #fff;
  }
  .text-company{
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
  }

  .text-company span {
    color: #FFCF01;
  }
  button:focus {
    outline: none !important;
  }

  /*Scroll*/
  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: #8ad2e8;
  }

  .overflow-auto::-webkit-scrollbar-track {
    background: #2a323c;
  }

  .overflow-auto::-webkit-scrollbar {
    width: 5px;
  }
  .logo-company{
    margin-top: -5px;
  }
</style>
