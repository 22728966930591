<template>
  <div class="flex hover:bg-gray-500 cursor-pointer text-white md:w-52" @click="selectStatus()"
       @keydown="selectStatus()" v-bind:class="{ 'bg-gray-500' : isSelected }">
    <div class="md:pl-1 pl-1 pr-1 p-2 w-9/12 text-left
  text-white break-words text-sidebar break-normal">
      {{ status.name }}
    </div>
    <div class="p-2 w-fill">
      <div class="rounded-full text-white p-1 px-2 text-xs" :style="style">{{ status.number }}</div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'StatusItem',
  props: {
    status: Object,
    type: String,
    selected: Number,
  },
  data() {
    return {
      style: {
        'background-color': this.status.color,
      },
    };
  },
  methods: {
    selectStatus() {
      this.$emit('set-status', this.status, this.type);
    },
  },
  computed: {
    isSelected() {
      if (this.status) {
        return this.status.id === this.selected;
      }
      return false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.bg-gray-500 {
  background-color: #2a6f7f7a;
  border-radius: 5px;
}

.text-sidebar {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}

.py-1.flex.hover\:bg-gray-500.cursor-pointer.bg-gray-500 {
  background-color: #2A6F7F;
}

.py-1.flex.hover\:bg-gray-500.cursor-pointer.bg-gray-500:hover {
  background-color: #2A6F7F;
}

@media (max-width: 600px) {
  .vcp__body-content[data-v-64321428] {
    padding: 12px 1px;
  }
}
</style>
