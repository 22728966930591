<template>
  <Transition name="fade">
<!--eslint-disable-next-line-->
    <div
      v-if="showing"
      class="fixed inset-0 z-10 w-full h-screen flex items-center justify-center"
      @click.self="close" :style="{'background-color': 'rgba(0,0,0,0.8)'}"
    >
      <slot />
    </div>
  </Transition>
</template>

<script>

export default {
  name: 'ModalBox',
  components: {
  },
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    showing(value) {
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden');
      }
      return document.querySelector('body').classList.remove('overflow-hidden');
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
