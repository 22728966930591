<template>
  <!--  eslint-disable-->
  <div class="w-full bg-white h-screen flex flex-col">
    <div class="flex-grow items-center justify-center">
      <div class="w-full px-4 flex items-center justify-center">
        <template v-if="isLoading">
          <div class="flex flex-col">
            <div class="w-full flex justify-center">
              <img alt="loading" src="@/assets/loading.gif" class="w-20">
            </div>
            <p class="text-lg text-black text-center object-none object-center ">
              Validando credenciales...
            </p>
          </div>
        </template>
        <template v-else>
          <div class=" md:flex w-full container mx-auto top-content desktop">
            <div  class="w-full md:w-1/2 md:mt-10  md:px-8 px-4">
              <form class="mt-8 space-y-6 md:mx-24" method="POST">
              <input type="hidden" name="remember" value="true">
              <div class="rounded-md shadow-sm -space-y-px">
                <div>
                  <img alt="leadbox logo my-6" class="w-60 mx-auto center my-10" src="../assets/logo.svg">
                  <input id="email" name="email" type="email" autocomplete="email" required
                         v-model="form.email"
                         class="my-6 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                         placeholder="Usuario / Email">
                </div>
                <div>
                  <input id="password-desktop" name="password" type="password" autocomplete="current-password"
                         required v-model="form.password"
                         class="my-6 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                         placeholder="Password">
                </div>
              </div>

              <p v-if="error">{{ message }}</p>
              <p v-if="success">{{ message }}</p>

              <div>
                <button @click="login($event)"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
        <!--    <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clip-rule="evenodd"/>
            </svg>-->
          </span>
                  Ingresar
                </button>
              </div>
            </form>
            </div>
            <div class="w-full md:w-1/2"  >
              <div class="loginCarousel">
                <div>
                <carousel
                  class="py-4"
                  pauseAutoplayOnHover
                  :autoplay="5000"
                  :items-to-show="1"
                  :wrap-around="true"
                >
                  <slide key="1"> <img alt="leadbox logo my-6" class="img-slider mx-auto center my-10" src="../assets/login-1.png">
                    <div class="text-slider absolute">
                    <p class="text">“La buena suerte<br>
                     no necesita explicación”<br>
                     <span>Shirley Temple Black</span></p>
                  </div>
                  </slide>
                  <slide key="2"> <img alt="leadbox logo my-6" class="img-slider mx-auto center my-10" src="../assets/login-2.png"><div class="text-slider absolute">
                    <p class="text">“El primer contacto de un lead debe tardar,<br>
                      lo que demora un café en enfriar”<br>
                      <span>LeadBox</span></p>
                  </div> </slide>
                  <slide key="3"> <img alt="leadbox logo my-6" class="img-slider mx-auto center my-10" src="../assets/login-3.png"> <div class="text-slider absolute">
                    <p class="text">“El 45% de lideres comerciales<br>
                      se enfocan en mejor la experiencia del cliente”<br>
                      <span>LeadBox</span></p>
                  </div></slide>

                  <template #addons>
                    <pagination />
                  </template>
                </carousel>
                </div>
              </div>
            </div>
          </div>
          <div class=" md:flex w-full container mx-auto top-content mobile">
            <div class="w-full md:w-1/2" >
              <div class="loginCarousel">
                <div class="">
                  <carousel
                    class="py-4"
                    :autoplay="5000"
                    pauseAutoplayOnHover
                    :items-to-show="1"
                    :wrap-around="true"
                  >
                    <slide key="1"> <img alt="leadbox logo my-6" class="logo img-slider-mobile mx-auto center my-10" src="../assets/slider-mobile-1.png">
                      <div class="text-slider absolute">
                        <p class="text">“La buena suerte<br>
                          no necesita explicación”<br>
                          <span>Shirley Temple Black</span></p>
                      </div>
                    </slide>
                    <slide key="2"> <img alt="leadbox logo my-6" class="img-slider-mobile mx-auto center my-10" src="../assets/slider-mobile-2.png"><div class="text-slider absolute">
                      <p class="text">“El primer contacto de un lead debe tardar,<br>
                        lo que demora un café en enfriar”<br>
                        <span>LeadBox</span></p>
                    </div> </slide>
                    <slide key="3"> <img alt="leadbox logo my-6" class="img-slider-mobile mx-auto center my-10" src="../assets/slider-mobile-3.png"> <div class="text-slider absolute">
                      <p class="text">“El 45% de lideres comerciales<br>
                        se enfocan en mejor la experiencia del cliente”<br>
                        <span>LeadBox</span></p>
                    </div></slide>

                    <template #addons>
                      <pagination />
                    </template>
                  </carousel>
                </div>
              </div>
            </div>
            <div  class="w-full md:w-1/2 md:mt-10  md:px-8 px-4">
              <form class="mt-8 space-y-6 md:mx-24" method="POST">
                <input type="hidden" name="remember" value="true">
                <div class="rounded-md shadow-sm -space-y-px">
                  <div>
                    <img alt="leadbox logo my-6" class="w-60 mx-auto center my-10" src="../assets/logo.svg">

                    <input id="email-address" name="email" type="email" autocomplete="email" required
                           v-model="form.email"
                           class="my-6 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                           placeholder="Usuario / Email">
                  </div>
                  <div>
                    <input id="password" name="password" type="password" autocomplete="current-password"
                           required v-model="form.password"
                           class="my-6 appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                           placeholder="Password">
                  </div>
                </div>

                <p v-if="error">{{ message }}</p>
                <p v-if="success">{{ message }}</p>

                <div>
                  <button @click="login($event)"
                          class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <span class="absolute left-0 inset-y-0 flex items-center pl-3">
            <!-- Heroicon name: solid/lock-closed -->
            <!--    <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                     aria-hidden="true">
                  <path fill-rule="evenodd"
                        d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                        clip-rule="evenodd"/>
                </svg>-->
          </span>
                    Ingresar
                  </button>
                </div>
              </form>
            </div>

          </div>

        </template>


      </div>
    </div>
    <p class="center text-center">Integramos todos tus canales digitales</p>
    <div class="flex items-center h-20 icons-logos container mx-auto justify-center ">

      <img alt="facebook logo" src="../assets/leadbox-facebook.png">
      <img alt="instagram logo" src="../assets/leadbox-instagram.png">
      <img alt="linkedin logo" src="../assets/leadbox-linkedin.png">
      <img alt="messenger logo" src="../assets/leadbox-messenger.png">
      <img alt="telegram logo" src="../assets/leadbox-telegram.png">
      <img alt="tiktok logo" src="../assets/leadbox-tiktok.png">
      <img alt="whatsapp logo" src="../assets/leadbox-whatsapp.png">
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {
  Carousel, Pagination, Slide,
} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Logi nView',
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      error: false,
      message: null,
      success: false,
      clicked: false,
      isLoading: false,
    };
  },
  computed: {
    companies() {
      return this.$store.state.companies;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        // this.$vs.notify({
        //   title: 'Intento de Login',
        //   text: 'Ya has ingresado!',
        //   iconPack: 'feather',
        //   icon: 'icon-alert-circle',
        //   color: 'warning'
        // })

        return false;
      }
      return true;
    },
    setActiveCompany() {
      const selectedCompany = localStorage.getItem('companyUuid');
      let company = null;
      if (selectedCompany) {
        const index = this.companies.findIndex((comany) => comany.uuid === selectedCompany);
        company = this.companies[index];
      } else {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      if (!company) {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.$notify({
            type: 'success',
            title: 'Loggeo Exitoso',
            text: `Bienvenid@ ${this.activeUser.name}`,
          });
          this.$router.push(response.data.query).catch(() => {
          });
        }, (error) => {
          this.isLoading = false;
          console.log('status', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
          console.log('error', error);
        });
    },
    login(event) {
      event.preventDefault();
      // if (!this.checkLogin()) return;
      console.log(this.form);
      this.error = false;
      this.success = false;
      this.message = null;
      if (this.form.email === null || this.form.password === null) {
        this.error = true;
        this.message = 'Los campos son requeridos';
        return;
      }
      this.isLoading = true;
      const payload = {
        email: this.form.email,
        password: this.form.password,
      };
      this.$store.dispatch('login', payload)
        .then((response) => {
          console.log('respuesta', response);
          this.setActiveCompany();
        }, (error) => {
          this.isLoading = false;
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
  },
});
</script>

<style>

  .carousel__next {
    right: 10%;
  }

  .carousel__prev {
    left: 10%;
  }
  .carousel__item {
    min-height: 200px;
    width: 100%;
    background-color: red;
    color:  white;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .carousel__slide {
    padding: 10px;
  }

  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 5px solid white;
  }
  .bg-indigo-600 {
    background: #1ad4c6 !important;
    border-radius: 15px;
  }

  .icons-logos img {
    max-width: 55px;
    text-align: center;
  }

  p.text {
    color: #2a6f7f;
  }
  p.text span {
    color: #09dddd;
  }
  .carousel__pagination {
    margin-top: 30px;
  }
  .img-slider{
    width: 100%;
    max-width: 320px;
    text-align: center;
    margin: 0 auto;
    display: block;
  }
  .img-slider-mobile{
    width: 100%;
    max-width: 90px;
    text-align: center;
    margin: 0 auto;
    display: block;
  }
  .carousel__pagination-button {
    background-color: #00bcd4;
  }
  .carousel__pagination-button--active {
    background-color: #0a494c;
  }

  @media only screen and (min-width: 768px) {
    .top-content.mobile {
      display: none;
    }
    .carousel__viewport {
      height: 390px;
    }
    .text-slider {
      bottom: -50px;
    }
    .carousel__pagination {
      margin-top: 5px;
    }
  }

  @media only screen and (max-width: 767px) {
    img.w-60.mx-auto.center.my-10 {
      max-width: 180px;
    }
    .top-content.desktop {
      display: none;
    }
    div#orderOne {
      order: 2;
    }
    .carousel__viewport {
      height: 220px;
    }

    .text-slider {
      bottom: -95px;
    }
    .icons-logos img {
      max-width: 40px;
      text-align: center;
    }
    .carousel__pagination {
      margin-top: -5px;
    }
  }

</style>
