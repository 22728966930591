<template>
  <Menu as="div" class="relative inline-block text-left ">
    <div>
      <MenuButton
        class="inline-flex justify-center w-full text-sm cursor-pointer font-medium text-gray-700">
        <template v-if="prob">
          <div class="rounded-full text-white self-center p-3 m-1"
               :style="{ 'background-color' : prob?.color }">
          </div>
        </template>
        <template v-else>
          <div class="rounded-full text-white self-center p-3 bg-gray-400">
          </div>
        </template>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-44 shadow-lg bg-teal-900 z-20 ring-1
        ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }" @click="setProbability(null)" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' :
            'text-white', 'block p-2 text-sm flex']">
              <div class="rounded-full text-white self-center p-3 bg-gray-400">
              </div>
              <p class="m-1 text-sm">Todos</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setProbability(probability)" class="cursor-pointer"
                    v-for="probability in probabilities" v-bind:key="probability.id">
            <div :class="[active ? 'bg-gray-800 text-white' :
            'text-white', 'block p-2 text-sm flex']">
              <div class="rounded-full text-white self-center p-3"
                   :style="{ 'background-color' : probability?.color }">
              </div>
              <p class="m-1 text-sm">{{probability.name}}</p>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';

export default {
  name: 'ProbabilitiesDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
  },
  props: {
    prob: Object,
  },
  methods: {
    setProbability(probability) {
      this.$emit('set-probability', probability);
    },
  },
  computed: {
    probabilities() {
      return this.$store.state.probabilities;
    },
  },
};
</script>
<style>
</style>
