<template>
  <Menu as="div" class="relative inline-block text-left ">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="inline-flex justify-center w-full text-sm cursor-pointer font-medium text-gray-700"
        :class="[filter !== null ? 'border-b-4 border-leadbox-600':'']">
        <FilterIcon class="w-6 m-1 " aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-44 shadow-lg bg-teal-900 z-20 ring-1
        ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }" @click="setFilter(null)" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <CheckIcon v-if="filter === null" class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm">Todos</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setFilter(true)" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <CheckIcon v-if="filter === true" class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm">Con leads</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setFilter(false)" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <CheckIcon v-if="filter === false" class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm">Sin leads</p>
            </div>
          </MenuItem>

        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  FilterIcon, CheckIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'FiltersDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    FilterIcon,
    CheckIcon,
  },
  props: {
    filter: String,
  },
  methods: {
    setFilter(filter) {
      this.$emit('set-filter', filter);
    },
  },
};
</script>
<style>
  .bg-teal-900 {
    background: #2A6F7F;
  }
</style>
