<template>
  <div class="w-full flex flex-col p-2 ">
    <div class="w-full justify-between flex border-leadbox-900 border-b-4 p-2">
      <h1 class="text-xl font-semibold">Conversaciones por ciudades</h1>
      <button
        class="text-sm font-medium text-gray-700 cursor-pointer bg-gray-200 rounded-md
        border border-black"
        @click="getConversationsByCities">
        <RefreshIcon class="m-2 h-5 w-5" aria-hidden="true"/>
      </button>
    </div>
    <div class="p-4">
      <template v-if="isLoading">
        <div class="w-full flex justify-center">
          <img alt="loading" src="@/assets/loading.gif" class="w-20">
        </div>
      </template>
      <template v-else>
        <div class="flex justify-center py-4">
          <template v-if="isLoadingExcel">
            <div class="flex-col justify-center">
              <img alt="loading" src="@/assets/loading.gif" class="w-20">
              <p>Generando descarga...</p>
            </div>
          </template>
          <template v-else>
            <button @click="downloadConversations"
                    class="w-full md:w-1/3 group relative w-full flex justify-center py-2 px-4
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600 border" type="button">
              Descargar reporte
            </button>
          </template>
        </div>
        <div class="flex justify-center py-4">
          <Doughnut :data="data" :options="options" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>

// import PaginationItem from '@/components/general/PaginationItem.vue';
import {
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Doughnut } from 'vue-chartjs';
import { utils, writeFileXLSX } from 'xlsx';

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: 'PieConversationByCities',
  displayName: 'PieConversationByCities',
  components: { RefreshIcon, Doughnut },
  props: {
    dateValue: Object,
    tab: String,
  },
  data() {
    return {
      conversations: [],
      connectionChats: [],
      selectedConnection: null,
      total: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      },
      isLoading: false,
      isDetail: false,
      isLoadingExcel: false,
      isLoadingInside: false,
    };
  },
  mounted() {
    if (this.activeCompany) {
      this.getConversationsByCities();
    }
  },
  watch: {
    tab(value) {
      if (value === 'chat') {
        if (this.activeCompany) {
          this.getConversationsByCities();
        }
      }
    },
    dateValue(val) {
      console.log('val', val);
      this.getConversationsByCities();
    },
    activeCompany(company) {
      if (company) {
        this.getConversationsByCities();
      }
    },
  },
  methods: {
    downloadConversations() {
      try {
        this.isLoadingExcel = true;
        const data = this.conversations.map((conversation) => {
          const newConversation = {};
          newConversation.identificador = conversation.uuid;
          newConversation.nombre = conversation.name;
          newConversation.iniciado_por = conversation.business_initiated ? 'empresa' : 'cliente';
          newConversation.nombre_conexion = conversation.connection_name;
          newConversation.numero_origen = conversation.connection_phone_number;
          newConversation['fecha_creación'] = conversation.created_at.date;
          newConversation['ultima_interación'] = conversation.formatted_last_interaction;
          newConversation.numero_leads = conversation.leads_count;
          newConversation.servicio = conversation.service;
          newConversation.asesor_asignado = conversation.user_name;
          newConversation.Ciudad = conversation.sender_city;
          newConversation.telefono = conversation.sender_phone;
          newConversation.ci = conversation.sender_document;
          newConversation.nombre_almacenado = conversation.sender_name;
          newConversation.email = conversation.sender_email;
          newConversation.numero_secundario = conversation.sender_secondary_phone;
          if (conversation.fields) {
            // eslint-disable-next-line
            for (const [key, value] of Object.entries(JSON.parse(conversation.fields))) {
              if (key !== 'form' && key !== 'rule') {
                newConversation[`field${key}`] = value;
              }
            }
          }
          return newConversation;
        });
        console.log('data', data);
        const wb = utils.book_new();
        const ws = utils.json_to_sheet(data);
        utils.book_append_sheet(wb, ws, `conversations${new Date().getTime()}`);
        writeFileXLSX(wb, `conversations${new Date().getTime()}.xlsx`);
        this.isLoadingExcel = false;
      } catch (e) {
        console.log('error', e);
      }
    },
    getBackgrounds(numColores) {
      const colores = [];

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < numColores; i++) {
        const color = this.getRandomColor(); // Genera un color aleatorio
        colores.push(color); // Agrega el color al array
      }
      return colores;
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getConversationsByCities() {
      this.isLoading = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('reportsConversationsByCities', payload)
        .then((response) => {
          console.log('response reportsConversationsByCities', response);
          this.conversations = response.data.success;
          const groupedConversations = this.conversations.reduce((acc, person) => {
            let city = person.sender_city;
            if (person.sender_city === null) {
              city = 'Sin ciudad';
            }
            if (!acc[city]) {
              acc[city] = 0;
            }
            // eslint-disable-next-line no-plusplus
            acc[city]++;
            return acc;
          }, {});
          console.log('grouped Conversations', groupedConversations);
          this.data.labels = Object.keys(groupedConversations);
          this.data.datasets[0].data = Object.values(groupedConversations);
          this.data.datasets[0].backgroundColor = this.getBackgrounds(this.data.labels.length);
          this.isLoading = false;
          console.log('respuesta reportsConversationsByCities', response);
        }, (error) => {
          console.log('error reportsConversationsByCities', error);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error reportsConversationsByCities', error);
        });
    },
  },
  computed: {
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>

<style scoped>

</style>
