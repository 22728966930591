<template>
  <Menu as="div" class=" relative text-left ">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="rounded-r-lg border-l border-white m-2 ml-0 my-1 text-white justify-center text-sm
        cursor-pointer bg-teal-900" data-toggle="tooltip" data-placement="top"
        title="Mensajes especiales" alt="Mensajes especiales">
        <ChevronUpIcon class="h-6 w-4 m-2 " aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-bottom-right absolute bottom-14 right-0 mt-2 w-64 rounded-md shadow-lg
        bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
        <div class="py-1 flex flex-col">
          <div class="flex justify-between flex-wrap">
            <MenuItem @click="openModal('interactive')">
              <div class="p-3 w-1/3 cursor-pointer" >
                <img class="w-10 h-10 rounded-lg justify-self-center img-icon" src="@/assets/interactivo.png"
                     alt="interactivo">
                <p class="text-black text-sm justify-self-center text-icons">Interactivo</p>
              </div>
            </MenuItem>
            <MenuItem @click="openModal('file')">
              <div class="p-3 w-1/3 cursor-pointer" >
                <img class="w-10 h-10 rounded-lg justify-self-center img-icon" src="@/assets/archivo.png"
                     alt="interactivo">
                <p class="text-black text-sm justify-self-center text-icons">Archivo</p>
              </div>
            </MenuItem>
            <MenuItem @click="openModal('product')">
              <div class="p-3 w-1/3 cursor-pointer" >
                <img class="w-10 h-10 rounded-lg justify-self-center img-icon" src="@/assets/producto.png"
                     alt="interactivo">
                <p class="text-black text-sm justify-self-center text-icons">Producto</p>
              </div>
            </MenuItem>
            <MenuItem @click="openModal('url')" v-if="isWhatsapp">
              <div class="p-3 w-1/3 cursor-pointer" >
                <img class="w-10 h-10 rounded-lg justify-self-center img-icon" src="@/assets/url.png"
                     alt="interactivo">
                <p class="text-black text-sm justify-self-center text-icons">Url</p>
              </div>
            </MenuItem>
            <MenuItem @click="openModal('location')" v-if="isWhatsapp">
              <div class="p-3 w-1/3 cursor-pointer" >
                <img class="w-10 h-10 rounded-lg justify-self-center img-icon" src="@/assets/ubicacion.png"
                     alt="interactivo">
                <p class="text-black text-sm justify-self-center text-icons">Ubicación</p>
              </div>
            </MenuItem>
            <MenuItem  @click="openModal('bot')">
              <div class="p-3 w-1/3 cursor-pointer">
                <img class="w-10 h-10 rounded-lg justify-self-center img-icon" src="@/assets/bot.png"
                     alt="interactivo">
                <p class="text-black text-sm justify-self-center text-icons">Iniciar Bot</p>
              </div>
            </MenuItem>
<!--            <MenuItem  @click="openModal('replies')">-->
<!--              <div class="p-3 w-1/3 cursor-pointer">-->
<!--                <img class="w-10 h-10 rounded-lg justify-self-center img-icon" src="@/assets/replies.png"-->
<!--                     alt="respuestas rápidas">-->
<!--                <p class="text-black text-sm justify-self-center text-icons">Repuestas Rápidas</p>-->
<!--              </div>-->
<!--            </MenuItem>-->
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItems, MenuItem,
} from '@headlessui/vue';
import {
  ChevronUpIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'MessagesDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    ChevronUpIcon,
  },
  data() {
    return {
      type: null,
    };
  },
  props: {
  },
  methods: {
    openModal(type) {
      this.$emit('open-modal', type);
    },
  },
  computed: {
    activeConversation() {
      return this.$store.state.activeConversation;
    },
    isWhatsapp() {
      return this.activeConversation.form_provider === 'whatsapp'
      || this.activeConversation.form_provider === 'dialog360';
    },
  },
};
</script>
<style>
  .bg-teal-900 {
    background: #2A6F7F;
  }
  .text-icons {
    font-size: 12px;
    text-align: center;
  }
  .img-icon {
    text-align: center;
    margin: 0 auto;
  }
</style>
