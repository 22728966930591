<template>
  <div class="w-full ">
    <div class="flex items-end  w-10/12 md:w-5/12 bg-white m-3 rounded-tl-lg rounded-tr-lg
    rounded-br-lg chat-company">
      <div class="p-1.5 w-full">
        <MediaItem :type="message.type" :location="message.location" @get-messages="refreshMessages"
                   :media="message.message_media" v-if="message.message_media"/>
        <div v-if="message.type === 'location'" class="w-full h-64">
          <!--    eslint-disable-next-line-->
          <GMapMap class="h-64"
                   :center="center"
                   :zoom="15"
                   map-type-id="terrain"
                   :options="{
                        zoomControl: false,
                        mapTypeControl: false,
                        scaleControl: false,
                        streetViewControl: false,
                        rotateControl: true,
                        fullscreenControl: false,
                      }"
                   style="width: 100%; height: 100%"
          >
            <GMapMarker
              :position="marker.position"
            />
          </GMapMap>
        </div>
        <div v-if="message.order" class="text-sm break-words text-gray-500 text-left p-2">
          <ul>
            <li v-for="(item,key) in message.order.product_items" v-bind:key="key">
              <img :src="getProductImage(item.product_retailer_id)" :alt="item.product_retailer_id"
                   style="width: 130px; display: inline-block;">
              <b>Producto: </b> {{ getProductName(item.product_retailer_id) }}
              <b>Cantidad: </b> {{ item.quantity }}
              <b>Precio: </b> ${{ item.item_price }}
              <b>Total: </b> ${{ (item.quantity * item.item_price).toFixed(2) }}
            </li>
            <br>
            <br>
            <li><b>Total: </b>${{
                message.order?.product_items?.reduce(function (acc, item) {
                  return acc + (item.item_price * item.quantity);
                }, 0)?.toFixed(2)
              }}
            </li>
          </ul>
        </div>
        <div v-if="message.referral">
          <p><b>Proviene de {{referral.source_type}}:</b></p>
          <p>{{referral.headline}}</p>
          <p>{{referral.body}}</p>
          <a :href="referral.source_url" target="_blank">click para ver anuncio aquí</a>
        </div>
        <div v-if="message.body" class="text-sm text-gray-500 text-left p-2">
          <p class="whitespace-pre-wrap break-words">{{ message.body }}</p>
        </div>
        <div v-if="message.type === 'location'"
             class="text-sm break-words text-gray-600 p-2 text-left">
          <p v-if="formattedLocation.name"><b>Nombre:</b> {{ formattedLocation.name }}</p>
          <p v-if="formattedLocation.address"><b>Dirección:</b>{{ formattedLocation.address }}</p>
          <a target="_blank" :href="locationUrl">
            <p>{{ locationUrl }}</p>
          </a>
        </div>
        <div v-if="message.type === 'interactive'"
             class="text-sm break-words text-gray-500 p-2 text-left">
          <template v-if="message.interactive.type === 'button_reply'">
            {{ message.interactive.button_reply.title }}
          </template>
          <template v-if="message.interactive.type === 'list_reply'">
            {{ message.interactive.list_reply.title }}<br>
            {{ message.interactive.list_reply.description }}
          </template>
        </div>
        <div class="flex justify-end">
          <div class="text-xs text-gray-400 text-right">{{ message.human_created }}</div>
          <img class="w-5 mx-2" v-if="message.send && !message.delivered && !message.seen"
               src="@/assets/one_check.svg" alt="enviado">
          <img class="w-5 mx-2" v-if="message.delivered && !message.seen"
               src="@/assets/doble_check_gris.svg" alt="recibido">
          <img class="w-5 mx-2" v-if="message.seen" src="@/assets/doble_check_azul.svg" alt="leído">
        </div>
        <div class="text-xs text-red-600 text-right" v-if="message.errors">
          Errores:
          <p v-for="(error, key) in message.errors?.errors" v-bind:key="key">
            {{ error.code }} : {{ error.title }} - {{ error.details }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MediaItem from '@/components/chat/MediaItem.vue';

export default {
  name: 'IncomeMessage',
  components: { MediaItem },
  props: {
    message: Object,
  },
  methods: {
    refreshMessages() {
      this.$emit('get-messages');
    },
    getProductImage(name) {
      const numeroEncontrado = name.match(/\d+$/);
      if (numeroEncontrado) {
        const extraido = numeroEncontrado[0];
        // eslint-disable-next-line max-len
        const objetoEncontrado = this.products.find((objeto) => objeto.woocommerce_id === Number(extraido));
        if (objetoEncontrado) {
          return objetoEncontrado.image;
        }
        return null;
      }
      return null;
    },
    getProductName(name) {
      const numeroEncontrado = name.match(/\d+$/);
      if (numeroEncontrado) {
        const extraido = numeroEncontrado[0];
        // eslint-disable-next-line max-len
        const objetoEncontrado = this.products.find((objeto) => objeto.woocommerce_id === Number(extraido));
        if (objetoEncontrado) {
          return objetoEncontrado.name;
        }
        return 'no encontrado';
      }
      return 'no encontrado';
    },
  },
  computed: {
    locationUrl() {
      if (this.message.type === 'location') {
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
            return `https://www.google.com.ec/maps/@${this.formattedLocation.lat},${this.formattedLocation.long},18z?hl=es`;
          case 'whatsapp':
          case 'dialog360':
          default:
            return `https://www.google.com.ec/maps/@${this.formattedLocation.latitude},${this.formattedLocation.longitude},18z?hl=es`;
        }
      }
      return '';
    },
    referral() {
      if (this.message.referral) {
        return JSON.parse(this.message.referral);
      }
      return null;
    },
    activeConversation() {
      return this.$store.state.activeConversation;
    },
    products() {
      return this.$store.state.products;
    },
    center() {
      if (this.message.type === 'location') {
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
            return { lat: this.formattedLocation.lat, lng: this.formattedLocation.long };
          case 'whatsapp':
          case 'dialog360':
          default:
            return { lat: this.formattedLocation.latitude, lng: this.formattedLocation.longitude };
        }
      }
      return { lat: -0.1806532, lng: -78.4678382 };
    },
    marker() {
      if (this.message.type === 'location') {
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
            return {
              position: {
                lat: this.formattedLocation.lat, lng: this.formattedLocation.long,
              },
            };
          case 'whatsapp':
          case 'dialog360':
          default:
            return {
              position: {
                lat: this.formattedLocation.latitude, lng: this.formattedLocation.longitude,
              },
            };
        }
      }
      return { lat: -0.1806532, lng: -78.4678382 };
    },
    formattedLocation() {
      if (this.message.type === 'location') {
        return JSON.parse(this.message.location);
      }
      return null;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.chat-company {
  border: solid 1px #d4d5d8;
  box-shadow: 3px 4px 11px 0px #a6c4c6;
}

/*Scroll*/
.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #8ad2e8;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
}
</style>
