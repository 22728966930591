<template>
  <LeadboxContacts />
</template>

<script>
// @ is an alias to /src
import LeadboxContacts from '@/components/Contacts.vue';

export default {
  name: 'LeadsView',
  components: {
    LeadboxContacts,
  },
};
</script>
