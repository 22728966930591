<template>
  <LeadboxLeads />
</template>

<script>
// @ is an alias to /src
import LeadboxLeads from '@/components/Leads.vue';

export default {
  name: 'LeadsView',
  components: {
    LeadboxLeads,
  },
};
</script>
