<template>
  <div class="w-full flex flex-col p-2 ">
    <div class="w-full justify-between flex border-leadbox-900 border-b-4 p-2">
      <h1 class="text-xl font-semibold">Citas por conexión</h1>
      <button
        class="text-sm font-medium text-gray-700 cursor-pointer bg-gray-200 rounded-md
        border border-black"
        @click="getAppointmentsByConnections">
        <RefreshIcon class="m-2 h-5 w-5" aria-hidden="true"/>
      </button>
    </div>
    <div class="p-4">
      <template v-if="isLoading">
        <div class="w-full flex justify-center">
          <img alt="loading" src="@/assets/loading.gif" class="w-20">
        </div>
      </template>
      <template v-else>
        <template v-if="isDetail">
          <div class="flex justify-between py-4 w-3/4 mx-auto">
            <p>Citas desde {{ selectedConnection.connection }}
              <b>{{ selectedConnection.phone }}</b></p>
            <button @click="goBack" class="underline">
              Regresar
            </button>
          </div>
          <template v-if="isLoadingInside">
            <div class="flex justify-center py-4 w-3/4 mx-auto">
              <div class="flex-col">
                <img alt="loading" src="@/assets/loading.gif" class="w-20">
                <p>Cargando citas...</p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex justify-center py-4">
              <template v-if="isLoadingExcel">
                <div class="flex-col justify-center">
                  <img alt="loading" src="@/assets/loading.gif" class="w-20">
                  <p>Generando descarga...</p>
                </div>
              </template>
              <template v-else>
                <button @click="downloadAppointmentsByConnections"
                        class="w-full md:w-1/3 group relative w-full flex justify-center py-2 px-4
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600 border" type="button">
                  Descargar reporte
                </button>
              </template>
            </div>
            <div class="flex w-3/4 mx-auto">
              <p class="my-2">Mostrando de {{from}} a {{to}} de {{total}} registros</p>
            </div>
            <table class="table-fixed border-collapse border border-black w-3/4 mx-auto">
              <thead class="">
              <tr class="bg-gray-200 text-left font-semibold">
                <th class="p-2 border border-black ">Nombre</th>
                <th class="p-2 border border-black ">Teléfono</th>
                <th class="p-2 border border-black ">Fecha</th>
                <th class="p-2 border border-black ">Hora</th>
                <th class="p-2 border border-black ">Ciudad</th>
                <th class="p-2 border border-black ">Centro</th>
                <th class="p-2 border border-black ">Tratamiento</th>
                <th class="p-2 border border-black ">Usuario</th>
                <th class="p-2 border border-black text-left font-semibold">Creado</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(appointment, index) in connectionAppointments" v-bind:key="index">
                <td class="p-2 border border-black">{{ appointment.patient_name }}</td>
                <td class="p-2 border border-black">{{ appointment.patient_phone }}</td>
                <td class="p-2 border border-black">{{ appointment.date }}</td>
                <td class="p-2 border border-black">
                  {{ appointment.start_time }} - {{ appointment.end_time }}</td>
                <td class="p-2 border border-black">{{ appointment.medical_center_city }}</td>
                <td class="p-2 border border-black">{{ appointment.medical_center_name }}</td>
                <td class="p-2 border border-black">
                  {{ appointment.doctor_service }} {{ appointment.doctor_name }}</td>
                <td class="p-2 border border-black">
                  {{ appointment.user_name }}
                </td>
                <td class="p-2 border border-black">{{ appointment.created_at }}</td>
              </tr>
              </tbody>
            </table>
            <div class="flex justify-end py-2 w-3/4 mx-auto">
              <pagination-item
                @page-changed="getAppointmentsByConnectionPage"
                :totalPages="totalPages"
                :perPage="perPage"
                :maxVisibleButtons="10"
                :currentPage="currentPage">
              </pagination-item>
            </div>
          </template>
        </template>
        <template v-else>
          <table class="table-fixed border-collapse border border-black w-3/4 mx-auto">
            <thead class="">
            <tr class="bg-gray-200 text-left font-semibold">
              <th class="p-2 border border-black ">Conexión (canal)</th>
              <th class="p-2 border border-black text-left font-semibold">Total</th>
              <th class="p-2 border border-black text-left font-semibold">Leads</th>
              <th class="p-2 border border-black text-left font-semibold">Conversaciones</th>
              <th class="p-2 border border-black text-left font-semibold">Productos</th>
              <th class="p-2 border border-black text-left font-semibold">Archivados</th>
              <th class="p-2 border border-black text-left font-semibold">Atendido por</th>
              <th class="p-2 border border-black text-left font-semibold">Acciones</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(connectionsRow, index) in connections" v-bind:key="index">
              <td class="p-2 border border-black">{{ connectionsRow.connection }}</td>
              <td class="p-2 border border-black">
                {{ connectionsRow.total }}
              </td>
              <td class="p-2 border border-black">
                {{ connectionsRow.leads }}
              </td>
              <td class="p-2 border border-black">
                {{ connectionsRow.conversations }}
              </td>
              <td class="p-2 border border-black">
                {{ connectionsRow.products }}
              </td>
              <td class="p-2 border border-black">
                {{ connectionsRow.archived }}
              </td>
              <td class="p-2 border border-black">{{ connectionsRow.users }}</td>
              <td class="p-2 border border-black">
                <button class="w-full group relative w-full flex justify-center py-2 px-4
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600 border"
                        @click="goTo(connectionsRow)">detalle
                </button>
              </td>
            </tr>
            <!--            <tr>-->
            <!--              <td class="p-2 border border-black" colspan="3">TOTAL</td>-->
            <!--              <td class="p-2 border border-black" colspan="3"> {{ total }}</td>-->
            <!--            </tr>-->
            </tbody>
          </table>
        </template>
      </template>
    </div>
  </div>
</template>

<script>

// import PaginationItem from '@/components/general/PaginationItem.vue';
import {
  RefreshIcon,
} from '@heroicons/vue/outline';
import { utils, writeFileXLSX } from 'xlsx';
import PaginationItem from '@/components/general/PaginationItem.vue';

export default {
  name: 'AppointmentsByConnections',
  displayName: 'AppointmentsByConnections',
  components: { PaginationItem, RefreshIcon },
  props: {
    dateValue: Object,
    tab: String,
    type: String,
  },
  data() {
    return {
      connections: [],
      connectionAppointments: [],
      selectedConnection: null,
      total: 0,
      options: {
        maxVisibleButtons: 5,
        totalPages: 10,
        perPage: 10,
        currentPage: 4,
      },
      isLoading: false,
      isDetail: false,
      isLoadingExcel: false,
      isLoadingInside: false,
      perPage: 10,
      totalPages: 0,
      currentPage: 1,
      first_page_url: null,
      from: 0,
      last_page_url: null,
      next_page_url: null,
      path: null,
      prev_page_url: null,
      to: 0,
    };
  },
  mounted() {
    if (this.activeCompany) {
      this.getAppointmentsByConnections();
    }
  },
  watch: {
    tab(value) {
      if (value === 'appointments') {
        if (this.activeCompany) {
          this.getAppointmentsByConnections();
        }
      }
    },
    dateValue(val) {
      console.log('val', val);
      this.getAppointmentsByConnections();
    },
    type(val) {
      console.log('val', val);
      this.getAppointmentsByConnections();
    },
    activeCompany(company) {
      if (company) {
        this.getAppointmentsByConnections();
      }
    },
  },
  methods: {
    goTo(connection) {
      this.isDetail = true;
      this.selectedConnection = connection;
      console.log('connection', connection);
      this.getAppointmentsByConnection(connection.uuid);
      // TODO: get new data
    },
    goBack() {
      this.isDetail = false;
    },
    getAppointmentsByConnection(uuid) {
      this.isLoadingInside = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        connection: uuid,
        dateType: this.type,
      };
      this.$store.dispatch('reportsAppointmentsByConnection', payload)
        .then((response) => {
          console.log('response reportsAppointmentsByConnection', response);
          const { success } = response.data;
          this.connectionAppointments = success.data;
          this.currentPage = success.current_page;
          this.first_page_url = success.first_page_url;
          this.from = success.from;
          this.totalPages = success.last_page;
          this.last_page_url = success.last_page_url;
          this.next_page_url = success.next_page_url;
          this.path = success.path;
          this.perPage = success.per_page;
          this.prev_page_url = success.prev_page_url;
          this.to = success.to;
          this.total = success.total;
          this.isLoadingInside = false;
          console.log('respuesta reportsAppointmentsByConnection', response);
        }, (error) => {
          console.log('error reportsAppointmentsByConnection', error);
          this.isLoadingInside = false;
        })
        .catch((error) => {
          this.isLoadingInside = false;
          console.log('error reportsAppointmentsByConnection', error);
        });
    },
    getAppointmentsByConnectionPage(number) {
      console.log('number', number);
      this.isLoadingInside = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        connection: this.selectedConnection?.uuid,
        url: `${this.path}?page=${number}`,
        dateType: this.type,
      };
      this.$store.dispatch('reportsAppointmentsByConnectionPage', payload)
        .then((response) => {
          console.log('response reportsAppointmentsByConnectionPage', response);
          const { success } = response.data;
          this.connectionChats = success.data;
          this.currentPage = success.current_page;
          this.first_page_url = success.first_page_url;
          this.from = success.from;
          this.totalPages = success.last_page;
          this.last_page_url = success.last_page_url;
          this.next_page_url = success.next_page_url;
          this.path = success.path;
          this.perPage = success.per_page;
          this.prev_page_url = success.prev_page_url;
          this.to = success.to;
          this.total = success.total;
          this.isLoadingInside = false;
          console.log('respuesta reportsAppointmentsByConnectionPage', response);
        }, (error) => {
          console.log('error reportsAppointmentsByConnectionPage', error);
          this.isLoadingInside = false;
        })
        .catch((error) => {
          this.isLoadingInside = false;
          console.log('error reportsAppointmentsByConnectionPage', error);
        });
    },
    getAppointmentsByConnections() {
      this.isLoading = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        dateType: this.type,
      };
      this.$store.dispatch('reportsAppointmentsByConnections', payload)
        .then((response) => {
          console.log('response reportsAppointmentsByConnections', response);
          this.connections = response.data.success.data;
          this.total = response.data.success.total;
          this.isLoading = false;
          console.log('respuesta reportsAppointmentsByConnections', response);
        }, (error) => {
          console.log('error reportsAppointmentsByConnections', error);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error reportsAppointmentsByConnections', error);
        });
    },
    async downloadAppointmentsByConnections() {
      this.isLoadingExcel = true;
      let info = [];
      for (
        let i = 0;
        i < this.totalPages;
        // eslint-disable-next-line no-plusplus
        i++
      ) {
        const payload = {
          company: this.activeCompany?.uuid,
          start: this.dateValue.startDate,
          end: this.dateValue.endDate,
          connection: this.selectedConnection?.uuid,
          url: `${this.path}?page=${i + 1}`,
          dateType: this.type,
        };
        // eslint-disable-next-line no-await-in-loop
        const response = await this.$store.dispatch('reportsAppointmentsByConnectionPage', payload);
        const { success } = response.data;
        info = [...info, ...success.data];
      }
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(info);
      utils.book_append_sheet(wb, ws, this.selectedConnection.connection.slice(0, 30));
      writeFileXLSX(wb, `${this.selectedConnection.connection}_${new Date().getTime()}.xlsx`);
      this.isLoadingExcel = false;
    },
    changePage(page) {
      this.options.currentPage = page;
      this.getAppointmentsByConnections();
    },
  },
  computed: {
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>

<style scoped>

</style>
