<template>
  <div class="flex flex-col h-screen max-h-screen bg-white">
    <template v-if="isLoadingCompanies">
      <div class="w-full h-full bg-header flex items-center justify-center">
        <div class="flex flex-col">
          <div class="w-full flex justify-center">
            <img alt="loading" src="@/assets/loading.gif" class="w-20">
          </div>
          <p class="text-lg text-white text-center object-none object-center ">
            Cargando tus empresas...
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <!--top bar-->
      <div class="w-full h-16 flex justify-between bg-header">
        <div class="md:w-1/5 w-36 flex justify-items-center cursor-pointer">
          <MainDropDown :type="'calendar'" class="w-full"/>
        </div>
        <CompanyDropDown class="p-1" :type="'leads'" @changed-company="changedCompany"/>
        <!--        <FormDropDown class="p-1" :type="'leads'" @changed-company="changedCompany"/>-->
        <LeadNotificationsDropDown class="p-1" @open-lead="getLead"/>
        <MessageNotificationsDropDown class="p-1" @open-conversation="getConversation"/>
        <div class="flex-grow"></div>
        <DropDown class="p-1 lg:p-3"/>
      </div>
      <div class="flex w-full flex-grow h-80">
        <template v-if="isLoadingEvents">
          <div class="w-full h-full bg-header flex items-center justify-center">
            <div class="flex flex-col">
              <div class="w-full flex justify-center">
                <img alt="loading" src="@/assets/loading.gif" class="w-20">
              </div>
              <p class="text-lg text-white text-center object-none object-center ">
                Cargando tus eventos...
              </p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="flex flex-col w-full">
            <div class="w-full flex justify-end">
              <button class="bg-gray-400 h-10 flex rounded-lg m-1 mr-3 p-2 text-white"
                      @click="close()">
                <XIcon class="mx-1 h-5 w-5 text-white" aria-hidden="true"/>
                Cerrar
              </button>
            </div>
            <FullCalendar class='p-3 pt-1 flex-grow' :options='calendarOptions'>
              <template v-slot:eventContent='arg'>
                <b>{{ arg.timeText }}</b>
                <i>{{ arg.event.title }}</i>
              </template>
            </FullCalendar>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import DropDown from '@/components/DropDown.vue';
import MainDropDown from '@/components/MainDropDown.vue';
import CompanyDropDown from '@/components/CompanyDropDown.vue';
import LeadNotificationsDropDown from '@/components/LeadNotificationsDropDown.vue';
import MessageNotificationsDropDown from '@/components/MessageNotificationsDropDown.vue';
import axios from '@/http/axios';
import {
  XIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'LeadboxCalendar',
  components: {
    FullCalendar,
    DropDown,
    MainDropDown,
    LeadNotificationsDropDown,
    MessageNotificationsDropDown,
    CompanyDropDown,
    // eslint-disable-next-line vue/no-unused-components
    dayGridPlugin,
    // eslint-disable-next-line vue/no-unused-components
    timeGridPlugin,
    // eslint-disable-next-line vue/no-unused-components
    interactionPlugin,
    XIcon,
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      // this.getActualToken();
      if (this.companies.length > 0) {
        if (this.activeCompany) {
          // this.getEvents();
        }
      } else {
        this.getCompanies();
      }
    } else {
      this.$router.push('').catch(() => {
      });
    }
  },
  data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'dayGridMonth',
        initialEvents: [], // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: (info) => {
          console.log('Date selected:', info);
          // Tu lógica aquí para manejar la selección de fecha
        },
        eventClick: (info) => {
          console.log('Event clicked:', info.event);
          // Tu lógica aquí para manejar el click en el evento
        },
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      isLoadingCompanies: false,
      isLoadingEvents: false,
      currentEvents: [],
    };
  },
  methods: {
    close() {
      this.$router.push('/leads').catch(() => {
      });
    },
    changedCompany() {
      this.getEvents();
    },
    getConversation(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      // this.isLoadingConversation = true;
      this.$store.dispatch('conversation', payload)
        .then((response) => {
          // this.isLoadingConversation = false;
          console.log('respuesta conversation', response);
          // this.getMessages();
          // this.getLeads();
          // this.getSender(this.activeConversation.uuid);
          // this.getNotes();
          this.$router.push('/chat').catch(() => {
          });
        }, (error) => {
          // this.isLoadingConversation = false;
          console.log('error conversation', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          // this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversation', error);
        });
    },
    getLead(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      // this.isLoadingConversation = true;
      this.$store.dispatch('lead', payload)
        .then((response) => {
          // this.isLoadingConversation = false;
          console.log('respuesta lead', response);
          // this.getMessages();
          // this.getLeads();
          // this.getSender(this.activeConversation.uuid);
          // this.getNotes();
          this.$router.push('/leads').catch(() => {
          });
        }, (error) => {
          // this.isLoadingConversation = false;
          console.log('error lead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          // this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error lead', error);
        });
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },
    handleDateSelect(selectInfo) {
      const title = 'titulo';
      const calendarApi = selectInfo.view.calendar;
      calendarApi.unselect(); // clear date selection
      if (title) {
        calendarApi.addEvent({
          id: '465465',
          title,
          start: selectInfo.startStr,
          end: selectInfo.endStr,
          allDay: selectInfo.allDay,
        });
      }
    },
    handleEventClick(clickInfo) {
      console.log(clickInfo);
    },
    handleEvents(events) {
      this.currentEvents = events;
      this.initialEvents = events;
      console.log('events', events);
    },
    getCompanies() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then((response) => {
          console.log('respuesta companies', response);
          this.isLoadingCompanies = false;
          // this.getEvents();
        }, (error) => {
          console.log('error companies', error);
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error company', error);
        });
    },
    getEvents() {
      // TODO: send pagination
      this.isLoadingEvents = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('events', payload)
        .then((response) => {
          this.isLoadingEvents = false;
          console.log('respuesta events', response.data.success);
          this.handleEvents(this.events);
        }, (error) => {
          this.isLoadingEvents = false;
          console.log('error events', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingEvents = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error events', error);
        });
    },
  },
  computed: {
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    companies() {
      return this.$store.state.companies;
    },
    events() {
      return this.$store.state.events;
    },
  },
};
</script>

<style scoped>

</style>
