<template>
  <div class="flex flex-col h-screen max-h-screen bg-white">
    <template v-if="isLoadingCompanies">
      <div class="w-full h-full bg-header flex items-center justify-center">
        <div class="flex flex-col">
          <div class="w-full flex justify-center">
            <img alt="loading" src="@/assets/loading.gif" class="w-20">
          </div>
          <p class="text-lg text-white text-center object-none object-center ">
            {{ activeUser.name }}, vamos por más
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <!--top bar-->
      <div class="w-full h-16 flex justify-between bg-header">
        <div class="md:w-1/5 w-36 flex justify-items-center cursor-pointer">
          <MainDropDown :type="'dates'" class="w-full"/>
        </div>
        <CompanyDropDown class="p-1" :type="'dates'" @changed-company="changedCompany"/>
        <FormDropDown class="p-1" :type="'dates'" @changed-form="changedForm"/>
        <MedicalCenterDropDown class="p-1" :type="'dates'"
                               @changed-medical-center="changedForm"/>
        <DoctorDropDown class="p-1" :type="'dates'" v-if="doctors.length > 0"
                               @changed="changedForm"/>
        <SpecialtyDropDown class="p-1" :type="'dates'" v-if="specialties.length > 0"
                               @changed="changedForm"/>
        <AreaDropDown v-if="areas.length > 0"
                      class="p-1" :type="'leads'" @changed-form="changedArea"/>
        <!--<LeadNotificationsDropDown class="p-1" @open-lead="getLead"/>-->
        <!--<MessageNotificationsDropDown class="p-1" @open-conversation="getConversation"/>-->

        <div class="flex-grow"></div>
        <DropDown class="p-1 lg:p-3"/>
      </div>

      <div class="flex w-full flex-grow h-80">
        <template v-if="false">
          <button class="sidebar-icons bg-header" @click="showCalendar = !showCalendar">
            <CalendarIcon class="m-2 h-5 w-5 text-white text-right" aria-hidden="true"/>
          </button>
          <template v-if="isLoadingDates">
            <div class="w-full h-full bg-header flex items-center justify-center">
              <div class="flex flex-col">
                <div class="w-full flex justify-center">
                  <img alt="loading" src="@/assets/loading.gif" class="w-20">
                </div>
                <p class="text-lg text-white text-center object-none object-center ">
                  Cargando citas...
                </p>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col w-full">
              <FullCalendar class="p-3 pt-1 flex-grow" :options="calendarOptions"/>
            </div>
          </template>
        </template>
        <template v-else>
          <!--left menu-->
          <div class="h-max bg-header lg:flex flex-col sidebar-menu md:overflow-y-auto"
               v-bind:class="{ 'hidden' : activeDate, 'flex' : !activeDate }">
            <div class="py-1 flex w-full cursor-pointer">
              <button class="sidebar-icons" @click="isShow = !isShow">
                <MenuIcon class="m-2 h-5 w-5 text-white text-right" aria-hidden="true"/>
              </button>
            </div>
            <div class="test-sidebar flex-col" v-bind:class="{ hidden: !isShow }">
              <div v-show="showCalendar" class="flex-col">
                <!--eslint-disable-next-line-->
                <div class="py-1 flex cursor-pointer md:w-52 w-32"
                     @click="showCalendar = !showCalendar">
                  <XIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                  <div class="pl-1 p-2 w-5/12 text-left text-white text-sidebar">
                    <b>Cerrar</b>
                  </div>
                </div>
                <div class="py-1 flex md:w-52 w-32">
                  <CalendarIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                  <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">
                    <b>Calendario</b>
                  </div>
                </div>
                <div class="p-3">
                  <!--eslint-disable-next-line-->
                  <input
                    ref="calendarInput"
                    class="w-full border border-gray-300 rounded p-2"
                    type="date"
                    placeholder="Selecciona una fecha"
                  />
                </div>
              </div>
              <vue-collapsible-panel-group accordion base-color="#000000">
                <vue-collapsible-panel>
                  <template #title>
                    <div class="py-1 flex cursor-pointer md:w-52 w-32" @click="setType('active')"
                         @keydown="setType('active')"
                         v-bind:class="{ 'bg-leadbox-500' : type === 'active' }">
                      <CollectionIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                      <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Activas</div>
                    </div>
                  </template>
                  <template #content>
                    <template v-if="isLoadingStatuses">
                      <p class="text-white text-md">Cargando...</p>
                    </template>
                    <template v-else>
                      <StatusItem v-for="(status) in activeStatuses"
                                  v-bind:key="status.id" :status="status"
                                  @set-status="setStatus" :selected="selectedStatus"/>
                    </template>
                  </template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>
                    <div class="py-1 flex w-full cursor-pointer" @click="setType('closed')"
                         @keydown="setType('closed')"
                         v-bind:class="{ 'bg-leadbox-500' : type === 'closed' }">
                      <LockClosedIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                      <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Cerradas</div>
                    </div>
                  </template>
                  <template #content>
                    <template v-if="isLoadingStatuses">
                      <p class="text-white text-md">Cargando...</p>
                    </template>
                    <template v-else>
                      <StatusItem v-for="(status) in closedStatuses" v-bind:key="status.id"
                                  :status="status"
                                  @set-status="setStatus" :selected="selectedStatus"/>
                    </template>
                  </template>
                </vue-collapsible-panel>
                <vue-collapsible-panel :expanded="false">
                  <template #title>
                    <div class="py-1 flex w-full cursor-pointer" @click="setType('archived')"
                         @keydown="setType('archived')"
                         v-bind:class="{ 'bg-leadbox-500' : type === 'archived' }">
                      <ArchiveIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                      <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">
                        Archivados
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <template v-if="isLoadingStatuses">
                      <p class="text-white text-md">Cargando...</p>
                    </template>
                    <template v-else>
                      <StatusItem v-for="(status) in statuses" v-bind:key="status.id"
                                  :status="status"
                                  @set-status="setStatus" :selected="selectedStatus"/>
                    </template>
                  </template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
              <vue-collapsible-panel-group accordion>
                <vue-collapsible-panel :expanded="true">
                  <template #title>
                    <div class="py-1 flex">
                      <UserIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                      <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">
                        <b>ASESORES</b>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div class="overflow-auto h-fit">
                      <template v-if="isLoadingDatesUsers">
                        <p class="text-white text-md">Cargando...</p>
                      </template>
                      <template v-else>
                        <AsesorItem v-for="(user) in datesUsers" v-bind:key="user.id" :user="user"
                                    :selected="selectedUser" @set-user="setUser"/>
                      </template>
                    </div>
                  </template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </div>
          </div>
          <!--intermediate menu-->
          <div v-if="!showCalendar"
               class="w-full md:w-7/12 lg:w-1/5 h-max bg-gray-200 lg:flex flex-col"
               v-bind:class="{ 'hidden' : activeDate, 'flex' : !activeDate }">
            <div class="w-full flex justify-center">
              <div class="flex py-1 mx-1 w-full">
                <litepie-datepicker class="filter-date" placeholder="Ver por fecha"
                                    separator=" hasta " i18n="es" overlay :auto-apply="false"
                                    :formatter="formatter" :options="options" v-model="dateValue">
                </litepie-datepicker>
                <button class="sidebar-icons bg-header rounded-md" @click="showCalendar = true;"
                        title="vista de calendario">
                  <CalendarIcon class="m-2 h-5 w-5 text-white text-right" aria-hidden="true"/>
                </button>
              </div>
            </div>
            <div class="w-full">
              <div class="flex rounded-lg bg-gray-100">
                <!--eslint-disable-next-line-->
                <input class="p-2 w-10/12 bg-gray-100 text-sm focus:outline-none" v-model="search"
                       type="text" placeholder="Buscar citas...">
                <div class="p-1 w-2/12 flex justify-center items-center cursor-pointer bg-gray-100"
                     @click="getDates()" @keydown="getDates()">
                  <SearchIcon class="m-2 h-6 w-6" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-full">
              <div class="m-1 flex justify-between rounded-lg">
                <p class="my-2"><b>Total:</b> {{ total }}</p>
                <div class="flex">
                  <div title="Eliminar selecionadas" class="hover:bg-gray-300">
                    <TrashIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                               v-if="selectedDates.length > 0" @click="showDeleteModal = true;"/>
                  </div>
                  <div title="Archivar selecionadas" class="hover:bg-gray-300">
                    <ArchiveIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                                 v-if="selectedDates.length > 0" @click="showArchiveModal = true;"/>
                  </div>
                  <div title="Asignar selecionadas" class="hover:bg-gray-300">
                    <UserIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                              v-if="selectedDates.length > 0" @click="showAssignOneModal = true;"/>
                  </div>

                  <div title="Asignación automática" class="hover:bg-gray-300">
                    <UsersIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                               @click="showAssignModal = true;"/>
                  </div>

                  <div title="Filtro de canales" class="hover:bg-gray-300">
                    <ChannelsDropDown v-if="channels.length>0" class="w-7 m-1 cursor-pointer"
                                      aria-hidden="true" :channel="channel"
                                      @set-channel="setChannel"/>
                  </div>
                  <div title="Opciones" class="hover:bg-gray-300">
                    <DateOptionsDropDown :order="order" @select-all="selectAll" @refresh="refresh"
                                         :all="allSelected" @change-order="changeOrder"
                                         @open-create="openCreateModal"
                                         @open-group="openCreateModal"/>
                  </div>
                </div>

              </div>
            </div>
            <div class="scroll-smooth overflow-auto h-fit">
              <template v-if="isLoadingDates">
                <p class="text-md text-black text-center">Cargando....</p>
              </template>
              <template v-else>
                <template v-if="dates?.length>0">
                  <DateItem v-for="(date) in dates"
                            v-bind:key="date.id" :date="date"
                            :selected="selectedDates"
                            @check-clicked="selectGroupDate"
                            @selected-date="selectDate"/>
                </template>
                <template v-else>
                  <p class="text-center mt-10">
                    {{
                      search !== null && search !== '' ?
                        'No hay resultados para ' + this.search :
                        'No hay citas'
                    }}</p>
                </template>
              </template>
            </div>
            <div class="w-full" v-if="nextPageUrl">
              <div class="m-1 flex rounded-lg cursor-pointer bg-white place-content-center p-2"
                   @click="loadMore()" @keydown="loadMore()">
                {{ isLoadingMore ? 'Cargando....' : 'ver más' }}
              </div>
            </div>
            <!--asignar leads-->
            <ModalBox :showing="showAssignModal" @close="showAssignModal = false">
              <div class="flex">
                <p class="text-black"><b>Asignar citas</b></p>
              </div>
              <template v-if="isLoadingAssignAuto">
                <p class="text-center">Cargando...</p>
              </template>
              <template v-else>
                <div class="flex">
                  <p class="text-black">
                    Entregar las citas sin asignar automaticamente a los asesores
                  </p>
                </div>
                <div class="w-full flex">
                  <div class="m-1">
                    <Toggle v-model="selectAssignUsers"/>
                  </div>
                  <p class="ml-1">Seleccionar Usuarios?</p>
                </div>
                <template v-if="selectAssignUsers">
                  <div class="w-full flex" v-for="(user) in assignUsersList"
                       v-bind:key="user.id">
                    <div class="m-1">
                      <Toggle v-model="user.selected"/>
                    </div>
                    <p class="ml-1">{{ user.name }}</p>
                  </div>
                </template>
                <div class="flex">
                  <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                          @click="showAssignModal = false"
                          type="button">Cerrar
                  </button>
                  <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                          @click="assignDates()">Asignar equitativamente
                  </button>
                </div>
              </template>
            </ModalBox>
            <!--asignar un asesor leads-->
            <ModalBox :showing="showAssignOneModal" @close="showAssignOneModal = false">
              <div class="flex">
                <p class="text-black"><b>Asignar citas a asesor</b></p>
              </div>
              <template v-if="isLoadingAssign">
                <p class="text-center">Cargando...</p>
              </template>
              <template v-else>
                <div class="flex flex-col">
                  <!--eslint-disable-next-line-->
                  <label class="w-full text-left" for="assignUser">
                    <b>Usuario:</b>
                  </label>
                  <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="datesUsersList.length < 1"
                          id="assignUser" v-model="assignUserModal" name="assignUser">
                    <option :value="null" class="px-3 py-2 text-sm">
                      -- Selecciona un asesor --
                    </option>
                    <option v-for="(usr) in datesUsersList"
                            v-bind:key="usr.id" :value="usr"
                            class="px-3 py-2 text-sm">
                      {{ usr.name }}
                    </option>
                  </select>
                </div>
                <div class="flex">
                  <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                          @click="showAssignOneModal = false"
                          type="button">Cerrar
                  </button>
                  <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                          @click="assignOneDates()">Asignar
                  </button>
                </div>
              </template>
            </ModalBox>
            <!--elimnar leads-->
            <ModalBox :showing="showDeleteModal" @close="showDeleteModal = false">
              <div class="flex">
                <p class="text-black"><b>Eliminar citas</b></p>
              </div>
              <div class="flex">
                <p class="text-black">
                  Deseas eliminar {{ selectedDates.length }} citas seleccionadas?
                </p>
              </div>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showDeleteModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="deleteDates()">Aceptar
                </button>
              </div>
            </ModalBox>
            <!--archivar leads-->
            <ModalBox :showing="showArchiveModal" @close="showArchiveModal = false">
              <div class="flex">
                <p class="text-black"><b>Archivar citas</b></p>
              </div>
              <div class="flex">
                <p class="text-black">
                  Deseas archivar {{ selectedDates.length }} citas seleccionadas?
                </p>
              </div>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showArchiveModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="archiveDates()">Aceptar
                </button>
              </div>
            </ModalBox>
            <!--loading-->
            <LoadingBox :showing="isLoadingDate">
              <div class="flex flex-col">
                <img alt="loading" src="@/assets/loading.gif" class="w-20 mx-3">
                <p class="text-center text-white">
                  {{ activeUser.name }},
                </p>
                <p class="text-center text-white">
                  vamos por más...
                </p>
              </div>
            </LoadingBox>
          </div>
          <!--main view-->
          <template v-if="showCalendar">
            <main class="lg:w-4/5 w-full p-3 flex-grow h-max flex-col bg-white drop-shadow-2xl
            shadow-2xl">
              <div class=" h-full overflow-y-auto">
                <FullCalendar ref="fullCalendar" class=" flex-grow"
                              :options="calendarOptions"/>
              </div>
            </main>
            <!--detalle calendario-->
            <ModalBox :showing="showEventDetailModal" @close="showEventDetailModal = false">
              <div class="flex">
                <p class="text-black break-words"><b>{{ selectedEvent.lbData.patient_name }}</b></p>
              </div>
              <div class="flex-grow p-1 w-full">
                <div class="flex text-lg justify-between m-1 break-words">
                  <div><b>Tratamiento:</b>
                    {{ selectedEvent.lbData.doctor_service }} -
                    {{ selectedEvent.lbData.doctor_name }}
                  </div>
                </div>
                <div class="flex text-md justify-between m-1 break-words">
                  <div><b>Centro:</b> {{ selectedEvent.lbData.medical_center_name }}</div>
                </div>
                <div class="flex text-md justify-between m-1 break-words">
                  <div><b>Fecha y Hora:</b> {{ selectedEvent.lbData.date }}</div>
                </div>
                <div class="flex text-md justify-between m-1 break-words">
                  <div>
                    <b>Inicio:</b> {{ selectedEvent.lbData.start_time }}
                    <b>Fin:</b> {{ selectedEvent.lbData.end_time }}
                  </div>
                </div>

                <div v-if="selectedEvent.lbData.status"
                     class="flex text-md justify-between m-1 break-words">
                  <div><b>Estado: </b>{{ selectedEvent.lbData.status.name }}</div>
                </div>
                <div v-if="selectedEvent.lbData.user_name"
                     class="flex text-md justify-between m-1 break-words">
                  <div><b>Asignado a: </b>{{ selectedEvent.lbData.user_name }}</div>
                </div>
                <div v-if="selectedEvent.lbData.referral_id"
                     class="flex text-md justify-between m-1 break-words">
                  <div><b>Anuncio WhatsApp: </b>{{ selectedEvent.lbData.referral_headline }}</div>
                </div>
                <div v-if="selectedEvent.lbData.ad_name"
                     class="flex text-md justify-between m-1 break-words">
                  <div><b>Anuncio dategen: </b>{{ selectedEvent.lbData.ad_name }}</div>
                </div>
                <div v-if="selectedEvent.lbData.campaign_name"
                     class="flex text-md justify-between m-1 break-words">
                  <div><b>Campaña dategen: </b>{{ selectedEvent.lbData.campaign_name }}</div>
                </div>
                <div v-if="selectedEvent.lbData.campaign_id"
                     class="flex text-md justify-between m-1 break-words">
                  <div><b>Campaña facebook: </b>{{ selectedEvent.lbData.campaign_id }}</div>
                </div>
                <div v-if="selectedEvent.lbData.campaign_internal_id"
                     class="flex text-md justify-between m-1 break-words">
                  <div><b>Campaña: </b>{{ selectedEvent.lbData.campaign_internal_id }}</div>
                </div>

              </div>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showEventDetailModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="closeEventDetailModal">Ver cita
                </button>
              </div>
            </ModalBox>
            <!--loading-->
            <LoadingBox :showing="isLoadingDates">
              <div class="flex flex-col">
                <img alt="loading" src="@/assets/loading.gif" class="w-20 mx-3">
                <p class="text-center text-white">
                  cargando citas
                </p>
                <p class="text-center text-white">
                  vamos por más...
                </p>
              </div>
            </LoadingBox>
          </template>
          <template v-else>
            <!--active lead-->
            <main class="lg:w-3/5 w-full flex-grow h-max flex-col bg-white drop-shadow-2xl
            shadow-2xl" v-bind:class="{ 'flex' : activeDate, 'hidden' : !activeDate }">
              <template v-if="isLoadingDate">
                <p class="text-center my-10">
                  {{ activeUser.name }}, vamos por más...
                </p>
              </template>
              <template v-else>
                <template v-if="activeDate">
                  <!--top bar -->
                  <div class="w-full flex flex-col bg-gray-100">
                    <div class="w-full hidden md:flex ">
                      <div class=" w-full md:w-5/12 flex ">
                        <!--<div class="p-1 relative">-->
                        <!--<div class="rounded-full absolute left-2 top-6 text-white p-2-->
                        <!--text-xs h-2 w-2" v-if="activeDate.status"-->
                        <!-- :style="{ backgroundColor: activeDate.status.color}">-->
                        <!--   </div>-->
                        <!--                          <img-->
                        <!--                  class="w-6 h-6 my-1 rounded-full self-center-->
                        <!--                            border-2 border-gray-300"-->
                        <!--                            src="@/assets/default-profile.svg"-->
                        <!--                            alt="user">-->
                        <!--                        </div>-->
                        <div class="p-1 flex flex-col">
                          <div class="flex text-sm">
                            <!--eslint-disable-next-line-->
                            <input v-if="editName" class="border-1 p-1"
                                   v-model="activeDate.patient_name" type="text">
                            <b class="p-1 " v-else>{{ activeDate.patient_name }}</b>
                            <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                           @click="editName = !editName" aria-hidden="true"/>
                          </div>
                          <div class="flex my-1 mt-0 content-center">
                            <img class="w-8 h-8 rounded-lg" :src="source" alt="user">
                            <div class="flex-grow ">
                              <div class="text-md m-2 my-1 self-center">
                                {{ activeDate.connection_name }}
                              </div>
                            </div>
                          </div>
                          <!--                        <div class="text-md m-2 my-1 self-center">-->
                          <!--                          {{ activeDate.medical_center_city }}-->
                          <!--                          {{ activeDate.medical_center_name }}-->
                          <!--                        </div>-->
                          <!--                        <div class="text-md m-2 my-1 self-center"-->
                          <!--                             v-if="activeDate.doctor_name">-->
                          <!--                          {{ activeDate.doctor_service }}-->
                          <!--                          {{ activeDate.doctor_name }}-->
                          <!--                        </div>-->
                          <div class="flex text-sm justify-between m-1">
                            <div><b>Fecha: </b>{{ activeDate.date }}</div>
                          </div>
                          <div class="flex text-sm justify-between m-1">
                            <div class="mx-1 py-1 flex">
                              <b>Inicio: </b>
                              <!--eslint-disable-next-line-->
                              <input v-if="editStartTime" class="border-1 p-1"
                                     v-model="activeDate.start_time" type="text">
                              <span class="text-sm" v-else>{{ activeDate.start_time }}</span>
                              <PencilAltIcon class="h-5 w-5 mx-2 cursor-pointer"
                                             @click="editStartTime = !editStartTime"
                                             aria-hidden="true"/>
                            </div>
                            <div class="mx-1 py-1 flex">
                              <b>Fin: </b>
                              <!--eslint-disable-next-line-->
                              <input v-if="editEndTime" class="border-1 p-1"
                                     v-model="activeDate.end_time" type="text">
                              <span class="text-sm" v-else>{{ activeDate.end_time }}</span>
                              <PencilAltIcon class="h-5 w-5 mx-2 cursor-pointer"
                                             @click="editEndTime = !editEndTime"
                                             aria-hidden="true"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col w-4/12 justify-start">
                        <div class="flex px-1 sm:px-2">
                          <div class="p-1 text-sm font-medium text-gray-700">
                            <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                          </div>
                          <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user" v-model="activeDate.user_id" name="user">
                            <option :value="null" class="px-3 py-2 text-sm">
                              -- Selecciona un asesor --
                            </option>
                            <option v-for="(user) in datesUsersList"
                                    v-bind:key="user.id" :value="user.id"
                                    class="px-3 py-2 text-sm">
                              {{ user.name }}
                            </option>
                          </select>
                        </div>
                        <div class="flex px-1 sm:px-2">
                          <div class="p-1 text-sm font-medium text-gray-700">
                            <NewspaperIcon class="m-1 h-7 w-7 " aria-hidden="true"/>
                          </div>
                          <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="form" v-model="activeDate.form_id" name="form">
                            <option :value="null" class="px-3 py-2 text-sm">
                              -- Selecciona un formulario --
                            </option>
                            <option v-for="(form) in forms"
                                    v-bind:key="form.id" :value="form.id"
                                    class="px-3 py-2 text-sm">
                              {{ form.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="flex w-3/12 justify-end">
                        <div
                          class="flex flex-col text-center items-center text-center items-center">
                          <button class="bg-green-720 h-10 flex rounded-lg
                         text-center items-center m-1 my-2 p-2 text-white text-center items-center"
                                  @click="save()">
                            <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                            Guardar
                          </button>
                          <button class="bg-orange-500 h-10 flex rounded-lg  m-1 my-2 p-2
                                text-white"
                                  v-if="!activeDate.is_closed_positive &&
                                !activeDate.is_closed_negative"
                                  @click="openReScheduleModal()">
                            Reagendar
                          </button>
                        </div>
                        <!--eslint-disable-next-line-->
                        <div class="p-2 hover:bg-gray-200 cursor-pointer" @click="closeDate()">
                          <XIcon class=" h-7 w-7  mr-1" aria-hidden="true"/>
                        </div>
                      </div>
                    </div>
                    <div class="w-full flex flex-col md:hidden">
                      <div class=" w-full flex ">
                        <div class="flex w-7/12">
                          <div class="p-1 flex flex-col flex-grow ml-2 sm:m-0">
                            <div class="flex text-sm">
                              <!--eslint-disable-next-line-->
                              <input v-if="editName" class="border-1 p-1"
                                     v-model="activeDate.patient_name" type="text">
                              <b class="p-1 " v-else>{{ activeDate.patient_name }}</b>
                              <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                             @click="editName = !editName" aria-hidden="true"/>
                            </div>
                            <div class="flex my-1 mt-0 content-center">
                              <img class="w-6 h-6 rounded-lg" :src="source" alt="user">
                              <div class="flex-grow ">
                                <div class="text-sm m-2 my-1 self-center">
                                  {{ activeDate.connection_name }}
                                </div>
                                <!--<div class="text-md m-2 my-1 self-center">-->
                                <!--         {{ activeDate.medical_center_city }}-->
                                <!--             - {{ activeDate.medical_center_name }}-->
                                <!--                             </div>-->
                                <!--               <div class="text-md m-2 my-1 self-center"-->
                                <!--                        v-if="activeDate.doctor_name">-->
                                <!--                   {{ activeDate.doctor_service }}-->
                                <!--                   - {{ activeDate.doctor_name }}-->
                                <!--                              </div>-->
                              </div>
                            </div>
                            <div class="flex-col text-xs m-1">
                              <div>{{ activeDate.date }}</div>
                              <div><b>Inicio:</b> {{ activeDate.start_time }}</div>
                              <div><b>Fin:</b> {{ activeDate.end_time }}</div>
                            </div>

                            <div class="flex text-sm m-1"
                                 v-if="activeDate.referral_id">
                              <b>Anuncio WhatsApp: </b>{{ activeDate.referral_headline }}
                            </div>
                            <div class="flex text-sm m-1"
                                 v-if="activeDate.ad_name">
                              <b>Anuncio leadgen: </b>{{ activeDate.ad_name }}
                            </div>
                            <div class="flex text-sm m-1"
                                 v-if="activeDate.campaign_name">
                              <b>Campaña leadgen: </b>{{ activeDate.campaign_name }}
                            </div>

                            <div class="flex text-sm m-1"
                                 v-if="activeDate.campaign_internal_name">
                              <b>Campaña:</b> {{ activeDate.campaign_internal_name }}
                            </div>
                            <div class="flex text-sm m-1"
                                 v-if="activeDate.campaign_id">
                              <b>Campaña facebook:</b> {{ activeDate.campaign_id }}
                            </div>
                          </div>
                        </div>
                        <div class="flex w-5/12 justify-end">
                          <div class="flex flex-col">
                            <button
                              class="bg-green-720 h-10 flex rounded-lg  m-1 my-2 p-2 text-white"
                              @click="save()">
                              <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                              Guardar
                            </button>
                            <button class="bg-orange-500 h-10 flex rounded-lg  m-1 my-2 p-2
                                text-white" v-if="!activeDate.is_closed_positive &&
                                !activeDate.is_closed_negative"
                                    @click="openReScheduleModal()">
                              Reagendar
                            </button>
                          </div>
                          <!--eslint-disable-next-line-->
                          <div class="p-2 hover:bg-gray-200 cursor-pointer" @click="closeDate()">
                            <XIcon class=" h-7 w-7 mr-1" aria-hidden="true"/>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col w-full justify-start">
                        <div class="flex px-1 sm:px-2">
                          <div class="p-1 text-sm font-medium text-gray-700">
                            <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                          </div>
                          <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-8
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user1" v-model="activeDate.user_id" name="user">
                            <option :value="null" class="px-3 py-2 text-sm">
                              -- Selecciona un asesor --
                            </option>
                            <option v-for="(user) in datesUsersList"
                                    v-bind:key="user.id" :value="user.id"
                                    class="px-3 py-2 text-sm">
                              {{ user.name }}
                            </option>
                          </select>
                        </div>
                        <div class="flex px-1 sm:px-2">
                          <div class="p-1 text-sm font-medium text-gray-700">
                            <NewspaperIcon class="m-1 h-7 w-7 " aria-hidden="true"/>
                          </div>
                          <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-8
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="form1" v-model="activeDate.form_id" name="form">
                            <option :value="null" class="px-3 py-2 text-sm">
                              -- Selecciona un formulario --
                            </option>
                            <option v-for="(form) in forms"
                                    v-bind:key="form.id" :value="form.id"
                                    class="px-3 py-2 text-sm">
                              {{ form.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--section selector-->
                  <div
                    class="w-full flex items-center bg-white py-4 overflow-x-auto
                    overflow-y-hidden">
                    <div
                      class="p-2 mx-2 my-2 cursor-pointer border-leadbox-900 text-tabs break-words"
                      @click="selectTab('info')"
                      v-bind:class="{ 'border-b-4' : selectedTab === 'info' }"
                      @keydown="selectTab('info')">
                      Datos
                    </div>
                    <div
                      class="p-2 mx-2 my-2 cursor-pointer border-leadbox-900 text-tabs break-words"
                      @click="selectTab('chats')"
                      v-bind:class="{ 'border-b-4' : selectedTab === 'chats' }"
                      @keydown="selectTab('chats')">
                      Chats
                    </div>
                    <div
                      class="p-2 mx-2 my-2 cursor-pointer border-leadbox-900 text-tabs break-words"
                      @click="selectTab('leads')"
                      v-bind:class="{ 'border-b-4' : selectedTab === 'leads' }"
                      @keydown="selectTab('leads')">
                      Leads
                    </div>
                    <div
                      class="p-2 mx-2 my-2 cursor-pointer border-leadbox-900 text-tabs break-words"
                      @click="selectTab('history')"
                      v-bind:class="{ 'border-b-4' : selectedTab === 'history' }"
                      @keydown="selectTab('history')">
                      Historial
                    </div>
                    <div class="p-2 mx-2 cursor-pointer border-leadbox-900 flex-shrink-0"
                         @click="selectTab('products')"
                         v-bind:class="{ 'border-b-4' : selectedTab === 'products' }"
                         @keydown="selectTab('products')">
                      Citas y Sesiones
                    </div>
                    <div class="p-2 mx-2 cursor-pointer border-leadbox-900 flex-shrink-0"
                         @click="selectTab('appointments')"
                         v-bind:class="{ 'border-b-4' : selectedTab === 'appointments' }"
                         @keydown="selectTab('appointments')">
                      Crear Cita
                    </div>
                  </div>
                  <!--lead content-->
                  <div class="w-full overflow-y-auto bg-scroll bg-contain h-full"
                       id="messages_container">
                    <template v-if="selectedTab === 'info'">
                      <div class="p-4 pt-1">
                        <!--buttons abierto cerrrado-->
                        <div class="flex">
                          <div class="w-6/12 py-2 px-1">
                            <button class=" rounded-lg p-2 border-2 w-full bg-sky-100 text-sm"
                                    @click="setStatusType('active')"
                                    v-bind:class="{ 'bg-sky-400' : selectedStatusType === 'active',
                              'border-sky-400' : selectedStatusType === 'active',
                              'text-white' : selectedStatusType === 'active'}">
                              Estados en Gestión
                            </button>
                          </div>
                          <div class="w-6/12 py-2">
                            <button class=" rounded-lg p-2 border-2 w-full bg-sky-100 text-sm"
                                    @click="setStatusType('closed')"
                                    v-bind:class="{ 'bg-sky-400' : selectedStatusType === 'closed',
                                'border-sky-400' : selectedStatusType === 'closed',
                                'text-white' : selectedStatusType === 'closed'}">
                              Estados Cerrados
                            </button>
                          </div>
                        </div>
                        <!--statuses-->
                        <div class="flex flex-wrap scroll-states">
                          <template v-if="isLoadingStatuses">Cargando..</template>
                          <template v-else>
                            <button class=" m-1 border-2 p-1 flex bg-white btn-states justify-center
                        items-center" v-for="(status) in
                              selectedStatusType === 'active' ? activeStatuses : closedStatuses"
                                    v-bind:key="status.key" @click="selectStatus(status)"
                                    v-bind:class="{ 'bg-sky-400' :
                                activeDate.company_status_id === status.id,
                                'border-sky-400' : activeDate.company_status_id === status.id,
                                'text-white' : activeDate.company_status_id === status.id}">
                              <CalendarIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                            v-if="status.activate_calendar"/>
                              <CashIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                        v-if="status.activate_product"/>
                              <ChevronDoubleRightIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                                      v-if="status.activate_transfer"/>
                              <ExternalLinkIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                                v-if="status.activate_integrations"/>
                              <ColorSwatchIcon class="mx-1 w-5 h-5" aria-hidden="true"
                                               v-if="status.activate_options"/>
                              <p>{{ status.name }}</p>
                            </button>
                          </template>
                        </div>
                        <!--datos-->
                        <div class="md:flex bg-white">
                          <div class="md:w-6/12 w-full p-1">
                            <h6 class="text-left my-1 title-header"><b>DATOS </b></h6>
                            <div class="my-1 flex text-sm"
                                 v-if="activeDate.patient_email">
                              <b class="m-1">Email: </b>
                              <template v-if="patient_email_editing">
                                <input v-model="activeDate.patient_email"
                                       class="border border-black mx-2">
                                <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                       @click="patient_email_editing = false" aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.patient_email }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="patient_email_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <div class="my-1 flex text-sm" v-if="activeDate.patient_phone">
                              <b class="m-1">Teléfono: </b>
                              <template v-if="patient_phone_editing">
                                <input v-model="activeDate.patient_phone"
                                       class="border border-black mx-2">
                                <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                       @click="patient_phone_editing = false" aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.patient_phone }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="patient_phone_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <div class="my-1 flex text-sm" v-if="activeDate.patient_document">
                              <b class="m-1">Identificación: </b>
                              <template v-if="patient_document_editing">
                                <input v-model="activeDate.patient_document"
                                       class="border border-black mx-2">
                                <XIcon class="h-5 w-5 m-1 cursor-pointer rounded-b-md"
                                       @click="patient_document_editing = false"
                                       aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.patient_document }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="patient_document_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <!--                          TODO:debe ser select-->
                            <div class="my-1 flex text-sm" v-if="activeDate.medical_center_name">
                              <b class="m-1">Centro: </b>
                              <template v-if="medical_center_editing">
                                <select class="my-1 bg-white appearance-none rounded-none relative
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 block
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="medical_center"
                                        v-model="activeDate.medical_center_id"
                                        name="medical_center">
                                  <option :value="null" class="px-3 py-2 text-sm">
                                    -- Selecciona un centro --
                                  </option>
                                  <option v-for="(medicalCenter) in medicalCenters"
                                          v-bind:key="medicalCenter.id" :value="medicalCenter.id"
                                          class="px-3 py-2 text-sm">
                                    {{ medicalCenter.city_name }} - {{ medicalCenter.name }}
                                  </option>
                                </select>
                                <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                       @click="medical_center_editing = false" aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.medical_center_city }}
                                  - {{ activeDate.medical_center_name }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="medical_center_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <div class="my-1 flex text-sm" v-if="activeDate.doctor_name">
                              <b class="m-1">Tratamiento: </b>
                              <template v-if="doctor_editing">
                                <select class="my-1 bg-white appearance-none rounded-none relative
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 block
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="doctor"
                                        v-model="activeDate.doctor_id"
                                        name="medical_center">
                                  <option :value="null" class="px-3 py-2 text-sm">
                                    -- Selecciona un tratamiento --
                                  </option>
                                  <option v-for="(doctor) in doctors"
                                          v-bind:key="doctor.id" :value="doctor.id"
                                          class="px-3 py-2 text-sm">
                                    {{ doctor.service }} - {{ doctor.name }}
                                  </option>
                                </select>
                                <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                       @click="doctor_editing = false" aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.doctor_service }}
                                  - {{ activeDate.doctor_name }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="doctor_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <!--                          TODO:debe ser select-->
                            <p class="m-0 text-sm" v-if="activeDate.specialty_name">
                              <b class="m-1">Especialidad: </b>
                              {{ activeDate.specialty_name }}
                            </p>
                            <!--                          TODO:debe ser select-->
                            <p class="m-0 text-sm" v-if="activeDate.procedure_name">
                              <b class="m-1">Procedimiento/zona: </b>
                              {{ activeDate.procedure_name }}
                            </p>
                            <div class="my-1 flex text-sm" v-if="activeDate.external_id">
                              <b class="m-1">Id Dasi: </b>
                              <template v-if="external_id_editing">
                                <input v-model="activeDate.external_id"
                                       class="border border-black mx-2">
                                <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                       @click="external_id_editing = false" aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.external_id }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="external_id_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <div class="my-1 flex text-sm">
                              <b class="m-1">Número de Ficha / Identificador: </b>
                              <template v-if="number_editing">
                                <input v-model="activeDate.number"
                                       class="border border-black mx-2">
                                <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                       @click="number_editing = false" aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.number }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="number_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <div class="my-1 flex text-sm">
                              <b class="m-1">Zonas: </b>
                              <template v-if="zones_editing">
                                <input v-model="activeDate.zones"
                                       class="border border-black mx-2">
                                <XIcon class="h-5 w-5 m-1 cursor-pointer"
                                       @click="zones_editing = false" aria-hidden="true"/>
                              </template>
                              <template v-else>
                                <p class="my-1">{{ activeDate.zones }}</p>
                                <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                               @click="zones_editing = true"
                                               aria-hidden="true"/>
                              </template>
                            </div>
                            <div class="my-1 flex flex-col text-sm"
                                 v-if="Object.keys(appointmentAppointments).length > 0">
                              <b class="m-1">Productos relacionados: </b>
                              <ul>
                                <li v-for="(appointmentProduct, key) in appointmentAppointments"
                                    :key="key">
                                  <p class="m-1">
                                    {{ appointmentProduct.product?.name }}
                                  </p>
                                </li>
                              </ul>
                            </div>
                            <!--eslint-disable-next-line-->
                            <div class="w-full flex justify-start items-start cursor-pointer"
                                 @click="openProductsModal()">
                              <p class="mx-2 text-sm my-3">
                                Agregar Productos
                              </p>
                              <button class="flex justify-center py-1 px-1 text-white mx-1
                            border border-transparent text-sm font-medium rounded-full
                                bg-sky-600 hover:bg-sky-900"
                                      type="button">
                                <PlusIcon class="m-1 h-5 w-5" aria-hidden="true"/>
                              </button>
                            </div>
                          </div>
                          <div class="md:w-6/12 w-full p-1">
                            <h6 class="text-left my-1 font-semibold title-header">NOTAS</h6>
                            <template v-if="isLoadingConversationNotes">
                              Cargando Notas...
                            </template>
                            <template v-else>
                              <template v-for="(note) in notes" v-bind:key="note.id">
                                <div class="p-4 flex flex-col rounded-lg border-2 border-black-600
                            shadow-xl shadow-black my-2">
                                  <p class="font-semibold">{{ note.data }}</p>
                                  <p>{{ note.user?.name }}</p>
                                  <p>{{ note.created_at?.formated_date }}</p>
                                </div>
                              </template>
                            </template>
                            <template v-if="showNoteInterface">
                              <div class="form-group">
                                <!--eslint-disable-next-line-->
                                <label for="newNote"><b>Nueva Nota</b></label>
                                <textarea class="my-1 appearance-none rounded-none relative block
                              w-full
                                 p-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md" v-model="note"
                                          name="newNote" id="newNote">
                            </textarea>
                              </div>
                              <div class="flex">
                                <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                        @click="showNoteInterface = false; note = '';"
                                        type="button">Cancelar
                                </button>
                                <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                        @click="createNote()">Aceptar
                                </button>
                              </div>
                            </template>
                            <template v-else>
                              <!--eslint-disable-next-line-->
                              <div class="flex justify-end cursor pointer"
                                   @click="showNoteInterface = true;">
                                <p class="mx-2 my-3 text-sm">
                                  Agregar nota
                                </p>
                                <button class="h-9 flex justify-center py-1 px-1 text-white mx-1
                            border border-transparent text-sm font-medium rounded-full
                                bg-sky-600 hover:bg-sky-900 " type="button">
                                  <PlusIcon class="m-1 h-5 w-5" aria-hidden="true"/>
                                </button>
                              </div>
                            </template>
                          </div>
                        </div>
                        <div class="flex" v-if="!activeDate.is_transferred &&
                    (activeDate.is_closed_positive || activeDate.is_closed_negative)">
                          <div class="bg-amber-100 m-2 p-4 rounded-lg w-full"
                               role="alert">
                            Esta cita se encuentra cerrada<span>: {{
                              activeDate.status?.name
                            }}</span>
                          </div>
                        </div>
                        <div class="flex" v-if="activeDate.already_attended_by">
                          <div class="bg-amber-100 m-2 p-4 rounded-lg w-full"
                               role="alert">
                            Esta persona ya se encuentra siendo atendida por
                            <strong>{{ activeDate.already_attended_by.user.name }}</strong>
                            en el formulario "{{ activeDate.already_attended_by.form.name }}"
                          </div>
                        </div>
                        <!--bottom actions lead-->
                        <div class="flex-col p-1">
                          <div class="flex justify-center">
                            <div class="flex-col w-full md:w-5/12 p-3">
                              <p class="text-center"><b>Canal:</b></p>
                              <select v-model="activeDate.channel_id"
                                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                                <option disabled :value="null">-- Seleccione un canal --</option>
                                <option v-for="(channel) in channels" v-bind:key="channel.id"
                                        :value="channel.id">
                                  {{ channel.name }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="flex w-full justify-center ">
                            <!-- eslint-disable-next-line-->
                            <a :href="'mailto:'+activeDate.patient_email"
                               :title="'Enviar email a '+activeDate.patient_email"
                               v-if="activeDate.patient_email">
                              <MailIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2
                              md:p-3 rounded-full text-white bg-email" aria-hidden="true"/>
                            </a>
                            <!-- eslint-disable-next-line-->
                            <a :title="'Llamar al 0'+activeDate.patient_phone.replace('+593', '')"
                               :href="'tel:0'+activeDate.patient_phone.replace('+593', '')"
                               v-if="activeDate.patient_phone">
                              <PhoneIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900
                              p-2 md:p-3 rounded-full text-white bg-phone" aria-hidden="true"/>
                            </a>
                            <!-- eslint-disable-next-line-->
                            <a
                              :href="'https://api.whatsapp.com/send?phone='+activeDate.patient_phone.replace('+593', '')"
                              v-if="activeDate.patient_phone" target="_blank"
                              :title="'Enviar mensaje de whatsapp a '+activeDate.patient_phone">
                              <ChatIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2
                              md:p-3 rounded-full text-white bg-chat" aria-hidden="true"/>
                            </a>
                            <!-- eslint-disable-next-line-->
                            <a :href="'sip:0'+activeDate.phone_national_number"
                               v-if="activeDate.phone && activeCompany.uuid
                     === 'b7a26e43-0168-4e20-a22b-5a8186e85a98'"
                               :title="'llamar por Issabel a 0'+activeDate.phone_national_number">
                              <PhoneIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900
                              p-2 md:p-3 rounded-full text-white bg-phone-issabel"
                                         aria-hidden="true"/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'chats'">
                      <div class="p-4">
                        <template v-if="isLoadingAppointmentConversations">Cargando...</template>
                        <template v-else>
                          <template v-if="appointmentConversations.length>0">
                            <template v-for="(conversation) in appointmentConversations"
                                      v-bind:key="conversation.id">
                              <div @keyDown="goToConversation(conversation.conversation?.uuid)"
                                   @click="goToConversation(conversation.conversation?.uuid)"
                                   class="p-4 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-2 bg-gray-100 cursor-pointer"
                                   v-if="conversation.conversation">
                                <div class="text-md px-2">
                                  <img class="w-14 h-14 my-2  rounded-full flex"
                                       src="@/assets/default-profile.svg"
                                       alt="user">
                                </div>
                                <div class="flex flex-col">
                                  <p class="text-md">
                                    <b>{{ conversation.conversation?.name }}</b>
                                  </p>
                                  <p class="text-md" v-if="conversation.conversation?.user_id">
                                    <b>Asignado a:</b> {{ conversation.conversation?.user?.name }}
                                  </p>
                                  <p class="text-sm">
                                    <b>ultima interacción:</b>
                                    {{ conversation.conversation?.formatted_last_interaction }}
                                  </p>
                                </div>
                              </div>
                            </template>
                          </template>
                          <template v-else>
                            <h6 class="text-center my-10">Cita no tiene conversaciones
                            </h6></template>
                        </template>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'leads'">
                      <template v-if="isLoadingAppointmentLeads">
                        <p class="text-center">Cargando leads...</p>
                      </template>
                      <template v-else>
                        <div class="flex flex-col p-4">
                          <template v-if="appointmentLeads.length < 1">
                            <p class="text-center my-6">Cita no tiene leads</p>
                          </template>
                          <template v-else>
                            <template v-for="(lead) in appointmentLeads" v-bind:key="lead.id">
                              <div class="p-4 flex flex-col rounded-lg border-2 border-gray-300
                            shadow-md shadow-black my-2 cursor-pointer bg-gray-100"
                                   @click="goToLead(lead.lead.uuid);" v-if="lead.lead"
                                   @keydown="goToLead(lead.lead.uuid);">
                                <p class="font-semibold">{{ lead.lead?.name }}</p>
                                <div class="flex" v-if="lead.lead?.status">
                                  <p><b>Estado: </b></p>
                                  <div class="rounded-full text-white p-2 text-xs m-1"
                                       :style="{backgroundColor: lead.lead?.status.color}">
                                  </div>
                                  <p>{{ lead.lead?.status?.name }}</p>
                                </div>
                                <p class="text-small"><b>Fecha de creación: </b>
                                  {{ lead.lead?.created_at?.date }}
                                </p>
                              </div>
                            </template>
                          </template>
                        </div>
                      </template>
                    </template>
                    <template v-if="selectedTab === 'contact'">
                      <div class="flex flex-col p-4">
                        <template v-if="activeDate.contact_id">
                          <div class="p-1">
                            <p><b>Nombre:</b> {{ activeDate.contact?.name }}</p>
                          </div>
                          <div class="p-1 flex" v-if="activeDate.contact?.email">
                            <p><b>Email:</b> {{ activeDate.contact?.email }}</p>
                            <!--eslint-disable-next-line-->
                            <a v-if="activeDate.contact?.email" title="Enviar email"
                               :href="'mailto:'+activeDate.contact?.email">
                              <MailIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                            </a>
                          </div>
                          <div class="p-1 flex" v-if="activeDate.contact?.secondary_email">
                            <p><b>Email Secundario:</b> {{ activeDate.contact?.secondary_email }}
                            </p>
                            <!--eslint-disable-next-line-->
                            <a v-if="activeDate.contact?.secondary_email" title="Enviar email"
                               :href="'mailto:'+activeDate.contact?.secondary_email">
                              <MailIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                            </a>
                          </div>
                          <div class="p-1 flex">
                            <p><b>Teléfono: </b>{{ activeDate.contact?.phone }}</p>
                            <!--eslint-disable-next-line-->
                            <a v-if="activeDate.contact?.phone" title="Llamar"
                               :href="'tel:'+activeDate.contact?.phone">
                              <PhoneOutgoingIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                            </a>
                            <!--eslint-disable-next-line-->
                            <a v-if="activeDate.contact?.phone" title="Enviar mensaje"
                               :href="'https://api.whatsapp.com/send?phone='+activeDate.contact?.phone?.replace('+', '')"
                               target="_blank">
                              <img class="w-6 h-6 mx-2" src="@/assets/whatsapp.png"
                                   alt="whatsapp">
                            </a>
                          </div>
                          <div class="p-1" v-if="activeDate.contact?.document">
                            <b>Documento:</b> {{ activeDate.contact?.document }}
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-center my-10">No tiene contacto</p>
                        </template>
                      </div>
                      <div class="flex flex-col px-4 overflow-y-auto h-fit"
                           v-if="activeDate.contact_id">
                        <template v-if="activeDate.contact?.leads.length>0">
                          <h6 class="text-left my-1 font-semibold title-header">Leads</h6>
                          <template v-for="(ld) in activeDate.contact?.leads"
                                    v-bind:key="ld.id">
                            <div class="p-4 flex rounded-lg border-2 border-gray-300
                            shadow-md shadow-black my-2 cursor-pointer
                            hover:bg-gray-200"
                                 v-bind:class="{
                                'bg-stone-400': ld.uuid === activeDate?.uuid,
                                'bg-gray-100': ld.uuid !== activeDate?.uuid,
                                 }"
                            >
                              <div class="flex-col flex-auto "
                                   :title="ld.uuid === activeDate?.uuid ?
                             'Este es el lead abierto':'Has click para abrir este lead'"
                                   @click="getDate(ld.uuid);"
                                   @keydown="getDate(ld.uuid);">
                                <div class="flex">
                                  <p class="font-semibold mx-1">{{ ld.name }}</p>
                                  <p v-if="ld.uuid === activeDate?.uuid" class="text-orange-500 ">
                                    (lead activo)
                                  </p>
                                </div>
                                <div class="flex">
                                  <img class="w-6 h-6 rounded-lg mx-1" :src="sourceDate(ld)"
                                       alt="user">
                                  <p>{{ ld.form_name }}</p>
                                </div>
                                <div class="flex" v-if="ld.status">
                                  <p class="mx-1"><b>Estado: </b></p>
                                  <div class="rounded-full text-white p-2 text-xs m-1"
                                       :style="{backgroundColor: ld.status?.color}">
                                  </div>
                                  <p>{{ ld.status?.name }}</p>
                                </div>
                                <div class="flex" v-if="ld.channel_name">
                                  <p class="mx-1"><b>Canal: </b></p>
                                  <p>{{ ld.channel_name }}</p>
                                </div>
                                <p class="text-small mx-1"><b>Fecha de creación: </b>
                                  {{ ld.created_at?.date }}
                                </p>
                              </div>
                              <div class=" flex-none  p-2 hover:bg-gray-300 cursor-pointer"
                                   v-if="ld.uuid !== activeDate?.uuid"
                                   title="Has click para eliminar este lead"
                                   @click="openDeleteContactLeadModal(ld)"
                                   @keydown="openDeleteContactLeadModal(ld);">
                                <TrashIcon class="w-7 mr-1 text-red-600" aria-hidden="true"/>
                              </div>
                            </div>
                          </template>
                        </template>
                        <template v-if="activeDate.contact?.orders.length>0">
                          <h6 class="text-left my-1 font-semibold title-header">Órdenes</h6>
                          <div v-for="(order) in activeDate.contact?.orders"
                               v-bind:key="order.id"
                               class="p-2 flex flex-col rounded-lg border-2 border-black-600
                               shadow-xl shadow-black my-3 bg-gray-100">
                            <p class="text-center text-lg my-1"><b>Orden No.{{ order.uuid }}</b></p>
                            <div class="bg-white p-2 rounded-lg flex">
                              <div class="w-1/2">
                                <p class="text-center"><b>Método de pago</b></p>
                                <p class="text-center">{{ getPayment(order.payment_method) }}</p>
                              </div>
                              <div class="w-1/2">
                                <p class="text-center"><b>Fecha de compra</b></p>
                                <p class="text-center">{{ order.created_at.formated_date }}</p>
                              </div>
                              <div class="w-1/2">
                                <p class="text-center"><b>Total</b></p>
                                <p class="text-center">$ {{ order.total }}</p>
                              </div>
                            </div>
                            <p class="text-center text-lg my-1"><b>Carrito de compras</b></p>
                            <div class="p-2 w-full overflow-x-auto shadow-md sm:rounded-lg
                            bg-white">
                              <table class="table-auto w-full border border-gray-400">
                                <thead>
                                <tr class="bg-grey-200 border border-gray-200">
                                  <th class="text-center border border-gray-200">
                                    <b>Producto</b>
                                  </th>
                                  <th class="text-center border border-gray-200">
                                    <b>Cantidad</b></th>
                                  <th class="text-center border border-gray-200"><b>Precio</b>
                                  </th>
                                  <th class="text-center border border-gray-200"><b>Total</b></th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(item, key) in order.cart" v-bind:key="key">
                                  <tr v-if="item.product" class="border border-gray-200">
                                    <td class="flex justify-center border border-gray-200
                                      content-center">
                                      <img class="w-12 h-12 my-2 rounded-full self-center"
                                           :src="'https://leadbox.ec/storage/'+item.product?.image"
                                           :alt="item.product?.name">
                                      <div class="flex flex-col mx-2 content-center">
                                        <p>{{ item.product?.name }} x {{ item.quantity }}</p>
                                        <div v-if="item.note">
                                          <p><b>Observación:</b> {{ item.note }}</p>
                                        </div>
                                      </div>
                                    </td>
                                    <td class="text-center border border-gray-200">
                                      {{ item.quantity }}
                                    </td>
                                    <td class="text-center border border-gray-200">
                                      {{ item.price / item.quantity }}
                                    </td>
                                    <td class="text-center border border-gray-200">
                                      {{ item.price }}
                                    </td>
                                  </tr>
                                </template>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </template>
                      </div>

                    </template>
                    <template v-if="selectedTab === 'events'">
                      <div class="p-4 overflow-y-auto">
                        <template v-if="isLoadingLeadEvents">Cargando...</template>
                        <template v-else>
                          <template v-if="leadEvents.length>0">
                            <div v-for="(event) in leadEvents"
                                 v-bind:key="event.id">
                              <div class="p-2 mx-4 flex rounded-lg border-2 border-black-600
                              shadow-xl shadow-black my-2 bg-grey-100">
                                <div class="rounded-full text-white self-center w-5 h-5 mx-4"
                                     :style="{ 'background-color' : event.color }">
                                </div>
                                <div class="flex-col m-2">
                                  <p class="text-md">
                                    {{ event.title }}</p>
                                  <p>Fecha del evento: <b>
                                    {{ event.start_date }}
                                  </b>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <h6 class="text-center my-10">No hay eventos</h6>
                          </template>
                        </template>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'history'">
                      <div class="p-4 overflow-y-auto">
                        <template v-if="isLoadingAppointmentHistory">Cargando...</template>
                        <template v-else>
                          <template v-if="appointmentHistories.length>0">
                            <div v-for="(history) in appointmentHistories"
                                 v-bind:key="history.id"
                                 class="p-2 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                              <div class="rounded-full text-white self-center p-4"
                                   :style="{ 'background-color' : history.status?.color }">
                              </div>
                              <div class="flex flex-col m-4 my-2">

                                <p class="text-md">
                                  {{ history.status?.name }}
                                  {{ history.user ? ' - ' + history.user.name : '' }}
                                </p>
                                <p class="text-sm">{{ history.created_at.formated_date }}</p>
                                <p class="text-sm" v-if="history.is_transfer && history.form">
                                  Fue transferido desde el formulario
                                  <b>{{ history.form?.name }}</b>
                                </p>
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <p class="text-center text-center">No hay Historial</p>
                          </template>
                        </template>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'products'">
                      <div class="p-4 overflow-y-auto">
                        <p class="mb-1 text-center"><b>Historial de Sesiones</b></p>
                        <template v-if="Object.keys(appointmentAppointments).length > 0">
                          <div class="my-1">
                            <div class="p-4 bg-gray-100">
                              <div v-for="(appointmentProduct, key) in appointmentAppointments"
                                   :key="key" class="mb-4">
                                <!-- eslint-disable -->
                                <div @click="toggleAppointment(key)"
                                     class="text-white p-4 rounded-t-lg cursor-pointer flex
                             justify-between items-center"
                                     :class="{
                                'bg-gray-500': appointmentProduct.total === appointmentProduct.totalProduct,
                                'bg-teal-600': appointmentProduct.total !== appointmentProduct.totalProduct,
                              }">
                                  <h2 class="text-sm font-bold">
                                    {{ appointmentProduct.product?.name }}
                                    {{ appointmentProduct.total }} /
                                    {{ appointmentProduct.totalProduct }}
                                  </h2>
                                  <!-- Chevron para mostrar si está abierto o cerrado -->
                                  <svg v-if="appointmentProduct.open"
                                       xmlns="http://www.w3.org/2000/svg"
                                       class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                       stroke="currentColor"
                                       stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M5 15l7-7 7 7"/>
                                  </svg>
                                  <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6"
                                       fill="none"
                                       viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round"
                                          d="M19 9l-7 7-7-7"/>
                                  </svg>
                                </div>
                                <!-- Contenido del collapsible -->
                                <div v-show="appointmentProduct.open"
                                     class="p-2 grid grid-cols-1 gap-4 bg-white rounded-b-lg
                             border border-gray-300">
                                  <!-- Productos -->
                                  <div class=" w-full flex justify-end items-end">
                                    <button class="flex justify-center py-1 px-1 text-white mx-1
                            border border-transparent text-sm font-medium rounded-full
                                bg-red-600 hover:bg-red-900"
                                            @click="showDeleteAppointmentProductModal(appointmentProduct)">
                                      <TrashIcon class="m-1 h-5 w-5" aria-hidden="true"/>
                                    </button>
                                  </div>
                                  <div class="w-full flex justify-center items-center"
                                       v-if="appointmentProduct.total
                                     < appointmentProduct.totalProduct">
                                    <p class="mx-2 my-3 text-sm">Agregar próxima cita</p>
                                    <button class="flex justify-center py-1 px-1 text-white mx-1
                            border border-transparent text-sm font-medium rounded-full
                                bg-sky-600 hover:bg-sky-900"
                                            @click="createAppointmentForm()">
                                      <PlusIcon class="m-1 h-5 w-5" aria-hidden="true"/>
                                    </button>
                                  </div>
                                  <template v-if="appointmentProduct.appointments.length > 0">
                                    <!-- eslint-disable -->
                                    <div
                                      v-for="appointment in appointmentProduct.appointments"
                                      :key="appointment.id"
                                      class="p-3 flex flex-col rounded-lg
                                     shadow-xl shadow-black my-3"
                                      :class="{
                                         'bg-gray-300': appointment.is_closed_positive ||
                                                        appointment.is_closed_negative,
                                         'bg-amber-100' : activeDate.uuid === appointment.appointment_uuid,
                                      }">
                                      <div class="flex flex-col md:flex-row">
                                        <!-- Mitad izquierda: Imagen y datos -->
                                        <div class="flex-col w-full md:w-1/3 flex items-center">
                                          <!-- Imagen ocupando 1/4 del espacio -->
                                          <div class="w-1/4 flex items-center justify-center"
                                               v-if="appointment.product?.image">
                                            <img class="w-3/4 h-auto rounded-sm"
                                                 :src="order.is_woocommerce ? appointment.product?.image : 'https://leadbox.ec/storage/' + appointmentProduct.product?.image"
                                                 :alt="appointment.product_name">
                                          </div>
                                          <!-- Texto ocupando 3/4 del espacio -->
                                          <div class="w-3/4 flex flex-col p-2">
                                            <p class="text-left text-base my-1">
                                              <b>Fecha de la cita: </b>
                                              {{ appointment.appointment_date }}
                                            </p>
                                            <p class="text-left text-base my-1">
                                              <b>Inicio: </b>
                                              {{ appointment.appointment_start }}
                                            </p>
                                            <p class="text-left text-base my-1">
                                              <b>Fin: </b>
                                              {{ appointment.appointment_end }}
                                            </p>
                                            <p class="text-left text-base my-1">
                                              <b>Precio: </b>
                                              {{ appointment.price }}
                                            </p>
                                            <p class="text-left text-sm my-1"
                                               v-if="appointment.note">
                                              <b>Nota: </b> {{ appointment.note }}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="flex-col w-full md:w-1/3 flex items-center">
                                          <!-- Texto ocupando 3/4 del espacio -->
                                          <div class="w-3/4 flex flex-col p-2">
                                            <b>Sesiones</b>
                                            <div class="text-left text-base my-1 flex">
                                              <b class="my-1 mx-1">Totales: </b>
                                              <input v-if="editTotalSessions"
                                                     class="border-1 rounded-md border
                                                    border-gray-300 w-16"
                                                     v-model="appointment.total_sessions_value"
                                                     type="number">
                                              <span class="text-sm my-1 mx-1" v-else>
                                                {{ appointment.total_sessions_value }}
                                              </span>
                                              <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                                             @click="editTotalSessions = !editTotalSessions"
                                                             aria-hidden="true"/>
                                            </div>

                                            <div class="text-left text-base my-1 flex">
                                              <b class="m-1">Utilizadas: </b>
                                              <input v-if="editUsedSessions"
                                                     class="border-1 rounded-md border
                                                    border-gray-300 w-16"
                                                     v-model="appointment.used_sessions"
                                                     type="number">
                                              <span class="text-sm m-1" v-else>
                                                {{ appointment.used_sessions }}
                                              </span>
                                              <PencilAltIcon class="h-5 w-5 m-1 cursor-pointer"
                                                             @click="editUsedSessions = !editUsedSessions"
                                                             aria-hidden="true"/>
                                            </div>
                                            <p class="text-left text-base my-1">
                                              <b class="m-1">Pendientes: </b>
                                              <span class="m-1">{{
                                                  appointment.total_sessions_value - appointment.used_sessions
                                                }}</span>

                                            </p>
                                          </div>
                                        </div>
                                        <!-- Mitad derecha: Contador de sesiones y botones -->
                                        <div
                                          class="flex-col w-full md:w-1/3 flex flex-col items-center justify-center p-2">
                                          <div class="relative inline-block text-left"
                                               v-if="appointment.status_id">
                                            <div
                                              class="flex items-center space-x-2 p-2 rounded-md focus:outline-none"
                                            >
                                              <select v-model="appointment.status_id"
                                                      class="px-3 py-2 rounded-md border
                                                    border-gray-300"
                                                      :disabled="appointment.is_closed_positive ||
                                                        appointment.is_closed_negative"
                                              >
                                                <option>seleccione</option>
                                                <option v-for="status in statuses"
                                                        :value="status.id"
                                                        :key="status.id">
                                                  {{ status.name }}
                                                </option>
                                              </select>
                                              <span
                                                class="w-7 h-7 rounded-full"
                                                :style="{ backgroundColor: appointment.status_color }"
                                              ></span>
                                            </div>
                                          </div>
                                          <!-- Número y botón para aumentar sesiones -->
                                          <!--                                        cuando hay sesiones pendientes-->
<!--                                          <template v-if="appointment.used_sessions-->
<!--                                                      < appointment.total_sessions_value">-->
<!--                                            <div class="flex items-center mb-2">-->
<!--                                              <button-->
<!--                                                class="ml-2 bg-blue-500 text-white px-3 py-1 rounded"-->
<!--                                                v-if="!appointment.is_closed_positive &&-->
<!--                                                        !appointment.is_closed_negative"-->
<!--                                                @click="decreaseSessions(appointment)">-->
<!--                                                - -->
<!--                                              </button>-->
<!--                                              <p class="text-sm font-bold mx-3"-->
<!--                                                 v-if="!appointment.is_closed_positive &&-->
<!--                                                        !appointment.is_closed_negative">-->
<!--                                                {{ appointment.used_sessions_copy }}-->
<!--                                              </p>-->
<!--                                              <p class="text-base font-bold mx-3"-->
<!--                                                 v-if="appointment.is_closed_positive ||-->
<!--                                                        appointment.is_closed_negative">-->
<!--                                                Cita Cerrada-->
<!--                                              </p>-->
<!--                                              <button-->
<!--                                                class="bg-blue-500 text-white px-3 py-1 rounded"-->
<!--                                                v-if="!appointment.is_closed_positive &&-->
<!--                                                        !appointment.is_closed_negative"-->
<!--                                                @click="increaseSessions(appointment)">-->
<!--                                                +-->
<!--                                              </button>-->
<!--                                            </div>-->
<!--                                          </template>-->
<!--                                          <template v-else>-->
<!--                                            -->
<!--                                          </template>-->
                                          <div class="flex flex-col items-center mb-2">
                                            <p class="text-base font-bold">
                                              {{ appointment.used_sessions }}
                                              {{ ' / ' }}
                                              {{
                                                appointment.total_sessions_value
                                              }}
                                            </p>

                                            <template v-if="appointment.used_sessions
                                                      === appointment.total_sessions_value">
                                              <p class="text-base font-bold">
                                                Sesiones Completas
                                              </p>
                                            </template>
                                            <p class="text-base font-bold mx-3"
                                               v-if="appointment.is_closed_positive ||
                                                        appointment.is_closed_negative">
                                              Cita Cerrada
                                            </p>
                                          </div>
                                          <template
                                            v-if="activeDate.uuid === appointment.appointment_uuid">
                                            <button class="bg-green-720 h-10 text-white rounded-lg px-4
                                                          py-2 rounded mb-3"
                                                    @click="updateSessionsHandler(appointment)">
                                              Actualizar sesiones
                                            </button>
                                          </template>
                                          <template v-else>
                                            <p v-if="!appointment.is_closed_positive &&
                                                        !appointment.is_closed_negative"
                                               class="text-left text-sm my-1">
                                              Ingresa a la cita para actualizar las sesiones
                                              utilizadas.</p>
                                            <p @click="getDate(appointment.appointment_uuid)">
                                              Ver
                                            </p>
                                          </template>
                                        </div>
                                      </div>
                                      <!--                                    <div class="flex w-full justify-center items-center">
                                                                            <button class="bg-green-720 h-10 text-white rounded-lg px-4
                                                                                                py-2 rounded mb-3"
                                                                                 v-if="activeDate.uuid !== appointment.appointment_uuid"
                                                                                 @click="getDate(appointment.appointment_uuid)">
                                                                              Ver cita
                                                                            </button>
                                                                          </div>-->
                                      <div class="flex w-full justify-center items-center">
                                        <div class="w-full md:w-1/2 flex-col">
                                          <template v-if="appointment.showNoteInterface">
                                            <div class="form-group w-full">
                                              <!--eslint-disable-next-line-->
                                              <label for="newNote"><b>Agregar Nota</b></label>
                                              <textarea class="my-1 appearance-none rounded-none
                                          w-full p-2 border border-gray-300
                                          placeholder-gray-500 text-gray-900 rounded-b-md"
                                                        v-model="appointment.note">
                                          </textarea>
                                            </div>
                                            <div class="flex w-full">
                                              <button class="group w-full md:w-1/2 flex justify-center
                                          p-2 border border-transparent text-sm font-medium
                                          rounded-md text-white bg-gray-300 hover:bg-gray-400
                                          focus:outline-none focus:ring-2 focus:ring-offset-2
                                          focus:ring-gray-500 mx-1"
                                                      @click="appointment.showNoteInterface = false;"
                                                      type="button">Cancelar
                                              </button>
                                              <button class="group w-full md:w-1/2 flex justify-center
                                          py-2 px-4 border border-transparent text-sm font-medium
                                          rounded-md text-white mx-1 bg-sky-600 hover:bg-sky-900 "
                                                      type="button"
                                                      @click="updateProductNote(appointment)">
                                                Aceptar
                                              </button>
                                            </div>
                                          </template>
                                          <template v-else>
                                            <div class="flex justify-center cursor pointer"
                                                 @click="appointment.showNoteInterface = true;">
                                              <p class="mx-2 my-3 text-base text-sm">
                                                Agregar nota
                                              </p>
                                              <button class="h-9 flex justify-center py-1 px-1 text-white
                                          mx-1 border border-transparent text-sm font-medium
                                          rounded-full bg-sky-600 hover:bg-sky-900 " type="button">
                                                <PlusIcon class="m-1 h-5 w-5" aria-hidden="true"/>
                                              </button>
                                            </div>
                                          </template>
                                        </div>

                                      </div>
                                    </div>
                                  </template>
                                  <template v-else>
                                    <p>No hay citas relacionadas</p>
                                  </template>
                                </div>
                              </div>
                            </div>
                            <div class="w-full flex justify-center items-center cursor-pointer"
                                 @click="openProductsModal()">
                              <p class="mx-2 text-sm my-3">
                                Agregar otros Productos y Sesiones
                              </p>
                              <button class="flex justify-center py-1 px-1 text-white mx-1
                            border border-transparent text-sm font-medium rounded-full
                                bg-sky-600 hover:bg-sky-900"
                                      type="button">
                                <PlusIcon class="m-1 h-5 w-5" aria-hidden="true"/>
                              </button>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-center my-4 text-gray-500">No tiene productos ni
                            sesiones</p>
                          <div class="w-full flex justify-center items-center cursor-pointer"
                               @click="openProductsModal()">
                            <p class="mx-2 text-sm my-3">
                              Agregar Productos y Sesiones
                            </p>
                            <button class="flex justify-center py-1 px-1 text-white mx-1
                            border border-transparent text-sm font-medium rounded-full
                                bg-sky-600 hover:bg-sky-900"
                                    type="button">
                              <PlusIcon class="m-1 h-5 w-5" aria-hidden="true"/>
                            </button>
                          </div>
                        </template>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'appointments'">
                      <div class="justify-start p-4">
                        <div class="justify-start">
                          <template v-if="isLoadingCreateAppointment">
                            <p class="text-center">Cargando...</p>
                          </template>
                          <template v-else>
                            <h6 class="text-left my-1 font-semibold ">CREAR NUEVA CITA</h6>
                            <select v-model="newAppointment.connection"
                                    class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsCities">
                              <option disabled :value="null">-- Seleccione la conexión --</option>
                              <option v-for="(connection) in appointmentConnections"
                                      v-bind:key="connection.id"
                                      :value="connection">
                                {{ connection.name }}
                              </option>
                            </select>
                            <template v-if="newAppointment.connection">
                              <div class="flex flex-col md:flex-row">
                                <div class="flex-col w-full px-1">
                                  <div class="p-2"><b>Nombre:</b></div>
                                  <!--eslint-disable-next-line-->
                                  <input class="m-2 appearance-none rounded-none relative block
                                  w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.name" type="text">
                                </div>
                              </div>
                              <div class="flex flex-col md:flex-row">
                                <div class="flex-col w-full md:w-1/2 px-1">
                                  <div class="p-2"><b>Teléfono:</b></div>
                                  <!--eslint-disable-next-line-->
                                  <input class="m-2 appearance-none rounded-none relative block
                                  w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.phone" type="tel">
                                </div>
                                <div class="flex-col w-full md:w-1/2 px-1">
                                  <div class="p-2"><b>Email:</b></div>
                                  <!--eslint-disable-next-line-->
                                  <input class="m-2 appearance-none rounded-none relative block
                                  w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.email" type="text">
                                </div>
                              </div>
                              <div class="flex-col w-full md:w-1/2 px-1">
                                <input type="radio" value="cedula" class="m-3"
                                       v-model="newAppointment.type">
                                <!--eslint-disable-next-line-->
                                <label for="cedula" class="my-4">Cédula</label>
                                <input type="radio" name="ruc" value="ruc" class="m-3"
                                       v-model="newAppointment.type">
                                <!--eslint-disable-next-line-->
                                <label for="ruc" class="my-4">RUC</label>
                                <input type="radio" name="passport" value="passport"
                                       class="m-3" v-model="newAppointment.type">
                                <!--eslint-disable-next-line-->
                                <label for="passport" class="my-4 mr-4">Pasaporte</label>
                                <input name="identificacion"
                                       placeholder="Número de identificación" type="text"
                                       v-model="newAppointment.document"
                                       class="my-2 appearance-none rounded-none relative block
                                       w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                              </div>
                              <div class="flex-col">
                                <p class="my-1"><b>Número de Ficha:</b></p>
                                <!--eslint-disable-next-line-->
                                <input class="m-2 appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.number" type="text">
                              </div>
                              <div class="flex-col" v-if="newAppointment.cities.length > 0">
                                <p class="my-1"><b>Ciudad:</b></p>
                                <select v-model="newAppointment.city"
                                        class="my-2 appearance-none rounded-none relative block
                                        w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsMedicalCenters">
                                  <option disabled :value="null">-- Seleccione la ciudad --</option>
                                  <option v-for="(city) in newAppointment.cities"
                                          v-bind:key="city.id"
                                          :value="city">
                                    {{ city.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-col"
                                   v-if="newAppointment.sectors.length > 0 && newAppointment.city">
                                <p class="my-1"><b>Sector:</b></p>
                                <select v-model="newAppointment.sector"
                                        class="my-2 appearance-none rounded-none relative block
                                        w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSectorMedicalCenters">
                                  <option disabled :value="null">-- Seleccione el sector --</option>
                                  <option v-for="(sector, sk) in newAppointment.sectors"
                                          v-bind:key="sk" :value="sector.sector">
                                    {{ sector.sector }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-col"
                                   v-if="newAppointment.medicalCenters.length > 0
                               && newAppointment.city">
                                <p class="my-1"><b>Centro:</b></p>
                                <select v-model="newAppointment.medicalCenter"
                                        class="my-2 appearance-none rounded-none relative block
                                        w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsDoctorsAndSpecialties">
                                  <option disabled :value="null">-- Seleccione el local --</option>
                                  <option v-for="(medicalCenter) in newAppointment.medicalCenters"
                                          v-bind:key="medicalCenter.id"
                                          :value="medicalCenter">
                                    {{ medicalCenter.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-col"
                                   v-if="newAppointment.doctors.length > 0 && newAppointment.city
                             && newAppointment.medicalCenter">
                                <p class="my-1"><b>Tratamientos:</b></p>
                                <select v-model="newAppointment.doctor"
                                        class="my-2 appearance-none rounded-none relative block
                                        w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSpecialtiesByDoctor">
                                  <option disabled :value="null">-- Seleccione el local --</option>
                                  <option v-for="(doctor) in newAppointment.doctors"
                                          v-bind:key="doctor.id"
                                          :value="doctor">
                                    {{ doctor.service }} - {{ doctor.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-col"
                                   v-if="newAppointment.specialties.length > 0 &&
                                   newAppointment.city
                             && newAppointment.medicalCenter">
                                <p class="my-1"><b>Zonas:</b></p>
                                <select v-model="newAppointment.specialty"
                                        class="my-2 appearance-none rounded-none relative block
                                        w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSchedules">
                                  <option disabled :value="null">-- Seleccione el local --</option>
                                  <option v-for="(specialty) in newAppointment.specialties"
                                          v-bind:key="specialty.id"
                                          :value="specialty">
                                    {{ specialty.name }}
                                  </option>
                                </select>
                              </div>
                              <div class="flex-col"
                                   v-if="newAppointment.medicalCenter && newAppointment.city">
                                <p class="my-1"><b>Fecha:</b></p>
                                <!--eslint-disable-next-line-->
                                <input class="my-2 appearance-none rounded-none relative block
                                w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.date" type="date"
                                       @change="getAppointmentsSchedules">
                              </div>
                              <div class="flex-col"
                                   v-if="newAppointment.medicalCenter && newAppointment.city
                             && newAppointment.date && newAppointment.schedules.length > 0">
                                <p class="my-1"><b>Horario:</b></p>
                                <select v-model="newAppointment.schedule"
                                        class="my-2 appearance-none rounded-none relative block
                                        w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                                  <option disabled :value="null">
                                    -- Seleccione el horario --
                                  </option>
                                  <option v-for="(schedule) in newAppointment.schedules"
                                          v-bind:key="schedule.id"
                                          :value="schedule">
                                    {{ schedule.startLabel }} - {{ schedule.endLabel }}
                                  </option>
                                </select>
                              </div>
                              <div class="w-full flex">
                                <div class="m-1">
                                  <Toggle v-model="newAppointment.addProduct"/>
                                </div>
                                <p class="ml-1 text-md">Agregar producto/s?</p>
                              </div>
                              <template v-if="newAppointment.addProduct">
                                <div class="my-1">
                                  <div class="p-4 bg-gray-100">
                                    <div v-for="category in categoriesCopy" :key="category.id"
                                         class="mb-4">
                                      <!-- eslint-disable -->
                                      <div @click="toggleCategory(category.slug)"
                                           class="bg-teal-600 text-white p-4 rounded-t-lg cursor-pointer flex
                             justify-between items-center">
                                        <h2 class="text-sm font-bold">{{ category.name }}</h2>
                                        <!-- Chevron para mostrar si está abierto o cerrado -->
                                        <svg v-if="category.open" xmlns="http://www.w3.org/2000/svg"
                                             class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                             stroke="currentColor"
                                             stroke-width="2">
                                          <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M5 15l7-7 7 7"/>
                                        </svg>
                                        <svg v-else xmlns="http://www.w3.org/2000/svg"
                                             class="h-6 w-6"
                                             fill="none"
                                             viewBox="0 0 24 24" stroke="currentColor"
                                             stroke-width="2">
                                          <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M19 9l-7 7-7-7"/>
                                        </svg>
                                      </div>
                                      <!-- Contenido del collapsible -->
                                      <div v-show="category.open"
                                           class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4 bg-white rounded-b-lg
                             border border-gray-300">
                                        <!-- Productos -->
                                        <div v-for="product in category.products" :key="product.id"
                                             class="p-4 bg-white rounded-lg shadow-md border border-gray-300">
                                          <div class="flex justify-between items-center mb-4">
                                            <!-- Componente de Toggle -->
                                            <Toggle v-model="product.selected"/>
                                            <p class="text-gray-800 font-bold">{{
                                                product.name
                                              }}</p>
                                          </div>

                                          <!-- Precio o Precio con Descuento -->
                                          <div class="flex items-center justify-between mb-4">
                                            <p class="text-gray-800 font-medium">
                                              Precio Normal: $ {{ product.price }}
                                            </p>
                                            <template
                                              v-if="product.discount_price && product.discount_price !== product.price">
                                              <Toggle v-model="product.use_discount"
                                                      @change="updatePrice(product)"/>
                                              <p class="text-gray-800 font-medium">
                                                Precio con Descuento: $ {{ product.discount_price }}
                                              </p>
                                            </template>

                                          </div>

                                          <!-- Mostrar Precio Final Seleccionado -->
                                          <div class="mb-4">
                                            <!-- eslint-disable -->
                                            <label class="text-sm text-gray-600">Precio
                                              Final</label>
                                            <input v-model="product.final_price" type="text"
                                                   readonly
                                                   class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                                          </div>

                                          <!-- Cantidad -->
                                          <div class="flex justify-between mb-4">
                                            <div class="w-1/2 pr-2">
                                              <!-- eslint-disable -->
                                              <label class="text-sm text-gray-600">Cantidad</label>
                                              <input v-model="product.quantity" type="number"
                                                     min="1"
                                                     placeholder="1" @change="updatePrice(product)"
                                                     class="appearance-none rounded-none block w-full px-3 py-2
                                       border border-gray-300 bg-gray-50 text-gray-700"/>
                                            </div>
                                            <div class="w-1/2 pr-2">
                                              <!-- eslint-disable -->
                                              <label class="text-sm text-gray-600">No. de
                                                Citas</label>
                                              <input v-model="product.number_dates" type="number"
                                                     min="1"
                                                     placeholder="1"
                                                     class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                                            </div>
                                          </div>

                                          <!-- Nota -->
                                          <div class="mb-4">
                                            <!-- eslint-disable -->
                                            <label class="text-sm text-gray-600">Nota</label>
                                            <textarea v-model="product.note"
                                                      placeholder="Agregar nota"
                                                      class="appearance-none rounded-none block w-full px-3 py-2
                                        border border-gray-300 bg-gray-50 text-gray-700">
                              </textarea>
                                          </div>

                                          <!-- Total -->
                                          <div class="text-right text-gray-800 font-bold">
                                            <p>Total: $ {{
                                                product.quantity * product.final_price
                                              }}</p>
                                          </div>
                                          <!-- Total -->
                                          <div class="text-right text-gray-800 font-bold">
                                            <p>Total Sesiones:
                                              {{ product.quantity * product.number_dates }}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="w-full flex text-lg justify-center">
                                      <p><b>TOTAL: </b>
                                        $ {{ total_products }}</p>
                                    </div>
                                    <div class="w-full flex text-lg justify-center">
                                      <p><b>TOTAL SESIONES: </b> {{ total_dates }}</p>
                                    </div>
                                  </div>
                                </div>
                              </template>
                              <div class="w-full flex">
                                <div class="m-1">
                                  <Toggle v-model="newAppointment.addUser"/>
                                </div>
                                <p class="ml-1">Asignar usuario?</p>
                              </div>
                              <template v-if="newAppointment.addUser">
                                <!--eslint-disable-next-line-->
                                <label class="w-full text-left" for="leadUser">
                                  <b>Usuario:</b>
                                </label>
                                <select class="my-2 appearance-none rounded-none relative block
                                w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="datesUsersList.length < 1"
                                        v-model="newAppointment.user" name="leadUser">
                                  <option :value="null" class="px-3 py-2 text-sm">
                                    -- Selecciona un asesor --
                                  </option>
                                  <option v-for="(usr) in datesUsersList"
                                          v-bind:key="usr.id" :value="usr"
                                          class="px-3 py-2 text-sm">
                                    {{ usr.name }}
                                  </option>
                                </select>
                              </template>
                              <div class="flex" v-if="newAppointment.schedule">
                                <button class="group relative w-full flex justify-center py-2 px-4
                              border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                        @click="createAppointmentDate()">Crear
                                </button>
                              </div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'integrations'">
                      <div class="p-4">
                        <template v-if="activeDate.integrations">
                          <div v-for="(integration, type) in activeDate.integrations"
                               v-bind:key="type">
                            <div class="mb-5" v-if="type === 'vtiger' || type === 'teojama'">
                              <div class="mb-3">
                                <img
                                  :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                                  style="max-width: 100%; height: 20px" alt="{{type}}">
                              </div>
                              <div v-if="integration.response">
                                <strong>Respuesta:</strong> {{ integration.response }}
                              </div>
                              <div v-if="integration.id">
                                <strong>ID:</strong> {{ integration.id }}
                              </div>
                              <div v-if="integration.user_id">
                                <strong>ID de usuario:</strong> {{ integration.user_id }}
                              </div>
                              <div v-if="integration.user_name">
                                <strong>Nombre de usuario:</strong> {{ integration.user_name }}
                              </div>
                              <div v-if="integration.user_id && type === 'vtiger'
                            && !integration.user_name">
                                <strong>Usuario:</strong> {{
                                  filterVtigerUser(integration.user_id, type)
                                }}
                              </div>
                              <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                            </div>
                            <div class="mb-5" v-if="type === 'oracle-sales' || type === 'pilot' ">
                              <div class="mb-3" v-if="type === 'oracle-sales'">
                                <img
                                  :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                                  style="max-width: 100%; height: 20px" alt="{{type}}">
                              </div>
                              <div class="mb-3" v-if="type === 'pilot'">
                                <img
                                  :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                                  style="max-width: 100%; height: 20px" alt="{{type}}">
                              </div>
                              <div v-if="integration.response">
                                <strong>Respuesta:</strong> {{ integration.response }}
                              </div>
                              <div v-if="integration.id">
                                <strong>ID:</strong> {{ integration.id }}
                              </div>
                              <div v-if="integration.status">
                                <strong>Estado:</strong> {{ integration.status }}
                              </div>
                              <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                            </div>
                            <div class="mb-5" v-if="type === 'mailer-lite' ">
                              <div class="mb-3">
                                <img
                                  :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                                  style="max-width: 100%; height: 20px" alt="{{type}}">
                              </div>
                              <div v-if="integration.response">
                                <strong>Respuesta:</strong> {{ integration.response }}
                              </div>
                              <div v-if="integration.id">
                                <strong>ID:</strong> {{ integration.id }}
                              </div>
                              <div v-if="integration.status">
                                <strong>Estado:</strong> {{ integration.status }}
                              </div>
                              <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                            </div>
                            <div class="mb-5" v-if="type === 'nova-scoring' ">
                              <div class="mb-3">
                                <img
                                  :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                                  style="max-width: 100%; height: 20px" alt="{{type}}">
                              </div>
                              <div v-if="integration.response">
                                <strong>Mensaje:</strong> {{ integration.response }}
                              </div>
                              <div v-if="integration.requerimiento">
                                <strong>Requerimiento:</strong> {{ integration.requerimiento }}
                              </div>
                              <div v-if="integration.calificacion">
                                <strong>Calificación:</strong> {{ integration.calificacion }}
                              </div>
                              <div v-if="integration.respuesta">
                                <strong>Respuesta:</strong> {{ integration.respuesta }}
                              </div>
                              <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                            </div>
                            <div class="mb-5" v-if="type === 'gxm' ">
                              <div class="mb-3">
                                <img
                                  :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                                  style="max-width: 100%; height: 20px" alt="{{type}}">
                              </div>
                              <div v-if="integration.id">
                                <strong>ID respuesta:</strong> {{ integration.id }}
                              </div>
                              <div v-if="integration.data">
                                <strong>Datos recibidos:</strong> {{ integration.data }}
                              </div>
                              <div v-if="integration.payload">
                                <strong>Datos enviados:</strong> {{ integration.payload }}
                              </div>
                              <div v-if="integration.response">
                                <strong>Respuesta:</strong> {{ integration.response }}
                              </div>
                              <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <p class="text-center">Aquí tendra un estado del lead con respecto a todas
                            las integraciones instaladas</p>
                        </template>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'campaign'">
                      <div class="flex flex-col p-4">
                        <template v-if="activeDate.referral_id">
                          <p><b>ID:</b> {{ activeDate.referral_id }}</p>
                          <p><b>Tipo:</b> {{ activeDate.referral_type }}</p>
                          <p><b>Url:</b> <a :href="activeDate.referral_url" target="_blank"
                                            class="text-sky-500 underline">
                            {{ activeDate.referral_url }}</a></p>
                          <p> {{ activeDate.referral_headline }}</p>
                          <p v-html="activeDate.referral_body"></p>
                        </template>
                        <template
                          v-if="activeDate.ad_id !== null || activeDate.ad_name !== null ||
                      activeDate.campaign_id !== null || activeDate.campaign_name !== null ||
                      activeDate.platform !== null || activeDate.post !== null">
                          <p><b>Id anuncio:</b> {{ activeDate.ad_id }}</p>
                          <p><b>Nombre anuncio:</b> {{ activeDate.ad_name }}</p>
                          <p><b>Id campaña:</b> {{ activeDate.campaign_id }}</p>
                          <p><b>Nombre campaña:</b> {{ activeDate.campaign_name }}</p>
                          <p><b>Plataforma:</b> {{ activeDate.platform }}</p>
                          <p v-if="activeDate.post !== null"><b>Post:</b>
                            <a :href="activeDate.post"
                               target="_blank"
                               class="text-sky-500 underline">
                              {{ activeDate.post }}</a></p>
                        </template>
                      </div>
                    </template>
                    <template v-if="selectedTab === 'documents'">
                      <div class="p-4 overflow-y-auto">
                        <template v-if="isLoadingLeadDocuments">Cargando...</template>
                        <template v-else>
                          <template v-if="leadDocuments.length>0">
                            <div v-for="(document) in leadDocuments"
                                 v-bind:key="document.id"
                                 class="p-3 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                              <div class="flex flex-col w-full">
                                <p class="text-lg text-center my-1">
                                  <b>{{ document.original_name }}</b>
                                </p>
                                <div class="flex text-sm my-1">
                                  <div class="w-full md:w-6/12 text-center">
                                    <p class=""><b>Creado por:</b></p>
                                    <p class="">{{
                                        document.user
                                          ? document.user.name : 'Usuario eliminado'
                                      }}</p>
                                  </div>
                                  <div class="w-full md:w-6/12 text-center">
                                    <p class=""><b>Fecha de creación:</b></p>
                                    <p class="">{{ document.created_at.formated_date }}</p>
                                  </div>
                                </div>
                                <div class="flex">
                                  <button class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-red-600 hover:bg-red-700 m-1" @click="openEmailModal(document)">
                                    Compartir
                                  </button>
                                  <a class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-sky-600 hover:bg-sky-700 m-1" target="_blank"
                                     :href="'https://leadbox.ec'+document.path">Descargar</a>
                                  <button class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-yellow-600 hover:bg-yellow-700 m-1"
                                          v-if="activeUser.is_superadmin ||
                                      activeUser.is_supervisor || activeUser.is_admin"
                                          @click="openDeleteFileModal(document)">Eliminar
                                  </button>
                                </div>

                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <p class="text-center my-10">No hay documentos</p>
                          </template>
                        </template>
                        <div class="w-full flex justify-center p-2">
                          <button class="group relative w-1/3 flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600 mx-auto" type="button"
                                  @click="showDocumentModal = true;">Subir documento
                          </button>
                        </div>
                      </div>
                    </template>
                    <AppointmentStatusModal :showModal="showModal" @close="showModal = false"
                                            :status="modalStatus" @close-update="closeModalUpdate"/>
                    <!--re schedule modal-->
                    <ModalBox :showing="showReScheduleModal" @close="showReScheduleModal = false">
                      <div class="flex">
                        <p class="text-black">
                          <b>Reagendar esta cita</b></p>
                      </div>
                      <div>
                        <div class="flex-col"
                             v-if="reschedule.medical_center_id && reschedule.doctor_id">
                          <p class="my-1"><b>Fecha:</b></p>
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="reschedule.date" type="date"
                                 @change="getAppointmentsReSchedules">
                        </div>
                        <div class="flex-col"
                             v-if="reschedule.medical_center_id && reschedule.doctor_id
                             && reschedule.date && reschedule.schedules.length > 0">
                          <p class="my-1"><b>Horario:</b></p>
                          <select v-model="reschedule.schedule"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                            <option disabled :value="null">-- Seleccione el horario --</option>
                            <option v-for="(schedule) in reschedule.schedules"
                                    v-bind:key="schedule.id"
                                    :value="schedule">
                              {{ schedule.startLabel }} - {{ schedule.endLabel }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="flex">
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                @click="showReScheduleModal = false"
                                type="button">Cerrar
                        </button>
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                @click="reScheduleDate()">Aceptar
                        </button>
                      </div>
                    </ModalBox>
                    <!--next date modal-->
                    <ModalBox :showing="showNewAppointmentModal"
                              @close="showNewAppointmentModal = false">
                      <div class="flex">
                        <p class="text-black">
                          <b>Agregar próxima cita</b></p>
                      </div>
                      <div>
                        <div class="flex-col"
                             v-if="newAppointment.medicalCenter && newAppointment.city">
                          <p class="my-1"><b>Fecha:</b></p>
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.date" type="date"
                                 @change="getAppointmentsSchedules">
                        </div>
                        <div class="flex-col"
                             v-if="newAppointment.medicalCenter && newAppointment.city
                             && newAppointment.date && newAppointment.schedules.length > 0">
                          <p class="my-1"><b>Horario:</b></p>
                          <select v-model="newAppointment.schedule"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                            <option disabled :value="null">-- Seleccione el horario --</option>
                            <option v-for="(schedule) in newAppointment.schedules"
                                    v-bind:key="schedule.id"
                                    :value="schedule">
                              {{ schedule.startLabel }} - {{ schedule.endLabel }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="flex">
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                @click="showNewAppointmentModal = false"
                                type="button">Cerrar
                        </button>
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                @click="createAppointmentDate()">Aceptar
                        </button>
                      </div>
                    </ModalBox>
                    <!--products modal-->
                    <ModalBox :showing="showProductsModal" @close="showProductsModal = false">
                      <div class="flex">
                        <p class="text-black mb-1 text-center">
                          <b>Agregar productos a esta cita:</b></p>
                      </div>
                      <div class="p-4 bg-gray-100">
                        <div v-for="category in categoriesCopy" :key="category.id" class="mb-4">
                          <!-- eslint-disable -->
                          <div @click="toggleCategory(category.slug)"
                               class="bg-teal-600 text-white p-4 rounded-t-lg cursor-pointer flex
                             justify-between items-center">
                            <h2 class="text-sm font-bold">{{ category.name }}</h2>
                            <!-- Chevron para mostrar si está abierto o cerrado -->
                            <svg v-if="category.open" xmlns="http://www.w3.org/2000/svg"
                                 class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M5 15l7-7 7 7"/>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M19 9l-7 7-7-7"/>
                            </svg>
                          </div>
                          <!-- Contenido del collapsible -->
                          <div v-show="category.open"
                               class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4 bg-white rounded-b-lg
                             border border-gray-300">
                            <!-- Productos -->
                            <div v-for="product in category.products" :key="product.id"
                                 class="p-4 bg-white rounded-lg shadow-md border border-gray-300">
                              <div class="flex justify-between items-center mb-4">
                                <!-- Componente de Toggle -->
                                <Toggle v-model="product.selected"/>
                                <p class="text-gray-800 font-bold">{{ product.name }}</p>
                              </div>

                              <!-- Precio o Precio con Descuento -->
                              <div class="flex items-center justify-between mb-4">
                                <p class="text-gray-800 font-medium">
                                  Precio Normal: $ {{ product.price }}
                                </p>
                                <template
                                  v-if="product.discount_price && product.discount_price !== product.price">
                                  <Toggle v-model="product.use_discount"
                                          @change="updatePrice(product)"/>
                                  <p class="text-gray-800 font-medium">
                                    Precio con Descuento: $ {{ product.discount_price }}
                                  </p>
                                </template>

                              </div>

                              <!-- Mostrar Precio Final Seleccionado -->
                              <div class="mb-4">
                                <!-- eslint-disable -->
                                <label class="text-sm text-gray-600">Precio Final</label>
                                <input v-model="product.final_price" type="text" readonly
                                       class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                              </div>

                              <!-- Cantidad -->
                              <div class="flex justify-between mb-4">
                                <div class="w-1/2 pr-2">
                                  <!-- eslint-disable -->
                                  <label class="text-sm text-gray-600">Cantidad</label>
                                  <input v-model="product.quantity" type="number" min="1"
                                         placeholder="1" @change="updatePrice(product)"
                                         class="appearance-none rounded-none block w-full px-3 py-2
                                       border border-gray-300 bg-gray-50 text-gray-700"/>
                                </div>
                                <div class="w-1/2 pr-2">
                                  <!-- eslint-disable -->
                                  <label class="text-sm text-gray-600">No. de Citas</label>
                                  <input v-model="product.number_dates" type="number" min="1"
                                         placeholder="1"
                                         class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                                </div>
                              </div>

                              <!-- Nota -->
                              <div class="mb-4">
                                <!-- eslint-disable -->
                                <label class="text-sm text-gray-600">Nota</label>
                                <textarea v-model="product.note" placeholder="Agregar nota"
                                          class="appearance-none rounded-none block w-full px-3 py-2
                                        border border-gray-300 bg-gray-50 text-gray-700">
                              </textarea>
                              </div>

                              <!-- Total -->
                              <div class="text-right text-gray-800 font-bold">
                                <p>Total: $ {{ product.quantity * product.final_price }}</p>
                              </div>
                              <!-- Total -->
                              <div class="text-right text-gray-800 font-bold">
                                <p>Total Sesiones: {{ product.quantity * product.number_dates }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-full flex text-lg justify-center">
                          <p><b>TOTAL: </b>
                            $ {{ total_products }}</p>
                        </div>
                        <div class="w-full flex text-lg justify-center">
                          <p><b>TOTAL SESIONES: </b> {{ total_dates }}</p>
                        </div>
                      </div>
                      <div class="flex">
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                @click="showProductsModal = false"
                                type="button">Cerrar
                        </button>
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                @click="addProducts()">Aceptar
                        </button>
                      </div>
                    </ModalBox>
                    <!--delete file modal-->
                    <ModalBox :showing="showDeleteFileModal" @close="showDeleteFileModal = false">
                      <div class="flex">
                        <p class="text-black">
                          <b>¿Quieres eliminar el documento {{
                              selectedDocument.original_name
                            }}?</b>
                        </p>
                      </div>
                      <div class="flex">
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                @click="showDeleteFileModal = false"
                                type="button">Cerrar
                        </button>
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                @click="deleteFile()">Aceptar
                        </button>
                      </div>
                    </ModalBox>
                    <!--delete products modal-->
                    <ModalBox :showing="showDeleteProductModal"
                              @close="showDeleteProductModal = false">
                      <div class="flex-col">
                        <p class="text-black">
                          <b>¿Quieres eliminar el producto {{
                              selectedAppointmentProduct.product.name
                            }}?</b>
                        </p>
                        <p>Se eliminaran todas las referencias a este producto en todas las citas
                          relacionadas</p>
                        <p>Este proceso no se puede revertir</p>
                      </div>
                      <div class="flex">
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                @click="showDeleteProductModal = false"
                                type="button">Cerrar
                        </button>
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                @click="deleteAppointmentProduct(selectedAppointmentProduct)">Aceptar
                        </button>
                      </div>
                    </ModalBox>

                    <!--delete contact lead modal-->
                    <ModalBox :showing="showDeleteContactLeadModal"
                              @close="showDeleteContactLeadModal = false">
                      <div class="flex flex-col p-4">
                        <p class="text-black">
                          <b>¿Estás Seguro?</b>
                        </p>
                        <p class="text-black">
                          ¿Quiéres eliminar el lead <b>{{ selectedContactLead?.name }}</b>,
                          perteneciente al formulario <b>{{ selectedContactLead.form_name }}</b>,
                          proveniente de <b>{{ selectedContactLead.form_provider }}?</b>
                        </p>
                        <p class="text-red-600">Esta acción no se puede revertir</p>
                      </div>
                      <div class="flex">
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                @click="showDeleteContactLeadModal = false"
                                type="button">Cerrar
                        </button>
                        <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                @click="deleteContactLead()">Aceptar
                        </button>
                      </div>
                    </ModalBox>

                  </div>

                </template>
                <template v-else>
                  <p class="mt-32 text-center">Selecciona una cita</p>
                </template>
              </template>
            </main>
          </template>
          <!--crear lead-->
          <ModalBox :showing="showCreateModal" @close="showCreateModal = false">
            <div class="justify-start p-4">
              <template v-if="isLoadingCreateAppointment">
                <p class="text-center">Cargando...</p>
              </template>
              <template v-else>
                <h6 class="text-left my-1 font-semibold ">CREAR CITA</h6>
                <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                <select v-model="newAppointment.connection"
                        class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsCities">
                  <option disabled :value="null">-- Seleccione la conexión --</option>
                  <option v-for="(connection) in appointmentConnections"
                          v-bind:key="connection.id"
                          :value="connection">
                    {{ connection.name }}
                  </option>
                </select>
                <template v-if="newAppointment.connection">
                  <div class="flex flex-col md:flex-row">
                    <div class="flex-col w-full px-1">
                      <div class="p-2"><b>Nombre:</b></div>
                      <!--eslint-disable-next-line-->
                      <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.name" type="text">
                    </div>
                  </div>
                  <div class="flex flex-col md:flex-row">
                    <div class="flex-col w-full md:w-1/2 px-1">
                      <div class="p-2"><b>Teléfono:</b></div>
                      <!--eslint-disable-next-line-->
                      <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.phone" type="tel">
                    </div>
                    <div class="flex-col w-full md:w-1/2 px-1">
                      <div class="p-2"><b>Email:</b></div>
                      <!--eslint-disable-next-line-->
                      <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.email" type="text">
                    </div>
                  </div>
                  <div class="flex">
                    <input type="radio" id="cedula" value="cedula" class="m-3"
                           v-model="newAppointment.type">
                    <!--eslint-disable-next-line-->
                    <label for="cedula" class="my-4">Cédula</label>
                    <input type="radio" id="ruc" name="ruc" value="ruc" class="m-3"
                           v-model="newAppointment.type">
                    <!--eslint-disable-next-line-->
                    <label for="ruc" class="my-4">RUC</label>
                    <input type="radio" id="passport" name="passport" value="passport"
                           class="m-3" v-model="newAppointment.type">
                    <!--eslint-disable-next-line-->
                    <label for="passport" class="my-4 mr-4">Pasaporte</label>
                    <input
                      id="document" name="identificacion"
                      placeholder="Número de identificación" type="text"
                      v-model="newAppointment.document"
                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                  </div>
                  <div class="flex-col">
                    <p class="my-1"><b>Número de Ficha:</b></p>
                    <!--eslint-disable-next-line-->
                    <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.number" type="text">
                  </div>
                  <div class="flex-col" v-if="newAppointment.cities.length > 0">
                    <p class="my-1"><b>Ciudad:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="newAppointment.city"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsMedicalCenters">
                      <option disabled :value="null">-- Seleccione la ciudad --</option>
                      <option v-for="(city) in newAppointment.cities" v-bind:key="city.id"
                              :value="city">
                        {{ city.name }}
                      </option>
                    </select>
                  </div>
                  <div class="flex-col"
                       v-if="newAppointment.sectors.length > 0 && newAppointment.city">
                    <p class="my-1"><b>Sector:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="newAppointment.sector"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSectorMedicalCenters">
                      <option disabled :value="null">-- Seleccione el sector --</option>
                      <option v-for="(sector, sk) in newAppointment.sectors"
                              v-bind:key="sk" :value="sector.sector">
                        {{ sector.sector }}
                      </option>
                    </select>
                  </div>
                  <div class="flex-col"
                       v-if="newAppointment.medicalCenters.length > 0 && newAppointment.city">
                    <p class="my-1"><b>Centro:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="newAppointment.medicalCenter"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsDoctorsAndSpecialties">
                      <option disabled :value="null">-- Seleccione el local --</option>
                      <option v-for="(medicalCenter) in newAppointment.medicalCenters"
                              v-bind:key="medicalCenter.id"
                              :value="medicalCenter">
                        {{ medicalCenter.name }}
                      </option>
                    </select>
                  </div>
                  <div class="flex-col"
                       v-if="newAppointment.doctors.length > 0 && newAppointment.city
                             && newAppointment.medicalCenter">
                    <p class="my-1"><b>Tratamientos:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="newAppointment.doctor"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSpecialtiesByDoctor">
                      <option disabled :value="null">-- Seleccione el local --</option>
                      <option v-for="(doctor) in newAppointment.doctors"
                              v-bind:key="doctor.id"
                              :value="doctor">
                        {{ doctor.service }} - {{ doctor.name }}
                      </option>
                    </select>
                  </div>
                  <div class="flex-col"
                       v-if="newAppointment.specialties.length > 0 && newAppointment.city
                             && newAppointment.medicalCenter">
                    <p class="my-1"><b>Zonas:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="newAppointment.specialty"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSchedules">
                      <option disabled :value="null">-- Seleccione el local --</option>
                      <option v-for="(specialty) in newAppointment.specialties"
                              v-bind:key="specialty.id"
                              :value="specialty">
                        {{ specialty.name }}
                      </option>
                    </select>
                  </div>
                  <div class="flex-col"
                       v-if="newAppointment.medicalCenter && newAppointment.city">
                    <p class="my-1"><b>Fecha:</b></p>
                    <!--eslint-disable-next-line-->
                    <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.date" type="date"
                           @change="getAppointmentsSchedules">
                  </div>
                  <div class="flex-col"
                       v-if="newAppointment.medicalCenter && newAppointment.city
                             && newAppointment.date && newAppointment.schedules.length > 0">
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <p class="my-1"><b>Horario:</b></p>
                    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                    <select v-model="newAppointment.schedule"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                      <option disabled :value="null">-- Seleccione el horario --</option>
                      <option v-for="(schedule) in newAppointment.schedules"
                              v-bind:key="schedule.id"
                              :value="schedule">
                        {{ schedule.startLabel }} - {{ schedule.endLabel }}
                      </option>
                    </select>
                  </div>
                  <div class="w-full flex">
                    <div class="m-1">
                      <Toggle v-model="newAppointment.addProduct"/>
                    </div>
                    <p class="ml-1 text-md">Agregar producto/s?</p>
                  </div>
                  <template v-if="newAppointment.addProduct">
                    <div class="my-1">
                      <div class="p-4 bg-gray-100">
                        <div v-for="category in categoriesCopy" :key="category.id" class="mb-4">
                          <!-- eslint-disable -->
                          <div @click="toggleCategory(category.slug)"
                               class="bg-teal-600 text-white p-4 rounded-t-lg cursor-pointer flex
                             justify-between items-center">
                            <h2 class="text-sm font-bold">{{ category.name }}</h2>
                            <!-- Chevron para mostrar si está abierto o cerrado -->
                            <svg v-if="category.open" xmlns="http://www.w3.org/2000/svg"
                                 class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                                 stroke="currentColor"
                                 stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M5 15l7-7 7 7"/>
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6"
                                 fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                              <path stroke-linecap="round" stroke-linejoin="round"
                                    d="M19 9l-7 7-7-7"/>
                            </svg>
                          </div>
                          <!-- Contenido del collapsible -->
                          <div v-show="category.open"
                               class="p-4 grid grid-cols-1 md:grid-cols-2 gap-4 bg-white rounded-b-lg
                             border border-gray-300">
                            <!-- Productos -->
                            <div v-for="product in category.products" :key="product.id"
                                 class="p-4 bg-white rounded-lg shadow-md border border-gray-300">
                              <div class="flex justify-between items-center mb-4">
                                <!-- Componente de Toggle -->
                                <Toggle v-model="product.selected"/>
                                <p class="text-gray-800 font-bold">{{ product.name }}</p>
                              </div>

                              <!-- Precio o Precio con Descuento -->
                              <div class="flex items-center justify-between mb-4">
                                <p class="text-gray-800 font-medium">
                                  Precio Normal: $ {{ product.price }}
                                </p>
                                <template
                                  v-if="product.discount_price && product.discount_price !== product.price">
                                  <Toggle v-model="product.use_discount"
                                          @change="updatePrice(product)"/>
                                  <p class="text-gray-800 font-medium">
                                    Precio con Descuento: $ {{ product.discount_price }}
                                  </p>
                                </template>
                              </div>

                              <!-- Mostrar Precio Final Seleccionado -->
                              <div class="mb-4">
                                <!-- eslint-disable -->
                                <label class="text-sm text-gray-600">Precio Final</label>
                                <input v-model="product.final_price" type="text" readonly
                                       class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                              </div>

                              <!-- Cantidad -->
                              <div class="flex justify-between mb-4">
                                <div class="w-1/2 pr-2">
                                  <!-- eslint-disable -->
                                  <label class="text-sm text-gray-600">Cantidad</label>
                                  <input v-model="product.quantity" type="number" min="1"
                                         placeholder="1" @change="updatePrice(product)"
                                         class="appearance-none rounded-none block w-full px-3 py-2
                                       border border-gray-300 bg-gray-50 text-gray-700"/>
                                </div>
                                <div class="w-1/2 pr-2">
                                  <!-- eslint-disable -->
                                  <label class="text-sm text-gray-600">No. de Citas</label>
                                  <input v-model="product.number_dates" type="number" min="1"
                                         placeholder="1"
                                         class="appearance-none rounded-none block w-full px-3 py-2
                                     border border-gray-300 bg-gray-50 text-gray-700"/>
                                  {{ product.number_dates }}
                                </div>
                              </div>

                              <!-- Nota -->
                              <div class="mb-4">
                                <!-- eslint-disable -->
                                <label class="text-sm text-gray-600">Nota</label>
                                <textarea v-model="product.note" placeholder="Agregar nota"
                                          class="appearance-none rounded-none block w-full px-3 py-2
                                        border border-gray-300 bg-gray-50 text-gray-700">
                              </textarea>
                              </div>

                              <!-- Total -->
                              <div class="text-right text-gray-800 font-bold">
                                <p>Total: $ {{ product.quantity * product.final_price }}</p>
                              </div>
                              <!-- Total -->
                              <div class="text-right text-gray-800 font-bold">
                                <p>Total Sesiones: {{
                                    product.quantity * product.number_dates
                                  }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="w-full flex text-lg justify-center">
                        <p><b>TOTAL: </b>
                          $ {{ total_products }}</p>
                      </div>
                      <div class="w-full flex text-lg justify-center">
                        <p><b>TOTAL SESIONES: </b> {{ total_dates }}</p>
                      </div>

                    </div>
                  </template>
                  <div class="w-full flex">
                    <div class="m-1">
                      <Toggle v-model="newAppointment.addUser"/>
                    </div>
                    <p class="ml-1">Asignar usuario?</p>
                  </div>
                  <template v-if="newAppointment.addUser">
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="leadUser">
                      <b>Usuario:</b>
                    </label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="datesUsersList.length < 1"
                            id="leadUser" v-model="newAppointment.user" name="leadUser">
                      <option :value="null" class="px-3 py-2 text-sm">
                        -- Selecciona un asesor --
                      </option>
                      <option v-for="(usr) in datesUsersList"
                              v-bind:key="usr.id" :value="usr"
                              class="px-3 py-2 text-sm">
                        {{ usr.name }}
                      </option>
                    </select>
                  </template>
                </template>
              </template>

            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showCreateModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      v-if="newAppointment.schedule"
                      @click="createAppointment()">Crear
              </button>
            </div>
          </ModalBox>
        </template>

      </div>
    </template>
  </div>
</template>
<script>
import { ref } from 'vue';
import LitepieDatepicker from 'litepie-datepicker';
import Toggle from '@vueform/toggle';
import {
  UserIcon, ArchiveIcon, CollectionIcon, SearchIcon, XIcon, TrashIcon, UsersIcon,
  PencilAltIcon, RefreshIcon, LockClosedIcon, CalendarIcon, CashIcon, ChevronDoubleRightIcon,
  MailIcon, PhoneOutgoingIcon, NewspaperIcon, PhoneIcon, ChatIcon, MenuIcon, ExternalLinkIcon,
  ColorSwatchIcon, PlusIcon,
} from '@heroicons/vue/outline';
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';
import {
  getToken, onMessage,
} from 'firebase/messaging';
import DropDown from '@/components/DropDown.vue';
import DateOptionsDropDown from '@/components/DateOptionsDropDown.vue';
import AppointmentStatusModal from '@/components/AppointmentStatusModal.vue';
import ModalBox from '@/components/ModalBox.vue';
import LoadingBox from '@/components/LoadingBox.vue';
import AsesorItem from '@/components/chat/AsesorItem.vue';
import StatusItem from '@/components/leads/StatusItem.vue';
import CompanyDropDown from '@/components/CompanyDropDown.vue';
import FormDropDown from '@/components/FormDropDown.vue';
import MedicalCenterDropDown from '@/components/MedicalCenterDropDown.vue';
import DoctorDropDown from '@/components/DoctorDropDown.vue';
import SpecialtyDropDown from '@/components/SpecialtyDropDown.vue';
import AreaDropDown from '@/components/AreaDropDown.vue';
import axios from '@/http/axios/index';
import MainDropDown from '@/components/MainDropDown.vue';
import ChannelsDropDown from '@/components/ChannelsDropDown.vue';
import DateItem from '@/components/dates/DateItem.vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
// Stylesheet
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css';
import 'flatpickr/dist/flatpickr.css';

flatpickr.localize(Spanish);

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export default {
  name: 'LeadboxDates',
  displayName: 'LeadboxDates',
  components: {
    // // eslint-disable-next-line
    // dayGridPlugin,
    // // eslint-disable-next-line
    // timeGridPlugin,
    // // eslint-disable-next-line
    // interactionPlugin,
    LoadingBox,
    AppointmentStatusModal,
    MainDropDown,
    CompanyDropDown,
    AsesorItem,
    StatusItem,
    DropDown,
    FullCalendar,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    DateOptionsDropDown,
    ModalBox,
    DateItem,
    CalendarIcon,
    CashIcon,
    PlusIcon,
    ChevronDoubleRightIcon,
    ExternalLinkIcon,
    ColorSwatchIcon,
    ArchiveIcon,
    CollectionIcon,
    UserIcon,
    SearchIcon,
    XIcon,
    TrashIcon,
    NewspaperIcon,
    UsersIcon,
    PencilAltIcon,
    MenuIcon,
    RefreshIcon,
    LockClosedIcon,
    MailIcon,
    PhoneOutgoingIcon,
    LitepieDatepicker,
    // eslint-disable-next-line vue/no-unused-components
    FormDropDown,
    MedicalCenterDropDown,
    DoctorDropDown,
    SpecialtyDropDown,
    AreaDropDown,
    ChatIcon,
    PhoneIcon,
    ChannelsDropDown,
    Toggle,
  },
  data() {
    return {
      fullCalendar: null,
      calendarInput: null,
      doctor_editing: false,
      patient_email_editing: false,
      patient_phone_editing: false,
      patient_document_editing: false,
      medical_center_editing: false,
      external_id_editing: false,
      number_editing: false,
      zones_editing: false,
      total_sessions_editing: false,
      used_sessions_editing: false,
      pending_sessions_editing: false,
      showCalendar: false,
      files: [],
      showFieldInterface: false,
      showCreateFieldInterface: false,
      showAssignModal: false,
      showEventDetailModal: false,
      showAssignOneModal: false,
      isLoadingAssignAuto: false,
      isLoadingAssign: false,
      selectAssignUsers: false,
      selectedEvent: null,
      assignUsersList: [],
      newField: {
        label: null,
        data: null,
      },
      createNewField: {
        label: null,
        data: null,
        name: null,
      },
      assignUserModal: null,
      translates: {
        response: 'Respuesta',
        is_success: 'Estado',
        id: 'Id',
        user_id: 'Id de usuario',
        user_name: 'Nombre de usuario',
        date: 'Fecha',
        status: 'Estado respuesta',
        payload: 'Datos enviados',
      },
      newLead: {
        form: null,
        fields: [],
        addProduct: false,
        category: null,
        product: null,
        addUser: false,
        user: null,
        name: null,
        email: null,
        phone: null,
      },
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin,
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'timeGridWeek,timeGridDay',
        },
        initialView: 'timeGridDay',
        events: [], // o bien, usa initialEvents si tienes eventos ya cargados
        slotMinTime: '08:00:00',
        slotMaxTime: '22:00:00',
        allDaySlot: false,
        nowIndicator: true,
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        locale: esLocale,
        // Evento para seleccionar fecha
        select: (info) => {
          console.log('Fecha seleccionada:', info);
          // this.showCreateModal = true;
        },
        // Evento para hacer click en un evento
        eventClick: (info) => {
          // console.log('Evento:', info.event.extendedProps);
          this.selectedEvent = info.event.extendedProps;
          this.showEventDetailModal = true;
        },
        datesSet: (dateInfo) => {
          // console.log('dateInfo:', dateInfo);
          // console.log('Fecha inicial visible:', dateInfo.start);
          // console.log('Fecha final visible:', dateInfo.end);

          // Compara las fechas utilizando getTime() para comparar el valor de tiempo
          const startChanged = !this.startCalendarDate
            || dateInfo.start.getTime() !== this.startCalendarDate.getTime();
          const endChanged = !this.endCalendarDate
            || dateInfo.end.getTime() !== this.endCalendarDate.getTime();

          // console.log('startChanged:', startChanged);
          // console.log('endChanged:', endChanged);

          if (startChanged || endChanged) {
            // Actualiza las fechas almacenadas
            this.startCalendarDate = new Date(dateInfo.start);
            this.endCalendarDate = new Date(dateInfo.end);

            // Llama a la función para obtener los datos (solicitar eventos)
            this.getDates();
          }
        },
      },
      startCalendarDate: null,
      payload: null,
      categoriesCopy: [],
      probability: null,
      channel: null,
      location: null,
      documentReceptor: null,
      selectedDocument: null,
      selectedAppointmentProduct: null,
      selectedContactLead: null,
      isShow: true,
      showModal: false,
      modalStatus: null,
      selected: '',
      isOpen: false,
      type: 'active',
      selectedTab: 'info',
      selectedStatusType: 'active',
      isLoadingDates: false,
      isLoadingDatesUsers: false,
      isLoadingDate: false,
      isLoadingMore: false,
      isLoadingCompanies: false,
      isLoadingStatuses: false,
      isLoadingAreas: false,
      isLoadingLeadEvents: false,
      isLoadingLeadDocuments: false,
      isLoadingLeadConversations: false,
      isLoadingAppointmentConversations: false,
      isLoadingAppointmentLeads: false,
      isLoadingLeadLeads: false,
      isLoadingAppointmentHistory: false,
      isLoadingLeadChat: false,
      isLoadingCreateLead: false,
      isLoadingConversationNotes: false,
      showNoteInterface: false,
      showDeleteModal: false,
      showArchiveModal: false,
      showCreateModal: false,
      showNewAppointmentModal: false,
      showReScheduleModal: false,
      showEmailModal: false,
      showDocumentModal: false,
      showProductsModal: false,
      showDeleteFileModal: false,
      showDeleteProductModal: false,
      showDeleteContactLeadModal: false,
      isLoadingLeadProducts: false,
      isOpenA: false,
      note: '',
      lastFetchedRange: null,
      saving: false,
      selectedDates: [],
      text: '',
      order: 'DESC',
      filter: 'all',
      allSelected: false,
      search: null,
      editName: false,
      editStartTime: false,
      editEndTime: false,
      editTotalSessions: false,
      editUsedSessions: false,
      selectedUser: 0,
      selectedStatus: null,
      dateValue: ref({
        startDate: null,
        endDate: null,
      }),
      formatter: ref({
        date: 'DD-MM-YYYY',
        month: 'MMM',
      }),
      options: ref({
        shortcuts: {
          today: 'Hoy',
          yesterday: 'Ayer',
          past: (period) => `últimos ${period} días`,
          currentMonth: 'este mes',
          pastMonth: 'Mes pasado',
        },
        footer: {
          apply: 'Aplicar',
          cancel: 'Cancelar',
        },
      }),
      cssProps: {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/bg-chat.jpg')})`,
      },
      cssProps2: {
        // eslint-disable-next-line global-require
        background: 'white',
      },
      props: {
        msg: String,
      },
      isLoadingCreateAppointment: false,
      isLoadingAppointmentConnections: false,
      isLoadingAppointmentAppointments: false,
      selectedDatesTab: 'list-appointments',
      reschedule: {
        connection_id: null,
        medical_center_id: null,
        doctor_id: null,
        procedure_id: null,
        date: null,
        schedules: [],
        schedule: null,
      },
      newAppointment: {
        sell_date: null,
        connection: null,
        addProduct: true,
        category: null,
        product: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        type: 'cedula',
        document: null,
        cities: [],
        city: null,
        sectors: [],
        sector: null,
        medicalCenters: [],
        medicalCenter: null,
        specialties: [],
        specialty: null,
        date: null,
        schedules: [],
        schedule: null,
        doctors: [],
        doctor: null,
      },
    };
  },
  mounted() {
    // Inicializamos Flatpickr
    this.$nextTick(() => {
    });
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.getActualToken();
      if (this.companies.length > 0) {
        if (this.activeDate) {
          // this.getLeadEvents();
          this.getAppointmentHistory();
          this.getNotes();
          this.getAppointmentConversations();
          this.getAppointmentLeads();
          this.getAppointmentAppointments();
          // this.getLeadDocuments();
          this.getAppointmentProducts();
        }
        if (this.activeCompany) {
          this.setActiveCompany();
        }
      } else {
        this.getCompanies();
      }
    } else {
      this.$router.push('').catch(() => {
      });
    }
    this.initializeFlatpickr();
  },
  watch: {
    dateValue(val) {
      console.log('val', val);
      this.getDates();
      this.getDatesUsers();
      this.getStatuses();
    },
    showCalendar(val) {
      if (val) {
        this.calendarOptions.events = this.calendarEvents;
        this.initializeFlatpickr();
      } else {
        this.lastFetchedRange = null;
        this.getDates();
        this.getDatesUsers();
        this.getStatuses();
      }
    },
  },
  methods: {
    formatDateToDDMMYYYY(date) {
      const day = String(date.getDate()).padStart(2, '0'); // Obtiene el día y agrega un 0 si es necesario
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtiene el mes (agrega 1 porque getMonth() devuelve un índice 0)
      const year = date.getFullYear(); // Obtiene el año completo

      return `${day}-${month}-${year}`; // Retorna en formato dd-mm-yyyy
    },
    showDeleteAppointmentProductModal(appointmentProduct) {
      // Compara si el rango actual es el mismo que el último solicitado
      console.log('group', appointmentProduct);
      this.selectedAppointmentProduct = appointmentProduct;
      this.showDeleteProductModal = true;
    },
    isSameRange(start, end) {
      // Compara si el rango actual es el mismo que el último solicitado
      return this.lastFetchedRange
        && this.lastFetchedRange.start === start
        && this.lastFetchedRange.end === end;
    },
    closeEventDetailModal() {
      const { uuid } = this.selectedEvent;
      this.showEventDetailModal = false;
      this.showCalendar = false;
      this.getDate(uuid);
    },
    initializeFlatpickr() {
      try {
        const calendarElement = this.$refs.calendarInput;
        console.log('calendarElement', calendarElement);
        if (calendarElement) {
          flatpickr(calendarElement, {
            inline: true,
            dateFormat: 'd/m/Y',
            locale: 'es',
            onChange: (selectedDates) => {
              console.log('selectedDates', selectedDates);
              if (selectedDates.length) {
                const calendarApi = this.$refs.fullCalendar.getApi();
                calendarApi.gotoDate(selectedDates[0]);
              }
            },
          });
        } else {
          console.error('calendarInput ref no está definido');
        }
      } catch (error) {
        console.error('Error inicializando flatpickr:', error);
      }
    },
    handleDatesSet(dateInfo) {
      console.log('Fecha inicial visible:', dateInfo.startStr);
      console.log('Fecha final visible:', dateInfo.endStr);
    },
    handleDateChange(selectedDates) {
      if (selectedDates.length) {
        const calendarApi = this.$refs.fullCalendar.getApi();
        calendarApi.gotoDate(selectedDates[0]);
      }
    },
    toggleCategory(categoryId) {
      // Toggle para abrir/cerrar cada categoría
      // eslint-disable-next-line max-len
      this.categoriesCopy = this.categoriesCopy.map((category) => (category.slug === categoryId ? {
        ...category,
        open: !category.open,
      } : category));
    },
    toggleAppointment(categoryId) {
      // Toggle para abrir/cerrar cada categoría
      // eslint-disable-next-line max-len
      this.appointmentAppointments[categoryId].open = !this.appointmentAppointments[categoryId].open;
    },
    updatePrice(product) {
      // Cambia el precio final entre el normal y el con descuento
      // eslint-disable-next-line
      product.final_price = product.use_discount ? product.discount_price : product.price;
      console.log('changed', product.use_discount);
      console.log(' product.final_price', product.final_price);
    },
    openReScheduleModal() {
      this.showReScheduleModal = true;
      const connection = this.appointmentConnections.find(
        (item) => item.id === this.activeDate?.connection_id,
      );
      if (connection) {
        this.reschedule.connection_id = connection.uuid;
      }
      this.reschedule.medical_center_id = this.activeDate?.medical_center_id;
      this.reschedule.doctor_id = this.activeDate?.doctor_id;
      this.reschedule.date = null;
      this.reschedule.schedule = null;
      this.reschedule.schedules = [];
      console.log('this.reschedule', this.reschedule);
    },
    openCreateModal() {
      this.showCreateModal = true;
      this.newAppointment = {
        sell_date: null,
        connection: null,
        addProduct: true,
        category: null,
        product: null,
        name: null,
        number: null,
        email: null,
        phone: null,
        type: 'cedula',
        document: null,
        cities: [],
        city: null,
        sectors: [],
        sector: null,
        medicalCenters: [],
        medicalCenter: null,
        specialties: [],
        specialty: null,
        date: null,
        schedules: [],
        schedule: null,
        doctors: [],
        doctor: null,
      };
      this.categoriesCopy = this.companyCategories.map((category) => {
        // eslint-disable-next-line no-param-reassign
        category.open = false;
        category.products.map((product) => {
          // eslint-disable-next-line no-param-reassign
          product.selected = false;
          // eslint-disable-next-line no-param-reassign
          product.use_discount = false;
          // eslint-disable-next-line no-param-reassign
          product.quantity = 1;
          // eslint-disable-next-line no-param-reassign
          product.note = null;
          // eslint-disable-next-line no-param-reassign
          product.final_price = product.price;
          // eslint-disable-next-line no-param-reassign
          product.used_sessions = 0;
          return product;
        });
        return category;
      });
      if (this.appointmentConnections.length === 1) {
        const [firstConnection] = this.appointmentConnections;
        this.newAppointment.connection = firstConnection;
        this.getAppointmentsCities();
      }
    },
    openProductsModal() {
      this.showProductsModal = true;
      this.categoriesCopy = this.companyCategories.map((category) => {
        // eslint-disable-next-line no-param-reassign
        category.open = false;
        category.products.map((product) => {
          // eslint-disable-next-line no-param-reassign
          product.selected = false;
          // eslint-disable-next-line no-param-reassign
          product.use_discount = false;
          // eslint-disable-next-line no-param-reassign
          product.quantity = 1;
          // eslint-disable-next-line no-param-reassign
          product.note = null;
          // eslint-disable-next-line no-param-reassign
          product.final_price = product.price;
          // eslint-disable-next-line no-param-reassign
          product.used_sessions = 0;
          return product;
        });
        return category;
      });
    },
    transformEventData(data) {
      return data.map((event) => ({
        id: event.id,
        uuid: event.uuid,
        title: `${event.patient_name || 'Sin nombre'} - ${event.doctor_service || ''} ${event.doctor_name || 'Sin doctor'}`,
        start: `${event.date}T${event.start_time}`,
        end: `${event.date}T${event.end_time}`,
        description: event.observations,
        color: event.medical_center.color,
        centerbackgroundColor: event.medical_center.color,
        borderColor: '#c70039',
        textColor: '#ffffff',
        className: event.cancelled ? 'event-cancelled' : 'event-confirmed',
      }));
    },
    removeFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) this.files.splice(index, 1);
    },
    openFilePicker() {
      const element = this.$refs.fileInput;
      element.click();
    },
    onInputChange(e) {
      this.addFiles(e.target.files);
      e.target.value = null;
      // reset so that selecting the same file again will still cause it to fire this change
    },
    addFiles(newFiles) {
      const newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id));
      this.files = this.files.concat(newUploadableFiles);
    },
    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId);
    },
    sendFiles() {
      console.log('files', this.files);
      try {
        this.files.forEach((file) => {
          console.log('file', file);
          this.uploadFile(file);
        });

        this.files = [];
        this.showDocumentModal = false;
        setTimeout(() => {
          this.getLeadDocuments();
        }, 3000);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'hubo un error',
        });
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.set('type', file.file.type);
      formData.set('name', file.file.name);
      formData.append('file', file.file);
      this.isLoading = true;
      console.log('formData', formData);
      this.axios.post(
        this.filesUrl,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data files', data);
        this.$notify({
          type: 'success',
          title: 'documento almacenado',
          text: `Se almacenó el archivo ${file.file.name}`,
        });
      })
        .catch((e) => {
          console.log('error', e);
        });
    },
    addHashToLocation(params) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        null,
        params,
      );
    },
    setFields() {
      this.newLead.fields = [];
      console.log('this.newLead.form.fields', this.newLead.form.fields);
      this.newLead.form.fields.forEach((field) => {
        if (field.key !== 'nombre' && field.key !== 'email' && field.key !== 'name'
          && field.key !== 'phone' && field.key !== 'mail' && field.key !== 'telefono') {
          this.newLead.fields.push({
            key: field.key,
            name: field.name,
            type: field.type,
            options: field.options,
            data: null,
          });
        }
      });
      console.log('this.newLead.fields', this.newLead.fields);
    },
    openEmailModal(document) {
      console.log('selected, document', document);
      this.selectedDocument = document;
      this.documentReceptor = null;
      this.showEmailModal = true;
    },
    openDeleteFileModal(document) {
      console.log('selected, document', document);
      this.selectedDocument = document;
      this.showDeleteFileModal = true;
    },
    openDeleteContactLeadModal(lead) {
      this.selectedContactLead = lead;
      this.showDeleteContactLeadModal = true;
    },
    getPayment(text) {
      switch (text) {
        case 'transfer':
          return 'Transferencia Bancaria';
        case 'cash':
          return 'Efectivo';
        case 'credit_card':
          return 'Tarjeta de crédito';
        default:
          return 'Pago';
      }
    },
    deleteDates() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedDates,
      };
      this.$store.dispatch('deleteDates', payload)
        .then((response) => {
          console.log('respuesta deleteDates', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'citas eliminados',
            text: success,
          });
          this.showDeleteModal = false;
          this.selectedDates = [];
          this.getDates();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getDatesUsers();
        }, (error) => {
          console.log('error deleteDates', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteDates', error);
        });
    },
    deleteContactLead() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: [this.selectedContactLead.uuid],
      };
      this.$store.dispatch('deleteLeads', payload)
        .then((response) => {
          console.log('respuesta deleteLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: `lead ${this.selectedContactLead.name} eliminado`,
            text: success,
          });
          this.showDeleteContactLeadModal = false;
          this.getDate(this.activeDate.uuid);
          this.getDates();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getDatesUsers();
          this.selectedContactLead = null;
        }, (error) => {
          console.log('error deleteLead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteLeads', error);
        });
    },
    archiveDates() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedDates,
      };
      this.$store.dispatch('archiveLeads', payload)
        .then((response) => {
          console.log('respuesta archiveLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'leads archivados',
            text: success,
          });
          this.showArchiveModal = false;
          this.selectedDates = [];
          this.getDates();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getDatesUsers();
        }, (error) => {
          console.log('error archiveLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error archiveLeads', error);
        });
    },
    createLead() {
      const payload = {
        company: this.activeCompany.uuid,
        form: this.newLead.form.uuid,
        name: this.newLead.name,
        email: this.newLead.email,
        phone: this.newLead.phone,
        addProduct: this.newLead.addProduct,
        product: this.newLead.product?.uuid,
        addUser: this.newLead.addUser,
        user: this.newLead.user?.id,
      };
      const fields = {};
      this.newLead.fields.forEach((field) => {
        fields[field.name] = field.data;
      });
      payload.fields = fields;
      this.isLoadingCreateLead = true;
      this.$store.dispatch('createLead', payload)
        .then(
          (response) => {
            this.isLoadingCreateLead = false;
            console.log('response createLead', response);
            this.$notify({
              type: 'success',
              title: 'Lead creado',
              text: response.data.message,
            });
            this.getDates();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getDatesUsers();
            this.newLead = {
              form: null,
              fields: [],
              addProduct: false,
              category: null,
              product: null,
              addUser: false,
              user: null,
              name: null,
              email: null,
              phone: null,
            };
            this.showCreateModal = false;
          },
          (error) => {
            this.isLoadingCreateLead = false;
            console.log('error createLead', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateLead = false;
          console.log('error createLead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    assignDates() {
      const usersList = [];
      if (this.selectAssignUsers) {
        this.assignUsersList.forEach((user) => {
          if (user.selected) {
            usersList.push(user.id);
          }
        });
      }
      const payload = {
        company: this.activeCompany.uuid,
        selectUsers: this.selectAssignUsers,
        usersList,
      };
      console.log('payload', payload);
      this.isLoadingAssignAuto = true;
      this.$store.dispatch('assignAuto', payload)
        .then(
          (response) => {
            this.isLoadingAssignAuto = false;
            console.log('response assignAuto', response);
            this.$notify({
              type: 'success',
              title: 'Leads asignados',
              text: response.data.success,
            });
            this.getDates();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getDatesUsers();
            this.showAssignModal = false;
          },
          (error) => {
            this.isLoadingAssignAuto = false;
            console.log('error assignAuto', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignAuto', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    assignOneDates() {
      const payload = {
        company: this.activeCompany.uuid,
        user: this.assignUserModal.uuid,
        ids: this.selectedDates,
      };
      this.isLoadingAssign = true;
      this.$store.dispatch('assignOne', payload)
        .then(
          (response) => {
            this.isLoadingAssign = false;
            console.log('response assignOne', response);
            this.$notify({
              type: 'success',
              title: `Leads asignados a ${this.assignUserModal.name}`,
              text: response.data.success,
            });
            this.getDates();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getDatesUsers();
            this.isLoadingAssign = false;
            this.showAssignOneModal = false;
            this.selectedDates = [];
            this.assignUserModal = null;
          },
          (error) => {
            this.isLoadingAssign = false;
            console.log('error assignOne', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignOne', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    getProducts() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('products', payload)
        .then((response) => {
          console.log('respuesta products', response);
        }, (error) => {
          console.log('error products', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error products', error);
        });
    },
    getProductSections() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productSections', payload)
        .then((response) => {
          console.log('respuesta productSections', response);
        }, (error) => {
          console.log('error productSections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productSections', error);
        });
    },
    getProductCategories() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productCategories', payload)
        .then((response) => {
          console.log('respuesta productCategories', response);
          this.categoriesCopy = this.companyCategories.map((category) => {
            // eslint-disable-next-line no-param-reassign
            category.open = false;
            category.products.map((product) => {
              // eslint-disable-next-line no-param-reassign
              product.selected = false;
              // eslint-disable-next-line no-param-reassign
              product.use_discount = false;
              // eslint-disable-next-line no-param-reassign
              product.quantity = 1;
              // eslint-disable-next-line no-param-reassign
              product.note = null;
              // eslint-disable-next-line no-param-reassign
              product.final_price = product.price;
              // eslint-disable-next-line no-param-reassign
              product.used_sessions = 0;
              return product;
            });
            return category;
          });
        }, (error) => {
          console.log('error productCategories', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productCategories', error);
        });
    },
    getMedicalCenters() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('medicalCenters', payload)
        .then((response) => {
          console.log('respuesta medicalCenters', response);
        }, (error) => {
          console.log('error medicalCenters', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error medicalCenters', error);
        });
    },
    getDoctors() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('doctors', payload)
        .then((response) => {
          console.log('respuesta doctors', response);
        }, (error) => {
          console.log('error doctors', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error doctors', error);
        });
    },
    getSpecialties() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('specialties', payload)
        .then((response) => {
          console.log('respuesta specialties', response);
        }, (error) => {
          console.log('error specialties', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error specialties', error);
        });
    },
    save() {
      if (this.saving) return;
      console.log('guarda', this.activeDate);
      const payload = {
        lead: this.activeDate,
        company: this.activeCompany.uuid,
      };
      this.saving = true;
      this.$store.dispatch('updateAppointment', payload)
        .then(
          (response) => {
            this.saving = false;
            console.log('response message', response);
            this.$notify({
              type: 'success',
              title: 'conversación actualizada',
              text: response.data.message,
            });

            this.getDate(this.activeDate.uuid);
            // this.getLeadDocuments();
            // this.getDates();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getChannels();
            this.getDatesUsers();
            this.getAppointmentProducts();
          },
          (error) => {
            this.saving = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.saving = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    goToConversation(uuid) {
      this.$router.push(`/chat?conversation=${uuid}`).catch(() => {
      });
    },
    goToLead(uuid) {
      this.$router.push(`/leads?lead=${uuid}`).catch(() => {
      });
    },
    getConversation(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      // this.isLoadingConversation = true;
      this.$store.dispatch('conversation', payload)
        .then((response) => {
          // this.isLoadingConversation = false;
          console.log('respuesta conversation', response);
          // this.getMessages();
          // this.getDates();
          // this.getSender(this.activeConversation.uuid);
          // this.getNotes();
          this.$router.push('/chat').catch(() => {
          });
        }, (error) => {
          // this.isLoadingConversation = false;
          console.log('error conversation', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          // this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversation', error);
        });
    },
    updateSender() {
      if (this.savingSender) return;
      console.log('guarda', this.activeSender);
      const payload = {
        conversation: this.activeConversation,
        company: this.activeCompany.uuid,
        sender: this.activeSender,
      };
      this.savingSender = true;
      this.$store.dispatch('updateSender', payload)
        .then(
          (response) => {
            this.savingSender = false;
            console.log('response sender', response);
            this.$notify({
              type: 'success',
              title: 'contacto actualizado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.savingSender = false;
            console.log('error sender', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.savingSender = false;
          console.log('error sender', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    setProbability(probability) {
      this.probability = probability;
      this.getDates();
      this.getDatesUsers();
      this.getStatuses();
    },
    setChannel(channel) {
      this.channel = channel;
      this.getDates();
      this.getDatesUsers();
      this.getStatuses();
    },
    setLocation(location) {
      this.location = location;
      this.getDates();
      this.getDatesUsers();
      this.getStatuses();
    },
    setStatusType(statusType) {
      this.selectedStatusType = statusType;
    },
    sendMessage(type) {
      if (this.isLoadingMessage) return;
      if (this.activeConversation.is_after) return;
      const payload = {
        body: this.text,
        type,
        id: this.activeConversation.uuid,
      };
      this.isLoadingMessage = true;
      this.$store.dispatch('sendMessage', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response message', response);
            this.text = null;
            this.$notify({
              type: 'success',
              title: 'Mensaje enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    setUser(user) {
      if (this.selectedUser === user.id) {
        this.selectedUser = 0;
      } else {
        this.selectedUser = user.id;
      }
      this.getDates();
      this.getStatuses();
    },
    selectStatus(status) {
      this.modalStatus = status;
      this.showModal = true;
    },
    setStatus(status) {
      if (this.selectedStatus) {
        if (this.selectedStatus === status.id) {
          this.selectedStatus = null;
        } else {
          this.selectedStatus = status.id;
        }
      } else {
        this.selectedStatus = status.id;
      }
      this.getDates();
      this.getDatesUsers();
    },
    selectTab(tab) {
      this.selectedTab = tab;
      if (tab === 'appointments') {
        this.categoriesCopy = this.companyCategories.map((category) => {
          // eslint-disable-next-line no-param-reassign
          category.open = false;
          category.products.map((product) => {
            // Buscar si el producto existe en appointmentProducts
            // Si no existe, poner valores por defecto
            // eslint-disable-next-line no-param-reassign
            product.selected = false;
            // eslint-disable-next-line no-param-reassign
            product.use_discount = false;
            // eslint-disable-next-line no-param-reassign
            product.quantity = 1;
            // eslint-disable-next-line no-param-reassign
            product.note = null;
            // eslint-disable-next-line no-param-reassign
            product.final_price = product.price;
            // eslint-disable-next-line no-param-reassign
            product.used_sessions = 0;
            return product;
          });
          return category;
        });
        this.newAppointment.name = this.activeDate.patient_name;
        this.newAppointment.phone = this.activeDate.patient_phone;
        this.newAppointment.email = this.activeDate.patient_email;
        this.newAppointment.document = this.activeDate.patient_document;
        this.newAppointment.number = this.activeDate.number;
        let connection = this.appointmentConnections.find(
          (item) => item.id === this.activeDate.connection_id,
        );
        if (connection) {
          this.newAppointment.connection = connection;
          this.getAppointmentsCities();
        }
        console.log('this.activeDate.medical_center_city_id', this.activeDate.medical_center_city_id);
        if (!connection) {
          console.log('no hay conexion');
          if (this.activeDate?.medical_center_city_id) {
            connection = this.appointmentConnections.find(
              (item) => item.city_id === this.activeDate.medical_center_city_id,
            );
            if (connection) {
              this.newAppointment.connection = connection;
              this.getAppointmentsCities();
            }
          }
        }
        if (!connection) {
          console.log('no hay conexion2');
          // eslint-disable-next-line prefer-destructuring
          connection = this.appointmentConnections[0];
          if (connection) {
            this.newAppointment.connection = connection;
            this.getAppointmentsCities();
          }
        }
      }
    },
    createAppointmentForm(appointment) {
      console.log('appointmentProductContact', appointment);
      try {
        this.showNewAppointmentModal = true;
        this.newAppointment.name = this.activeDate.patient_name;
        this.newAppointment.phone = this.activeDate.patient_phone;
        this.newAppointment.email = this.activeDate.patient_email;
        this.newAppointment.document = this.activeDate.patient_document;
        this.newAppointment.number = this.activeDate.number;
        console.log('this.newAppointment', this.newAppointment);
        this.categoriesCopy = this.companyCategories.map((category) => {
          // eslint-disable-next-line no-param-reassign
          category.open = false;
          category.products.map((product) => {
            // Buscar si el producto existe en appointmentProducts
            const appointmentProduct = this.appointmentProducts.find(
              (ap) => ap.product?.uuid === product.uuid,
            );

            if (appointmentProduct) {
              console.log('appointmentProduct', appointmentProduct);
              // Si existe, actualizar los valores de selected, quantity, y note
              // eslint-disable-next-line no-param-reassign
              product.use_discount = appointmentProduct.use_discount;
              // eslint-disable-next-line no-param-reassign
              product.selected = true;
              // eslint-disable-next-line no-param-reassign
              product.quantity = appointmentProduct.quantity;
              // eslint-disable-next-line no-param-reassign
              product.note = appointmentProduct.note;
              // eslint-disable-next-line no-param-reassign
              product.final_price = appointmentProduct.price;
              // eslint-disable-next-line no-param-reassign
              product.used_sessions = appointmentProduct.used_sessions;
            } else {
              // Si no existe, poner valores por defecto
              // eslint-disable-next-line no-param-reassign
              product.selected = false;
              // eslint-disable-next-line no-param-reassign
              product.use_discount = false;
              // eslint-disable-next-line no-param-reassign
              product.quantity = 1;
              // eslint-disable-next-line no-param-reassign
              product.note = null;
              // eslint-disable-next-line no-param-reassign
              product.final_price = product.price;
              // eslint-disable-next-line no-param-reassign
              product.used_sessions = 0;
            }
            return product;
          });
          return category;
        });
        console.log('this.categoriesCopy', this.categoriesCopy);
        console.log('this.appointmentConnections', this.appointmentConnections);
        let connection = this.appointmentConnections.find(
          (item) => item.id === this.activeDate.connection_id,
        );
        console.log('this.activeDate.connection_id', this.activeDate.connection_id);
        console.log('connection', connection);
        if (connection) {
          this.newAppointment.connection = connection;
          this.getAppointmentsCities();
        }
        console.log('this.activeDate.medical_center_city_id', this.activeDate.medical_center_city_id);
        if (!connection) {
          console.log('no hay conexion');
          if (this.activeDate?.medical_center_city_id) {
            connection = this.appointmentConnections.find(
              (item) => item.city_id === this.activeDate.medical_center_city_id,
            );
            if (connection) {
              this.newAppointment.connection = connection;
              this.getAppointmentsCities();
            }
          }
        }
        if (!connection) {
          console.log('no hay conexion2');
          // eslint-disable-next-line prefer-destructuring
          connection = this.appointmentConnections[0];
          if (connection) {
            this.newAppointment.connection = connection;
            this.getAppointmentsCities();
          }
        }
      } catch (e) {
        console.log('error', e);
      }
    },
    selectDatesTab(tab) {
      this.selectedDatesTab = tab;
      if (tab === 'create-appointment') {
        this.categoriesCopy = this.companyCategories.map((category) => {
          // eslint-disable-next-line no-param-reassign
          category.open = false;
          category.products.map((product) => {
            // Buscar si el producto existe en appointmentProducts
            // Si no existe, poner valores por defecto
            // eslint-disable-next-line no-param-reassign
            product.selected = false;
            // eslint-disable-next-line no-param-reassign
            product.use_discount = false;
            // eslint-disable-next-line no-param-reassign
            product.quantity = 1;
            // eslint-disable-next-line no-param-reassign
            product.note = null;
            // eslint-disable-next-line no-param-reassign
            product.final_price = product.price;
            // eslint-disable-next-line no-param-reassign
            product.used_sessions = 0;
            return product;
          });
          return category;
        });
        this.newAppointment.name = this.activeDate.patient_name;
        this.newAppointment.phone = this.activeDate.patient_phone;
        this.newAppointment.email = this.activeDate.patient_email;
        this.newAppointment.document = this.activeDate.patient_document;
        this.newAppointment.number = this.activeDate.number;
        const connection = this.appointmentConnections.find(
          (item) => item.id === this.activeDate.connection_id,
        );
        if (connection) {
          this.newAppointment.connection = connection;
          this.getAppointmentsCities();
        }
      }
    },
    changeOrder(direction) {
      this.order = direction;
      this.getDates();
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedDates = [];
      } else {
        this.selectedDates = this.dates.map((lead) => lead.uuid);
      }
      this.allSelected = !this.allSelected;
    },
    setEmoji(emoji) {
      this.text += emoji.native;
    },
    selectDate(date) {
      console.log('date', date);
      if (!this.isLoadingDate) {
        this.selectedTab = 'info';
        this.getDate(date);
      }
    },
    selectGroupDate(date) {
      if (this.selectedDates.includes(date.uuid)) {
        const index = this.selectedDates.indexOf(date.uuid);
        this.selectedDates.splice(index, 1);
      } else {
        this.selectedDates.push(date.uuid);
      }
    },
    scrollDown() {
      const element = document.getElementById('messages_container');
      element.scrollTop = element.scrollHeight;
    },
    closeDate() {
      this.$store.dispatch('setActiveDate', null)
        .then((response) => {
          console.log('respuesta', response);
          this.addHashToLocation('/dates');
        }, (error) => {
          console.log('error', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    changedCompany() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeDate();
      this.getDatesUsers();
      this.getDatesUsersList();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getChannels();
      this.getIntegrations();
      this.getDates();
      this.getForms();
      this.getConnections();
      this.getSaleProbabilities();
      this.getProductSections();
      this.getProductCategories();
      this.getProducts();
      this.getMedicalCenters();
      this.getDoctors();
      this.getSpecialties();
      this.getLeadNotifications();
      this.getMessageNotifications();
      this.getAppointmentConnections();
    },
    changedForm() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeDate();
      this.getDatesUsers();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getDates();
    },
    changedArea() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeDate();
      this.getDatesUsers();
      this.getStatuses();
      this.getDates();
    },
    setToken() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then(
          (response) => {
            console.log('response token', response);
            this.getCompanies();
            this.getActualToken();
          },
          (error) => {
            console.log('error token', error);
          },
        ).catch((error) => {
          console.log('error token', error);
        });
    },
    getCompanies() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then((response) => {
          console.log('respuesta companies', response);
          this.setActiveCompany();
          this.isLoadingCompanies = false;
        }, (error) => {
          console.log('error companies', error);
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error company', error);
        });
    },
    setActiveCompany() {
      const selectedCompany = localStorage.getItem('companyUuid');
      let company = null;
      if (selectedCompany) {
        const index = this.companies.findIndex((comany) => comany.uuid === selectedCompany);
        company = this.companies[index];
      } else {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.getDates();
          this.getDatesUsers();
          this.getDatesUsersList();
          this.getForms();
          this.getConnections();
          this.getSaleProbabilities();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getChannels();
          this.getIntegrations();
          this.getProductSections();
          this.getProductCategories();
          this.getProducts();
          this.getMedicalCenters();
          this.getDoctors();
          this.getSpecialties();
          this.getLeadNotifications();
          this.getMessageNotifications();
          this.getAppointmentConnections();
          const id = this.$route.query.date;
          if (id) {
            this.selectDate(id);
          }
        }, (error) => {
          this.isLoading = false;
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    refresh() {
      this.getDates();
      this.getDatesUsers();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getChannels();
    },
    getDates() {
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        user: this.selectedUser,
        status: this.selectedStatus,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        probability: this.probability?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        form: this.activeForm?.id,
        medical_center: this.activeMedicalCenter?.id,
        doctor: this.activeDoctor?.id,
        specialty: this.activeSpecialty?.id,
        area: this.activeArea?.id,
        pages: 25,
        show_calendar: this.showCalendar,
      };
      if (this.showCalendar) {
        console.log('this.startCalendarDate', this.startCalendarDate);
        console.log('this.endCalendarDate', this.endCalendarDate);
        payload.start = this.formatDateToDDMMYYYY(this.startCalendarDate);
        payload.end = this.formatDateToDDMMYYYY(this.endCalendarDate);
        payload.pages = 400;
      }
      console.log('payload dates', payload);
      this.isLoadingDates = true;
      this.$store.dispatch('dates', payload)
        .then((response) => {
          console.log('respuesta dates', response);
          this.isLoadingDates = false;
          this.calendarOptions.events = this.calendarEvents;
        }, (error) => {
          console.log('error dates', error);
          this.isLoadingDates = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingDates = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error dates', error);
        });
    },
    getLeadNotifications() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingLeadNotifications = true;
      this.$store.dispatch('leadNotifications', payload)
        .then((response) => {
          console.log('respuesta leadNotifications', response);
          this.isLoadingLeadNotifications = false;
        }, (error) => {
          console.log('error leadNotifications', error);
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadNotifications', error);
        });
    },
    getMessageNotifications() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingMessageNotifications = true;
      this.$store.dispatch('messageNotifications', payload)
        .then((response) => {
          console.log('respuesta messageNotifications', response);
          this.isLoadingMessageNotifications = false;
        }, (error) => {
          console.log('error messageNotifications', error);
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messageNotifications', error);
        });
    },
    loadMore() {
      if (this.isLoadingMore) return;
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        user: this.selectedUser,
        status: this.selectedStatus,
        url: this.nextPageUrl,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        probability: this.probability?.id,
        channel: this.channel?.id,
        form: this.activeForm?.id,
        medical_center: this.activeMedicalCenter?.id,
        doctor: this.activeDoctor?.id,
        specialty: this.activeSpecialty?.id,
        area: this.activeArea?.id,
        pages: 25,
        show_calendar: this.showCalendar,
      };
      if (this.showCalendar) {
        console.log('this.startCalendarDate', this.startCalendarDate);
        console.log('this.endCalendarDate', this.endCalendarDate);
        payload.start = this.formatDateToDDMMYYYY(this.startCalendarDate);
        payload.end = this.formatDateToDDMMYYYY(this.endCalendarDate);
        payload.pages = 400;
      }
      this.isLoadingMore = true;
      this.$store.dispatch('nextPageAppointments', payload)
        .then((response) => {
          console.log('respuesta conversations', response);
          this.isLoadingMore = false;
        }, (error) => {
          this.isLoadingMore = false;
          console.log('error conversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMore = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversations', error);
        });
    },
    getConnections() {
      // TODO: send pagination
      this.isLoadingConnections = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('connections', payload)
        .then((response) => {
          this.isLoadingConnections = false;
          console.log('respuesta connections', response);
        }, (error) => {
          this.isLoadingConnections = false;
          console.log('error connections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingConnections = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error connections', error);
        });
    },
    getDatesUsers() {
      // TODO: send pagination

      this.isLoadingDatesUsers = true;
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        status: this.selectedStatus,
        form: this.activeForm?.id,
        medical_center: this.activeMedicalCenter?.id,
        doctor: this.activeDoctor?.id,
        specialty: this.activeSpecialty?.id,
        area: this.activeArea?.id,
        probability: this.probability?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('appointmentUsers', payload)
        .then((response) => {
          this.isLoadingDatesUsers = false;
          console.log('respuesta leadUsers', response);
        }, (error) => {
          this.isLoadingDatesUsers = error;
          console.log('error leadUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingDatesUsers = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadUsers', error);
        });
    },
    getDatesUsersList() {
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        status: this.selectedStatus,
      };
      this.$store.dispatch('leadUsersList', payload)
        .then((response) => {
          console.log('respuesta leadUsersList', response);
          this.assignUsersList = this.datesUsersList.map((user) => {
            const usr = user;
            usr.selected = false;
            return usr;
          });
        }, (error) => {
          console.log('error leadUsersList', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadUsersList', error);
        });
    },
    createField() {
      const field = {
        data: this.newField.data,
        extra: false,
        is_editable: true,
        is_visible: true,
        key: this.newField.label,
        label: this.newField.label,
      };
      this.activeDate.fields.push(field);
      this.showFieldInterface = false;
      this.newField = {
        label: null,
        data: null,
      };
    },
    addNewField() {
      const field = {
        data: this.createNewField.data,
        extra: false,
        is_editable: true,
        is_visible: true,
        key: this.createNewField.name,
        label: this.createNewField.name,
        name: this.createNewField.name,
      };
      this.newLead.fields.push(field);
      this.showCreateFieldInterface = false;
      this.createNewField = {
        name: null,
        label: null,
        data: null,
      };
    },
    deleteField(key) {
      this.filteredFields.splice(key, 1);
    },
    createNote() {
      const payload = {
        note: this.note,
        appointment: this.activeDate.uuid,
      };
      this.isLoadingCreateNote = true;
      this.$store.dispatch('createAppointmentNote', payload)
        .then(
          (response) => {
            this.isLoadingCreateNote = false;
            this.showNoteInterface = false;
            this.note = '';
            console.log('response createAppointmentNote', response);
            this.$notify({
              type: 'success',
              title: 'Nota creada',
              text: response.data.message,
            });
            this.getNotes();
          },
          (error) => {
            this.isLoadingCreateNote = false;
            console.log('error createAppointmentNote', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateNote = false;
          console.log('error createAppointmentNote', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    sendEmail() {
      const payload = {
        document: this.selectedDocument.uuid,
        email: this.documentReceptor,
        lead: this.activeDate.uuid,
      };
      this.isLoadingDate = true;
      this.$store.dispatch('sendEmail', payload)
        .then(
          (response) => {
            this.isLoadingDate = false;
            this.showEmailModal = false;
            this.selectedDocument = null;
            this.documentReceptor = null;
            console.log('response sendEmail', response);
            this.$notify({
              type: 'success',
              title: 'Documento enviado',
              text: response?.data?.message,
            });
            this.getLeadDocuments();
          },
          (error) => {
            this.isLoadingDate = false;
            console.log('error sendEmail', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingDate = false;
          console.log('error sendEmail', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    deleteFile() {
      const payload = {
        document: this.selectedDocument.uuid,
        lead: this.activeDate.uuid,
        company: this.activeCompany.uuid,
      };
      console.log('payload', payload);
      this.isLoadingDate = true;
      this.$store.dispatch('deleteFile', payload)
        .then(
          (response) => {
            this.isLoadingDate = false;
            this.showDeleteFileModal = false;
            this.getLeadDocuments();
            console.log('response deleteFile', response);
            this.$notify({
              type: 'success',
              title: 'Documento enviado',
              text: response?.data?.message,
            });
            this.getLeadDocuments();
          },
          (error) => {
            this.isLoadingDate = false;
            console.log('error deleteFile', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingDate = false;
          console.log('error deleteFile', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    getNotes() {
      const payload = {
        appointment: this.activeDate.uuid,
      };
      this.isLoadingConversationNotes = true;
      this.$store.dispatch('appointmentNotes', payload)
        .then((response) => {
          this.isLoadingConversationNotes = false;
          console.log('respuesta appointmentNotes', response);
        }, (error) => {
          this.isLoadingConversationNotes = false;
          console.log('error appointmentNotes', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationNotes = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error appointmentNotes', error);
        });
    },
    getStatuses() {
      // TODO: send pagination
      this.isLoadingStatuses = true;
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        user: this.selectedUser,
        form: this.activeForm?.id,
        medical_center: this.activeMedicalCenter?.id,
        doctor: this.activeDoctor?.id,
        specialty: this.activeSpecialty?.id,
        area: this.activeArea?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        probability: this.probability?.id,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('appointmentStatuses', payload)
        .then((response) => {
          this.isLoadingStatuses = false;
          console.log('respuesta statuses', response);
        }, (error) => {
          this.isLoadingStatuses = false;
          console.log('error statuses', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingStatuses = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error statuses', error);
        });
    },
    getAreas() {
      // TODO: send pagination
      this.isLoadingAreas = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('areas', payload)
        .then((response) => {
          this.isLoadingAreas = false;
          console.log('respuesta areas', response);
        }, (error) => {
          this.isLoadingAreas = false;
          console.log('error areas', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingAreas = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error areass', error);
        });
    },
    getLocations() {
      // TODO: send pagination
      this.isLoadingLocations = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('locations', payload)
        .then((response) => {
          this.isLoadingLocations = false;
          console.log('respuesta isLoadingLocations', response);
        }, (error) => {
          this.isLoadingLocations = false;
          console.log('error areas', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingLocations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error isLoadingLocations', error);
        });
    },
    getChannels() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        user: this.selectedUser,
      };
      this.$store.dispatch('channels', payload)
        .then((response) => {
          console.log('respuesta channels', response);
        }, (error) => {
          console.log('error channels', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error channels', error);
        });
    },
    getForms() {
      // TODO: send pagination
      this.isLoadingForms = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('forms', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta forms', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error forms', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error forms', error);
        });
    },
    getAppointmentProducts() {
      const payload = {
        appointment: this.activeDate.uuid,
      };
      this.isLoadingLeadProducts = true;
      this.$store.dispatch('appointmentProducts', payload)
        .then((response) => {
          this.calculateTotalProducts();
          this.isLoadingLeadProducts = false;
          console.log('respuesta appointmentProducts', response);
        }, (error) => {
          this.isLoadingLeadProducts = false;
          console.log('error appointmentProducts', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadProducts = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error appointmentProducts', error);
        });
    },
    calculateTotalProducts() {
      let total = this.activeDate.total_sessions
        ? parseInt(this.activeDate.total_sessions, 10) : 0;
      let pending = this.activeDate.used_sessions
        ? parseInt(this.activeDate.used_sessions, 10) : 0;
      if (this.appointmentProducts.length > 0) {
        total = 0;
        pending = 0;
      }
      this.appointmentProducts.forEach((product) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        total += product.product?.number_dates * product.quantity;
        pending += product.used_sessions;
      });
      this.activeDate.used_sessions = pending;
      this.activeDate.total_sessions = total;
      this.activeDate.pending_sessions = total - pending;
    },
    getSaleProbabilities() {
      // TODO: send pagination
      this.isLoadingForms = true;
      console.log('the company', this.activeCompany);
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('probabilities', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta probabilities', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error probabilities', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error probabilities', error);
        });
    },
    getIntegrations() {
      console.log('the company', this.activeCompany);
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('integrations', payload)
        .then((response) => {
          console.log('respuesta integrations', response);
          response.data.success.forEach((integration) => {
            if (integration.type === 'vtiger' || integration.type === 'teojama') {
              this.getIntegrationUsers(integration.type, integration.uuid);
            }
          });
        }, (error) => {
          console.log('error integrations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrations', error);
        });
    },
    getIntegrationUsers(type, integration) {
      console.log('type', type);
      console.log('integration', integration);
      const payload = {
        company: this.activeCompany.uuid,
        type,
        integration,
      };
      console.log('payload', payload);
      this.$store.dispatch('integrationUsers', payload)
        .then((response) => {
          console.log('respuesta integrationUsers', response);
        }, (error) => {
          console.log('error integrationUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrationUsers', error);
        });
    },
    getNumbers() {
      this.$store.dispatch('conversations', this.activeCompany.uuid)
        .then((response) => {
          console.log('respuesta', response);
          const { conversations } = response.data.success;
          console.log('conversations', conversations);
        }, (error) => {
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    // sessions
    decreaseSessions(appointmentProduct) {
      if (appointmentProduct.used_sessions_copy > 0) {
        // eslint-disable-next-line
        appointmentProduct.used_sessions_copy--;
      }
    },
    increaseSessions(appointmentProduct) {
      // eslint-disable-next-line
      const totalSessions = (appointmentProduct.product?.number_dates * appointmentProduct.quantity) - appointmentProduct.used_sessions;
      if (appointmentProduct.used_sessions_copy < totalSessions) {
        // eslint-disable-next-line
        appointmentProduct.used_sessions_copy++;
      }
    },
    updateSessionsHandler(appointmentProduct) {
      this.updateSessions(appointmentProduct);
    },
    updateSessions(appointmentProduct) {
      const payload = {
        company: this.activeCompany.uuid,
        appointment: appointmentProduct.appointment_uuid,
        id: appointmentProduct.id,
        status_id: appointmentProduct.status_id,
        total_sessions: appointmentProduct.total_sessions_value,
        new_used_sessions: appointmentProduct.used_sessions_copy,
        used_sessions: appointmentProduct.used_sessions,
      };
      this.$store.dispatch('updateSessions', payload)
        .then((response) => {
          console.log('respuesta updateSessions', response);
          this.getDate(this.activeDate.uuid);
        }, (error) => {
          console.log('error updateSessions', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error updateSessions', error);
        });
    },
    updateProductNote(appointmentProduct) {
      const payload = {
        company: this.activeCompany.uuid,
        appointment: appointmentProduct.appointment_uuid,
        id: appointmentProduct.id,
        note: appointmentProduct.note,
      };
      this.$store.dispatch('updateProductNote', payload)
        .then((response) => {
          console.log('respuesta updateProductNote', response);
          this.getDate(this.activeDate.uuid);
        }, (error) => {
          console.log('error updateProductNote', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error updateProductNote', error);
        });
    },
    addProducts() {
      const payload = {
        company: this.activeCompany.uuid,
        appointment: this.activeDate.uuid,
      };
      const products = [];
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            products.push(product);
          }
        });
      });
      payload.products = products;
      this.$store.dispatch('addProducts', payload)
        .then((response) => {
          console.log('respuesta addProducts', response);
          this.getDate(this.activeDate.uuid);
          this.showProductsModal = false;
        }, (error) => {
          console.log('error addProducts', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error addProducts', error);
        });
    },
    deleteAppointmentProduct(appointmentProduct) {
      const payload = {
        company: this.activeCompany.uuid,
        appointmentProducts: appointmentProduct.appointments.map((ap) => ap.id),
      };
      this.$store.dispatch('deleteProducts', payload)
        .then((response) => {
          console.log('respuesta deleteProducts', response);
          this.getDate(this.activeDate.uuid);
          this.showDeleteProductModal = false;
          this.$notify({
            type: 'success',
            title: 'producto eliminado',
            text: 'Se eliminó el producto correctamente',
          });
        }, (error) => {
          console.log('error deleteProducts', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteProducts', error);
        });
    },
    reScheduleDate() {
      const payload = {
        company: this.activeCompany.uuid,
        appointment: this.activeDate.uuid,
        date: this.parsedReScheduleDate,
        schedule: `${this.reschedule.schedule?.startLabel
        }-${this.reschedule.schedule?.endLabel}`,
      };
      this.$store.dispatch('reScheduleDate', payload)
        .then((response) => {
          console.log('respuesta reScheduleDate', response);
          this.reschedule = {
            connection_id: null,
            medical_center_id: null,
            doctor_id: null,
            procedure_id: null,
            date: null,
            schedules: [],
            schedule: null,
          };
          this.showReScheduleModal = false;
          this.getDate(this.activeDate.uuid);
        }, (error) => {
          console.log('error reScheduleDate', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error reScheduleDate', error);
        });
    },
    closeModalUpdate(uuid) {
      this.showModal = false;
      this.getDate(uuid);
    },
    getDate(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      this.addHashToLocation(`/dates?date=${uuid}`);
      this.isLoadingDate = true;
      this.$store.dispatch('date', payload)
        .then((response) => {
          this.isLoadingDate = false;
          console.log('respuesta date', response);
          // this.getLeadEvents();
          this.getAppointmentHistory();
          this.getNotes();
          this.getAppointmentConversations();
          this.getAppointmentLeads();
          this.getAppointmentAppointments();
          this.getAppointmentProducts();
          // this.getLeadConversations();
          // this.getLeadDocuments();
        }, (error) => {
          this.isLoadingDate = false;
          console.log('error date', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingDate = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error date', error);
        });
    },
    getDateLeads() {
      const payload = {
        lead: this.activeDate.uuid,
      };
      this.isLoadingLeadLeads = true;
      this.$store.dispatch('leadEvents', payload)
        .then((response) => {
          this.isLoadingLeadLeads = false;
          console.log('respuesta leadLeads', response);
        }, (error) => {
          this.isLoadingLeadLeads = false;
          console.log('error leadLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messages', error);
        });
    },
    getAppointmentConversations() {
      const payload = {
        date: this.activeDate.uuid,
      };
      this.isLoadingAppointmentConversations = true;
      this.$store.dispatch('appointmentConversations', payload)
        .then((response) => {
          this.isLoadingAppointmentConversations = false;
          console.log('respuesta AppointmentConversations', response);
        }, (error) => {
          this.isLoadingAppointmentConversations = false;
          console.log('error AppointmentConversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentConversations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error AppointmentConversations', error);
        });
    },
    getAppointmentLeads() {
      const payload = {
        date: this.activeDate.uuid,
      };
      this.isLoadingAppointmentLeads = true;
      this.$store.dispatch('appointmentLeads', payload)
        .then((response) => {
          this.isLoadingAppointmentLeads = false;
          console.log('respuesta AppointmentLeads', response);
        }, (error) => {
          this.isLoadingAppointmentLeads = false;
          console.log('error AppointmentLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error AppointmentLeads', error);
        });
    },
    getAppointmentAppointments() {
      const payload = {
        date: this.activeDate.uuid,
      };
      this.isLoadingAppointmentAppointments = true;
      this.$store.dispatch('appointmentAppointments', payload)
        .then((response) => {
          this.isLoadingAppointmentAppointments = false;
          console.log('respuesta appointmentAppointments', response);
        }, (error) => {
          this.isLoadingAppointmentAppointments = false;
          console.log('error appointmentAppointments', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentAppointments = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error appointmentAppointments', error);
        });
    },
    getAppointmentHistory() {
      const payload = {
        appointment: this.activeDate.uuid,
      };
      this.isLoadingAppointmentHistory = true;
      this.$store.dispatch('appointmentHistory', payload)
        .then((response) => {
          this.isLoadingAppointmentHistory = false;
          console.log('respuesta AppointmentHistory', response);
        }, (error) => {
          this.isLoadingAppointmentHistory = false;
          console.log('error AppointmentHistory', error);
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: error?.data?.message,
          // });
        })
        .catch((error) => {
          this.isLoadingAppointmentHistory = false;
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: 'hubo un error',
          // });
          console.log('error AppointmentHistory', error);
        });
    },
    setType(type) {
      this.type = type;
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.getDatesUsers();
      this.getStatuses();
      this.getDates();
    },
    setOnMessage() {
      onMessage(this.$messaging, (payload) => {
        if (payload.data.for === 'user') {
          this.$notify({
            type: 'success',
            title: payload.notification.title,
            text: payload.notification.body,
          });
        }
        this.getDates();
        this.getMessageNotifications();
        console.log('Message received. ', payload);
        // if (this.activeConversation) {
        //   if (this.activeConversation.uuid === payload.data.conversation_id) {
        //     this.selectedTab = 'chat';
        //     setTimeout(() => {
        //       this.getMessagesAgain();
        //     }, 100);
        //   }
        // } else {
        //   console.log('no esta activa');
        //   console.log(payload.data.conversation_id);
        //   // TODO: check if is in correct company, if no set the correct company
        //   this.getDate(payload.data.conversation_id);
        // }
      });
    },
    getKeys(obj) {
      return Object.keys(obj);
    },
    checkIfExist(items, item) {
      return items.includes(item);
    },

    sourceDate(lead) {
      if (lead) {
        let url = '';
        switch (lead.form_provider) {
          case 'manychat':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'manychat-instagram':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'typeform':
            // eslint-disable-next-line global-require
            url = require('@/assets/typeform.svg');
            break;
          case 'elementor':
            // eslint-disable-next-line global-require
            url = require('@/assets/elementor.png');
            break;
          case 'facebook_leadgen':
            // eslint-disable-next-line global-require
            url = require('@/assets/facebook-leadgen.png');
            break;
          case 'wordpress':
            // eslint-disable-next-line global-require
            url = require('@/assets/wordpress.png');
            break;
          case 'drupal':
            // eslint-disable-next-line global-require
            url = require('@/assets/drupal.png');
            break;
          case 'landing':
            // eslint-disable-next-line global-require
            url = require('@/assets/landing.png');
            break;
          case 'email':
            // eslint-disable-next-line global-require
            url = require('@/assets/email.png');
            break;
          case 'excel':
            // eslint-disable-next-line global-require
            url = require('@/assets/excel.png');
            break;
          case 'patiotuerca':
            // eslint-disable-next-line global-require
            url = require('@/assets/patiotuerca.webp');
            break;
          case 'ecommerce':
            // eslint-disable-next-line global-require
            url = require('@/assets/shop-cart.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'add-sales':
            // eslint-disable-next-line global-require
            url = require('@/assets/add-sales.jpg');
            break;
          case 'channels':
            if (this.activeDate.channel_icon) {
              url = `https://leadbox.ec/storage/${this.activeDate.channel_icon}`;
            } else {
              // eslint-disable-next-line global-require
              url = require('@/assets/filter.png');
            }
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
          default:
            break;
        }
        return url;
      }
      return '';
    },
    getActualToken() {
      getToken(this.$messaging, {
        vapidKey: 'BLfWeuuIdNsHrWx-2XIUiXfn_PEWjUMI6oUwLRSerdWw4JmKxE6O22nxcJ6dO60ZPahGMSBlyUTasq1OkgYogdc',
      }).then((currentToken) => {
        if (currentToken) {
          console.log('token', currentToken);
          this.$store.dispatch('tokens', [currentToken])
            .then((response) => {
              console.log('respuesta', response);
              this.setOnMessage();
            }, (error) => {
              console.log('status', error);
              this.$notify({
                type: 'error',
                title: 'Error',
                text: error?.data?.message,
              });
            })
            .catch((error) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: 'hubo un error',
              });
              console.log('error', error);
            });
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    },
    // appointments
    getAppointmentConnections() {
      // TODO: send pagination
      this.isLoadingAppointmentConnections = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('appointmentConnections', payload)
        .then((response) => {
          this.isLoadingAppointmentConnections = false;
          console.log('respuesta Appointmentconnections', response);
          console.log('respuesta2 Appointmentconnections', this.appointmentConnections);
        }, (error) => {
          this.isLoadingAppointmentConnections = false;
          console.log('error Appointmentconnections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentConnections = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error Appointmentconnections', error);
        });
    },
    getAppointmentsCities() {
      if (!this.newAppointment.connection) {
        return;
      }
      console.log('ppointment.connection', this.newAppointment.connection);

      const payload = {
        connection: this.newAppointment.connection?.uuid,
      };
      this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsCities', payload)
        .then((response) => {
          this.isLoadingAppointmentsCities = false;
          console.log('respuesta AppointmentsCities', response);
          this.newAppointment.cities = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          if (this.newAppointment.connection.city_id) {
            console.log('si hay ', this.newAppointment.connection.city_id);
            const foundCity = this.newAppointment.cities.find(
              (city) => city.id === this.newAppointment.connection.city_id,
            );
            if (foundCity) {
              console.log('foundCity ', foundCity);
              this.newAppointment.city = foundCity;
              this.getAppointmentsMedicalCenters();
            }
          }
          if (this.activeDate?.medical_center_city) {
            const foundCity = this.newAppointment.cities.find(
              (city) => city.name === this.activeDate?.medical_center_city,
            );
            if (foundCity) {
              console.log('foundCity ', foundCity);
              this.newAppointment.city = foundCity;
              this.getAppointmentsMedicalCenters();
            }
          }
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          this.isLoadingAppointmentsCities = false;
          console.log('error AppointmentsCities', error);
          this.newAppointment.cities = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          this.isLoadingAppointmentsCities = false;
          console.log('error AppointmentsCities', error);
          this.newAppointment.cities = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsMedicalCenters() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      console.log('this.newAppointment.city', this.newAppointment.city);
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsMedicalCenters', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsMedicalCenters', response);
          const medicalCenters = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
          if (medicalCenters.length > 10) {
            this.getAppointmentsSectors();
          } else {
            this.newAppointment.medicalCenters = medicalCenters;
          }
          if (this.newAppointment.connection.medical_center_id) {
            console.log('si hay ', this.newAppointment.connection.medical_center_id);
            const foundMedicalCenter = this.newAppointment.medicalCenters.find(
              // eslint-disable-next-line
              (medicalCenter) => medicalCenter.id === this.newAppointment.connection.medical_center_id,
            );
            if (foundMedicalCenter) {
              console.log('foundMedicalCenter ', foundMedicalCenter);
              this.newAppointment.medicalCenter = foundMedicalCenter;
              this.getAppointmentsDoctorsAndSpecialties();
            }
          } else {
            this.newAppointment.medicalCenter = null;
          }
          if (this.activeDate?.medical_center_id) {
            const foundMedicalCenter = this.newAppointment.medicalCenters.find(
              // eslint-disable-next-line
              (medicalCenter) => medicalCenter.id === this.activeDate?.medical_center_id,
            );
            if (foundMedicalCenter) {
              console.log('foundMedicalCenter ', foundMedicalCenter);
              this.newAppointment.medicalCenter = foundMedicalCenter;
              this.getAppointmentsDoctorsAndSpecialties();
            }
          }
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsMedicalCenters', error);
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsMedicalCenters', error);
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsDoctorsAndSpecialties() {
      this.getAppointmentsDoctors();
      this.getAppointmentsSpecialties();
      // esto
    },
    getAppointmentsDoctors() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsDoctors', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsDoctors', response);
          this.newAppointment.doctors = response.data;
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
          if (this.activeDate?.doctor_id) {
            const foundDoctor = this.newAppointment.doctors.find(
              // eslint-disable-next-line
              (doctor) => doctor.id === this.activeDate?.doctor_id,
            );
            if (foundDoctor) {
              console.log('foundDoctor ', foundDoctor);
              this.newAppointment.doctor = foundDoctor;
              this.getAppointmentsSpecialtiesByDoctor();
            }
          } else {
            this.newAppointment.doctor = null;
          }
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsDoctors', error);
          this.newAppointment.doctors = [];
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsDoctors', error);
          this.newAppointment.doctors = [];
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSpecialties() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSpecialties', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSpecialties', response);
          this.newAppointment.specialties = response.data;
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialties', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialties', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSpecialtiesByDoctor() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        doctor_id: this.newAppointment.doctor?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSpecialtiesByDoctor', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSpecialtiesByDoctor', response);
          this.newAppointment.specialties = response.data;
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialtiesByDoctor', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialtiesByDoctor', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSectors() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSectors', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSectors', response);
          this.newAppointment.sectors = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectors', error);
          this.newAppointment.sectors = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectors', error);
          this.newAppointment.sectors = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSectorMedicalCenters() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.sector) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        sector: this.newAppointment.sector,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSectorMedicalCenters', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSectorMedicalCenters', response);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = response.data;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectorMedicalCenters', error);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = [];
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectorMedicalCenters', error);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = [];
        });
    },
    getAppointmentsReSchedules() {
      if (!this.reschedule.connection_id) {
        return;
      }
      if (!this.reschedule.medical_center_id) {
        return;
      }
      if (!this.reschedule.date) {
        return;
      }
      const payload = {
        connection: this.reschedule.connection_id,
        medical_center_id: this.reschedule.medical_center_id,
        date: this.parsedReScheduleDate,
        endpoint: 'schedules',
      };
      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.doctor_id = this.reschedule.doctor_id;
        payload.endpoint = 'schedules-aura';
      }
      console.log('payload', payload);
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSchedules', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSchedules', response);
          this.reschedule.schedules = response.data;
          this.reschedule.schedule = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.reschedule.schedules = [];
          this.reschedule.schedule = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.reschedule.schedules = [];
          this.reschedule.schedule = null;
        });
    },
    getAppointmentsSchedules() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      if (!this.newAppointment.date) {
        return;
      }
      console.log('this.company', this.activeCompany.uuid);
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        medical_center_id: this.newAppointment.medicalCenter?.id,
        date: this.parsedAppointmentDate,
        endpoint: 'schedules',
      };
      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.doctor_id = this.newAppointment.doctor?.id;
        payload.endpoint = 'schedules-aura';
      }
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSchedules', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSchedules', response);
          this.newAppointment.schedules = response.data;
          this.newAppointment.schedule = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
        });
    },
    toggleDropdown(appointment) {
      // eslint-disable-next-line no-param-reassign
      appointment.dropdownOpen = !appointment.dropdownOpen;
    },
    createAppointment() {
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id?.toString(),
        medical_center_id: this.newAppointment.medicalCenter?.id?.toString(),
        date: this.parsedAppointmentDate,
        schedule: `${this.newAppointment.schedule?.startLabel
        }-${this.newAppointment.schedule?.endLabel}`,
        name: this.newAppointment.name,
        email: this.newAppointment.email,
        phone: this.newAppointment.phone,
        document: this.newAppointment.document,
        number: this.newAppointment.number,
        endpoint: 'appointment',
        create_lead: true,
      };
      if (this.newAppointment.doctor) {
        payload.doctor_id = this.newAppointment.doctor?.id;
      }
      if (this.newAppointment.specialty) {
        payload.specialty_id = this.newAppointment.specialty?.id;
      }

      const products = [];
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            products.push(product);
          }
        });
      });
      payload.products = products;

      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.endpoint = 'appointment-aura';
      }
      console.log('payload creatre appointment', payload);
      this.isLoadingCreateAppointment = true;
      this.$store.dispatch('createAppointment', payload)
        .then(
          (response) => {
            this.isLoadingCreateAppointment = false;
            console.log('response createAppointment', response);
            this.$notify({
              type: 'success',
              title: 'Cita creada',
            });
            this.showCreateModal = false;
            this.newAppointment = {
              sell_date: null,
              connection: null,
              addProduct: true,
              category: null,
              product: null,
              name: null,
              number: null,
              email: null,
              phone: null,
              type: 'cedula',
              document: null,
              cities: [],
              city: null,
              sectors: [],
              sector: null,
              medicalCenters: [],
              medicalCenter: null,
              specialties: [],
              specialty: null,
              date: null,
              schedules: [],
              schedule: null,
              doctors: [],
              doctor: null,
            };
            this.getDates();
            this.closeDate();
          },
          (error) => {
            this.isLoadingCreateAppointment = false;
            console.log('error createAppointment', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateAppointment = false;
          console.log('error createAppointment', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    createAppointmentDate() {
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id?.toString(),
        medical_center_id: this.newAppointment.medicalCenter?.id?.toString(),
        date: this.parsedAppointmentDate,
        schedule: `${this.newAppointment.schedule?.startLabel
        }-${this.newAppointment.schedule?.endLabel}`,
        name: this.newAppointment.name,
        surname: this.newAppointment.surname,
        email: this.newAppointment.email,
        phone: this.newAppointment.phone,
        document: this.newAppointment.document,
        number: this.newAppointment.number,
        date_id: this.activeDate.uuid,
        endpoint: 'appointment',
        create_lead: true,
        total_sessions: this.activeDate.total_sessions,
        used_sessions: this.activeDate.used_sessions,
      };
      if (this.newAppointment.doctor) {
        payload.doctor_id = this.newAppointment.doctor?.id;
      }
      if (this.newAppointment.specialty) {
        payload.specialty_id = this.newAppointment.specialty?.id;
      }

      const products = [];
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            products.push(product);
          }
        });
      });
      payload.products = products;

      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.endpoint = 'appointment-aura';
      }
      console.log('payload creatre appointment', payload);
      this.isLoadingCreateAppointment = true;
      this.$store.dispatch('createAppointment', payload)
        .then(
          (response) => {
            this.showNewAppointmentModal = false;
            this.isLoadingCreateAppointment = false;
            console.log('response createAppointment', response);
            this.$notify({
              type: 'success',
              title: 'Cita creada',
            });
            this.showCreateModal = false;
            this.newAppointment = {
              sell_date: null,
              connection: null,
              addProduct: true,
              category: null,
              product: null,
              name: null,
              number: null,
              email: null,
              phone: null,
              type: 'cedula',
              document: null,
              cities: [],
              city: null,
              sectors: [],
              sector: null,
              medicalCenters: [],
              medicalCenter: null,
              specialties: [],
              specialty: null,
              date: null,
              schedules: [],
              schedule: null,
              doctors: [],
              doctor: null,
            };
            this.getDates();
            this.closeDate();
          },
          (error) => {
            this.isLoadingCreateAppointment = false;
            console.log('error createAppointment', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateAppointment = false;
          console.log('error createAppointment', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    /// space
    /// space
    /// space
    /// space
  },
  computed: {
    total_products() {
      let total = 0;
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            if (product.use_discount) {
              total += product.discount_price * product.quantity;
            } else {
              total += product.price * product.quantity;
            }
          }
        });
      });
      return total;
    },
    total_dates() {
      let total = 0;
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            total += product.number_dates * product.quantity;
          }
        });
      });
      return total;
    },
    filesUrl() {
      if (this.activeDate) {
        return `https://leadbox.ec/api/v1/leads/${this.activeDate.uuid}/uploadFile`;
      }
      return '';
    },
    calendarEvents() {
      return this.dates.map((event) => ({
        id: event.id,
        uuid: event.uuid,
        title: `${event.patient_name || 'Sin nombre'} - ${event.doctor_service || ''} ${event.doctor_name || 'Sin doctor'}`,
        start: `${event.date}T${event.start_time}`,
        end: `${event.date}T${event.end_time}`,
        description: event.observations,
        color: event.doctor.color,
        backgroundColor: event.doctor.color,
        textColor: event.doctor.color_text,
        lbData: event,
      }));
    },
    companyCategories() {
      return this.$store.state.productCategories;
    },
    source() {
      if (this.activeDate) {
        let url = '';
        switch (this.activeDate.form_provider) {
          case 'manychat':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'manychat-instagram':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'typeform':
            // eslint-disable-next-line global-require
            url = require('@/assets/typeform.svg');
            break;
          case 'elementor':
            // eslint-disable-next-line global-require
            url = require('@/assets/elementor.png');
            break;
          case 'facebook_leadgen':
            switch (this.activeDate.platform) {
              case 'ig':
              case 'in':
                // eslint-disable-next-line global-require
                url = require('@/assets/logo-instagram.png');
                break;
              case 'fb':
              default:
                // eslint-disable-next-line global-require
                url = require('@/assets/facebook-leadgen.png');
                break;
            }
            break;
          case 'wordpress':
            // eslint-disable-next-line global-require
            url = require('@/assets/wordpress.png');
            break;
          case 'drupal':
            // eslint-disable-next-line global-require
            url = require('@/assets/drupal.png');
            break;
          case 'landing':
            // eslint-disable-next-line global-require
            url = require('@/assets/landing.png');
            break;
          case 'email':
            // eslint-disable-next-line global-require
            url = require('@/assets/email.png');
            break;
          case 'excel':
            // eslint-disable-next-line global-require
            url = require('@/assets/excel.png');
            break;
          case 'patiotuerca':
            // eslint-disable-next-line global-require
            url = require('@/assets/patiotuerca.webp');
            break;
          case 'ecommerce':
            // eslint-disable-next-line global-require
            url = require('@/assets/shop-cart.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'leadbox-dates':
            // eslint-disable-next-line global-require
            url = require('@/assets/dates.png');
            break;
          case 'add-sales':
            // eslint-disable-next-line global-require
            url = require('@/assets/add-sales.jpg');
            break;
          case 'channels':
            if (this.activeDate.channel_icon) {
              url = `https://leadbox.ec/storage/${this.activeDate.channel_icon}`;
            } else {
              // eslint-disable-next-line global-require
              url = require('@/assets/filter.png');
            }
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
          default:
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
        }
        return url;
      }
      return '';
    },
    appointmentProducts() {
      return this.$store.state.appointmentProducts;
    },
    companies() {
      return this.$store.state.companies;
    },
    statuses() {
      return this.$store.state.appointmentStatuses;
    },
    medicalCenters() {
      return this.$store.state.medicalCenters;
    },
    doctors() {
      return this.$store.state.doctors;
    },
    specialties() {
      return this.$store.state.specialties;
    },
    activeMedicalCenter() {
      return this.$store.state.activeMedicalCenter;
    },
    activeDoctor() {
      return this.$store.state.activeDoctor;
    },
    activeSpecialty() {
      return this.$store.state.activeSpecialty;
    },
    activeStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'assigned' || status.type === 'initial');
      }
      return [];
    },
    closedStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'final_positive' || status.type === 'final_negative');
      }
      return [];
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    activeForm() {
      return this.$store.state.activeForm;
    },
    activeArea() {
      return this.$store.state.activeArea;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    channels() {
      return this.$store.state.channels;
    },
    messages() {
      return this.$store.state.conversationMessages;
    },
    dates() {
      return this.$store.state.dates;
    },
    total() {
      return this.$store.state.total;
    },
    conversations() {
      return this.$store.state.conversations;
    },
    activeDate() {
      return this.$store.state.activeDate;
    },
    datesUsers() {
      return this.$store.state.appointmentUsers;
    },
    datesUsersList() {
      return this.$store.state.leadsUsersList;
    },
    locations() {
      return this.$store.state.locations;
    },
    forms() {
      return this.$store.state.forms;
    },
    nextPageUrl() {
      return this.$store.state.next_page_url;
    },
    activeSender() {
      return this.$store.state.activeSender;
    },
    leadEvents() {
      return this.$store.state.leadEvents;
    },
    dateLeads() {
      return this.$store.state.dateLeads;
    },
    appointmentHistories() {
      return this.$store.state.appointmentHistories;
    },
    leadConversations() {
      return this.$store.state.leadConversations;
    },
    appointmentConversations() {
      return this.$store.state.appointmentConversations;
    },
    appointmentLeads() {
      return this.$store.state.appointmentLeads;
    },
    appointmentAppointments() {
      return this.$store.state.appointmentAppointments;
    },
    leadDocuments() {
      return this.$store.state.leadDocuments;
    },
    notes() {
      return this.$store.state.appointmentNotes;
    },
    areas() {
      return this.$store.state.areas;
    },
    probabilities() {
      return this.$store.state.probabilities;
    },
    companyIntegrations() {
      return this.$store.state.companyIntegrations;
    },
    integrationUsers() {
      return this.$store.state.integrationUsers;
    },
    appointmentConnections() {
      return this.$store.state.appointmentConnections;
    },
    parsedAppointmentDate() {
      if (this.newAppointment.date) {
        const fechaObjeto = new Date(`${this.newAppointment.date}T00:00:00-05:00`);
        // Obtener los componentes de la fecha
        const dia = fechaObjeto.getDate().toString().padStart(2, '0');
        const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, '0');
        const year = fechaObjeto.getFullYear();
        // Formatear la fecha en el formato 'DD/MM/YYYY'
        return `${dia}/${mes}/${year}`;
      }
      return '';
    },
    parsedReScheduleDate() {
      if (this.reschedule.date) {
        const fechaObjeto = new Date(`${this.reschedule.date}T00:00:00-05:00`);
        // Obtener los componentes de la fecha
        const dia = fechaObjeto.getDate().toString().padStart(2, '0');
        const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, '0');
        const year = fechaObjeto.getFullYear();
        // Formatear la fecha en el formato 'DD/MM/YYYY'
        return `${dia}/${mes}/${year}`;
      }
      return '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.acor .vcp__body-content {
  background: #f3f4f6 !important;
}

.acor .vcp__header-icon > span[data-v-64321428] {
  color: #4b4e4e !important;
}

.vcpg[data-v-63943e43] {
  border: none !important;
  border-radius: 0px !important;
}

.bg-header {
  background-color: #353E4B;
}

.vcp__header-icon {
  color: #bcc8ca;
}

.text-left {
  text-align: left;
}

.bg-green-720 {
  background-color: #2A6F7F !important;
}

.bg-indigo-600 {
  background: #205662 !important;
}

.hover\:bg-gray-500:hover {
  background-color: #2a6f7f7a;
  border-radius: 5px;
}

.flex.m-1.bg-white.p-2.cursor-pointer.border-r-4 {
  border-right: 4px solid #22d3c5;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100.bg-sky-400.border-sky-400.text-white {
  color: #ffffff;
  background-image: -webkit-gradient(linear, left top, right top, from(#00BFBC), to(#009DB2));
  background-image: linear-gradient(to right, #00BFBC, #009DB2);
  position: relative;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100 {
  color: #3a3838;
  background-color: #EAF2F9;
  border-color: #EAF2F9;
  position: relative;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}

.btn-states {
  border-radius: 7px;
  min-width: 125px;
  max-width: 145px;
  color: #333;
  padding: 0.25rem 0.5rem;
  font-size: 0.775rem;
  border-color: #5bc0de;
}

.btn-states:hover {
  background: #5bc0de;
  transition: 0.7s;
}

.text-sidebar {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}

button.bg-sky-600 {
  background-color: #2A6F7F !important;
}

.border-leadbox-600 {
  border-color: #2A6F7F !important;
}

.acor .vcpg[data-v-63943e43] .vcp__header {
  background-color: #f9f6f6 !important;
  border: 1px solid #5b5858 !important;
  margin: 10px 0px !important;
  border-radius: 5px;
}

.acor .vcp.vcp--expanded.vcp--expandable .vcp__header {
  background: #bfc8ca !important;
}

.vcpg[data-v-63943e43] .vcp__header {
  background-color: #353e4b !important;
}

.vcpg[data-v-63943e43] .vcp:not(:first-of-type) .vcp__header {
  border-top: 1px solid #4a4e50 !important;
}

.vcp__body-content {
  background: #2c3646;
}

.icons-float svg {
  padding: 10px;
  height: 45px;
  width: 45px;
  border-radius: 100%;
}

.icons-float svg:hover {
  background: #2a6f7f;
}

.bg-hover {
  background: #2a6f7f;
}

.bg-email {
  background: #fbd123;
}

.bg-phone {
  background: #0673f8;
}

.bg-phone-issabel {
  background: #7f0ac4;
}

.bg-chat {
  background: #09ce0d;
}

.icono-email {
  background: #fbd123;
  color: #fff;
}

.icono-phone {
  background: #0673f8;
  color: #fff;
}

.icono-chat {
  background: #09ce0d;
  color: #fff;
}

.icons-float {
  z-index: 99;
  text-align: center;
  display: flex;
}

.notShown {
  visibility: hidden !important;
}

.sidebar-menu.test-sidebar.hidden {
  max-width: 40px;
}

.vcpg {
  border-bottom: none !important;
  border-top: solid 1px #75777a !important;
}

.vcp__header-icon {
  color: white;
}

/*Scroll*/
.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 5px;
}

section.vcp.vcp--expanded.vcp--expandable .vcp__header {
  background: #2a6f7f !important;
  border: none !important;
}

.vcpg {
  border-left: none !important;
}

.border-leadbox-900 {
  border-color: #22d3c5;
}

.text-tabs {
  line-height: 18px;
}

@media (max-width: 600px) {
  .test-sidebar {
    overflow-y: auto;
  }

  .test-sidebar::-webkit-scrollbar-thumb {
    background-color: #22d3c5;
  }

  .test-sidebar::-webkit-scrollbar-track {
    background: #fff;
  }

  .test-sidebar::-webkit-scrollbar {
    width: 5px;
  }

  .area-message {
    left: -115px;
  }

  .area-notification {
    left: -71px;
  }

  .vcp__header[data-v-64321428] {
    padding: 0px !important;
  }

  .vcp__body-content[data-v-64321428] {
    padding: 12px 6px !important;
  }

  .scroll-states {
    overflow-x: auto;
  }

  .title-header {
    font-size: 20px;
  }

  .filter-date {
    padding-right: 0px;
    padding-top: 7px;
    padding-bottom: 11px;
    margin-right: 0px;
    border: none;
  }

  button.mr-1.focus\:outline-none.text-litepie-secondary-400.dark\:text-opacity-70.rounded-md {
    margin-right: 0px;
  }
}

/* Estilos personalizados para eventos */
.event-cancelled {
  background-color: #ff0000 !important; /* Rojo para eventos cancelados */
  border-color: #ff0000 !important;
  color: #ffffff !important; /* Blanco para el texto */
}

.event-confirmed {
  background-color: #00ff00 !important; /* Verde para eventos confirmados */
  border-color: #00ff00 !important;
  color: #000000 !important; /* Negro para el texto */
}

.fc-event {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 20px;
  font-size: 14px; /* Ajusta el tamaño de la fuente según sea necesario */
}
</style>
