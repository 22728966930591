<template>
  <div class="flex flex-col h-screen max-h-screen bg-white">
    <template v-if="isLoadingCompanies">
      <div class="w-full h-full bg-header flex items-center justify-center">
        <div class="flex flex-col">
          <div class="w-full flex justify-center">
            <img alt="loading" src="@/assets/loading.gif" class="w-20">
          </div>
          <p class="text-lg text-white text-center object-none object-center ">
            {{ activeUser.name }}, vamos por más
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <!--top bar-->
      <div class="w-full h-16 flex justify-between bg-header">
        <div class="md:w-1/5 w-36 flex justify-items-center cursor-pointer">
          <MainDropDown :type="'contacts'" class="w-full"/>
        </div>
        <CompanyDropDown class="p-1" :type="'contacts'" @changed-company="changedCompany"/>
        <FormDropDown class="p-1" :type="'contacts'" @changed-form="changedForm"/>
        <AreaDropDown v-if="areas.length > 0"
                      class="p-1" :type="'contacts'" @changed-form="changedArea"/>
<!--        <LeadNotificationsDropDown class="p-1" @open-lead="getLead"/>-->
<!--        <MessageNotificationsDropDown class="p-1" @open-conversation="getConversation"/>-->

        <div class="flex-grow"></div>
        <DropDown class="p-1 lg:p-3"/>
      </div>
      <div class="flex w-full flex-grow h-80">
        <!--left menu-->
        <div class="h-max bg-header lg:flex flex-col sidebar-menu md:overflow-y-auto"
             v-bind:class="{ 'hidden' : activeContact, 'flex' : !activeContact }">
          <div class="py-1 flex w-full cursor-pointer">
            <button class="sidebar-icons" @click="isShow = !isShow">
              <MenuIcon class="m-2 h-5 w-5 text-white text-right" aria-hidden="true"/>
            </button>
          </div>
          <div class="test-sidebar" v-bind:class="{ hidden: !isShow }">
            <vue-collapsible-panel-group accordion base-color="#000000">
              <vue-collapsible-panel>
                <template #title>
                  <div class="py-1 flex cursor-pointer md:w-52 w-32" @click="setType('active')"
                       @keydown="setType('active')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'active' }">
                    <CollectionIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Activas</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in activeStatuses"
                                v-bind:key="status.id" :status="status"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <div class="py-1 flex w-full cursor-pointer" @click="setType('closed')"
                       @keydown="setType('closed')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'closed' }">
                    <LockClosedIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Cerradas</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in closedStatuses" v-bind:key="status.id"
                                :status="status"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <div class="py-1 flex w-full cursor-pointer" @click="setType('archived')"
                       @keydown="setType('archived')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'archived' }">
                    <ArchiveIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Archivados</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in statuses" v-bind:key="status.id" :status="status"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
            </vue-collapsible-panel-group>
            <div class="h-fit max-h-fit">
              <vue-collapsible-panel-group accordion>
                <vue-collapsible-panel :expanded="true">
                  <template #title>
                    <div class="py-1 flex">
                      <UserIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                      <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">
                        <b>ASESORES</b>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div class="overflow-auto h-fit">
                      <template v-if="isLoadingContactsUsers">
                        <p class="text-white text-md">Cargando...</p>
                      </template>
                      <template v-else>
                        <AsesorItem v-for="(user) in datesUsers" v-bind:key="user.id" :user="user"
                                    :selected="selectedUser" @set-user="setUser"/>
                      </template>
                    </div>
                  </template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </div>
          </div>
        </div>
        <!--intermediate menu-->
        <div class="w-full md:w-7/12 lg:w-1/5 h-max bg-gray-200 lg:flex flex-col"
             v-bind:class="{ 'hidden' : activeContact, 'flex' : !activeContact }">
          <div class="w-full flex justify-center">
            <div class="flex py-1 mx-1 w-full">
              <litepie-datepicker class="filter-date" placeholder="Ver por fecha"
                                  separator=" hasta " i18n="es" overlay :auto-apply="false"
                                  :formatter="formatter" :options="options" v-model="dateValue">
              </litepie-datepicker>
            </div>
          </div>
          <div class="w-full">
            <div class="flex rounded-lg bg-gray-100">
              <!--eslint-disable-next-line-->
              <input class="p-2 w-10/12 bg-gray-100 text-sm focus:outline-none" v-model="search"
                     type="text" placeholder="Buscar citas...">
              <div class="p-1 w-2/12 flex justify-center items-center cursor-pointer bg-gray-100"
                   @click="getContacts()" @keydown="getContacts()">
                <SearchIcon class="m-2 h-6 w-6" aria-hidden="true"/>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="m-1 flex justify-between rounded-lg">
              <p class="my-2"><b>Total:</b> {{ total }}</p>
              <div class="flex">
                <div title="Eliminar selecionadas" class="hover:bg-gray-300">
                  <TrashIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                             v-if="selectedDates.length > 0" @click="showDeleteModal = true;"/>
                </div>
                <div title="Archivar selecionadas" class="hover:bg-gray-300">
                  <ArchiveIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                               v-if="selectedDates.length > 0" @click="showArchiveModal = true;"/>
                </div>
                <div title="Asignar selecionadas" class="hover:bg-gray-300">
                  <UserIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                            v-if="selectedDates.length > 0" @click="showAssignOneModal = true;"/>
                </div>

                <div title="Asignación automática" class="hover:bg-gray-300">
                  <UsersIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                             @click="showAssignModal = true;"/>
                </div>
<!--                <div title="Filtro de ubicaciones" class="hover:bg-gray-300">-->
<!--                  <LocationsDropDown v-if="locations.length>0" class="w-7 m-1 cursor-pointer"-->
<!--                                     aria-hidden="true" :location="location"-->
<!--                                     @set-location="setLocation"/>-->
<!--                </div>-->
                <div title="Filtro de canales" class="hover:bg-gray-300">
                  <ChannelsDropDown v-if="channels.length>0" class="w-7 m-1 cursor-pointer"
                                    aria-hidden="true" :channel="channel"
                                    @set-channel="setChannel"/>
                </div>
                <div title="Opciones" class="hover:bg-gray-300">
                  <LeadOptionsDropDown :order="order" @select-all="selectAll" @refresh="refresh"
                                       :all="allSelected" @change-order="changeOrder"
                                       @open-create="showCreateModal = true;"
                                       @open-group="showCreateModal = true;"/>
                </div>
              </div>

            </div>
          </div>
          <div class="scroll-smooth overflow-auto h-fit">
            <template v-if="isLoadingContacts">
              <p class="text-md text-black text-center">Cargando....</p>
            </template>
            <template v-else>
              <template v-if="contacts?.length>0">
                <DateItem v-for="(date) in contacts"
                          v-bind:key="date.id" :date="date"
                          :selected="selectedContacts"
                          @check-clicked="selectGroupContact"
                          @selected-date="selectContact"/>
              </template>
              <template v-else>
                <p class="text-center mt-10">
                  {{
                    search !== null && search !== '' ?
                      'No hay resultados para ' + this.search :
                      'No hay contactos'
                  }}</p>
              </template>
            </template>
          </div>
          <div class="w-full" v-if="nextPageUrl">
            <div class="m-1 flex rounded-lg cursor-pointer bg-white place-content-center p-2"
                 @click="loadMore()" @keydown="loadMore()">
              {{ isLoadingMore ? 'Cargando....' : 'ver más' }}
            </div>
          </div>
          <!--asignar leads-->
          <ModalBox :showing="showAssignModal" @close="showAssignModal = false">
            <div class="flex">
              <p class="text-black"><b>Asignar citas</b></p>
            </div>
            <template v-if="isLoadingAssignAuto">
              <p class="text-center">Cargando...</p>
            </template>
            <template v-else>
              <div class="flex">
                <p class="text-black">
                  Entregar las citas sin asignar automaticamente a los asesores
                </p>
              </div>
              <div class="w-full flex">
                <div class="m-1">
                  <Toggle v-model="selectAssignUsers"/>
                </div>
                <p class="ml-1">Seleccionar Usuarios?</p>
              </div>
              <template v-if="selectAssignUsers">
                <div class="w-full flex" v-for="(user) in assignUsersList"
                     v-bind:key="user.id">
                  <div class="m-1">
                    <Toggle v-model="user.selected"/>
                  </div>
                  <p class="ml-1">{{ user.name }}</p>
                </div>
              </template>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showAssignModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="assignDates()">Asignar equitativamente
                </button>
              </div>
            </template>
          </ModalBox>
          <!--asignar un asesor leads-->
          <ModalBox :showing="showAssignOneModal" @close="showAssignOneModal = false">
            <div class="flex">
              <p class="text-black"><b>Asignar citas a asesor</b></p>
            </div>
            <template v-if="isLoadingAssign">
              <p class="text-center">Cargando...</p>
            </template>
            <template v-else>
              <div class="flex flex-col">
                <!--eslint-disable-next-line-->
                <label class="w-full text-left" for="assignUser">
                  <b>Usuario:</b>
                </label>
                <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="datesUsersList.length < 1"
                        id="assignUser" v-model="assignUserModal" name="assignUser">
                  <option :value="null" class="px-3 py-2 text-sm">
                    -- Selecciona un asesor --
                  </option>
                  <option v-for="(usr) in datesUsersList"
                          v-bind:key="usr.id" :value="usr"
                          class="px-3 py-2 text-sm">
                    {{ usr.name }}
                  </option>
                </select>
              </div>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showAssignOneModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="assignOneDates()">Asignar
                </button>
              </div>
            </template>
          </ModalBox>
          <!--elimnar leads-->
          <ModalBox :showing="showDeleteModal" @close="showDeleteModal = false">
            <div class="flex">
              <p class="text-black"><b>Eliminar citas</b></p>
            </div>
            <div class="flex">
              <p class="text-black">
                Deseas eliminar {{ selectedDates.length }} citas seleccionadas?
              </p>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showDeleteModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="deleteDates()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--archivar leads-->
          <ModalBox :showing="showArchiveModal" @close="showArchiveModal = false">
            <div class="flex">
              <p class="text-black"><b>Archivar citas</b></p>
            </div>
            <div class="flex">
              <p class="text-black">
                Deseas archivar {{ selectedDates.length }} citas seleccionadas?
              </p>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showArchiveModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="archiveDates()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--crear lead-->
          <ModalBox :showing="showCreateModal" @close="showCreateModal = false">
            <div class="flex">
              <p class="text-black"><b>Crear lead</b></p>
            </div>
            <div class="justify-start p-4 w-full">
              <template v-if="isLoadingCreateLead">
                <p class="text-center">Cargando...</p>
              </template>
              <template v-else>
                <p class="my-1">formulario:</p>
                <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                <select v-model="newLead.form" @change="setFields()"
                        class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                  <option disabled :value="null">-- Seleccione un formulario --</option>
                  <option v-for="(form) in forms" v-bind:key="form.id" :value="form">
                    {{ form.name }}
                  </option>
                </select>
                <template v-if="newLead.form">
                  <div class="flex">
                    <div class="w-1/2 p-2"><b>Nombre:</b></div>
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.name" type="text">
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/2 p-2"><b>Email:</b></div>
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.email" type="text">
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/2 p-2"><b>Teléfono:</b></div>
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.phone" type="tel">
                    </div>
                  </div>
                  <template v-if="newLead.fields.length>0">
                    <div class="flex" v-for="(field, key) in newLead.fields" v-bind:key="key">
                      <div class="w-1/2 p-2">
                        <b>{{ field.name ? field.name : field.label }}:</b>
                      </div>
                      <div class="w-1/2">
                        <template v-if="field.type === 'TEXT' ||field.type === null
                        ||field.type === undefined">
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data" type="text">
                        </template>
                        <template v-if="field.type === 'EMAIL'">
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data" type="email">
                        </template>
                        <template v-if="field.type === 'PHONE'">
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data" type="tel">
                        </template>
                        <template v-if="field.type === 'SELECT'">
                        <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data">
                            <option :value="null">--Seleccione una opción--</option>
                            <option v-for=" (option, ky) in field.options"
                                    :value="option" v-bind:key="ky">
                              {{ option }}
                            </option>
                          </select>
                        </template>
                      </div>
                    </div>
                  </template>
                  <template v-if="showCreateFieldInterface">
                    <div class="form-group flex my-1">
                      <div class="w-1/2 flex flex-col">
                        <!--eslint-disable-next-line-->
                        <label for="fieldTitle"><b>Título:</b></label>
                        <input type="text" v-model="createNewField.name" id="createFieldTitle"
                               class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 sm:text-sm mx-1">
                      </div>
                      <div class="w-1/2 flex flex-col">
                        <!--eslint-disable-next-line-->
                        <label for="fieldData"><b>Valor:</b></label>
                        <input type="text" v-model="createNewField.data" id="createFieldData"
                               class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 sm:text-sm mx-1">
                      </div>
                    </div>
                    <div class="flex my-1">
                      <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                              @click="showCreateFieldInterface = false;
                                    createNewField = {name:null, label: null, data:null,};"
                              type="button">Cancelar
                      </button>
                      <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                              @click="addNewField()">Aceptar
                      </button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="flex justify-end my-2">
                      <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                              @click="showCreateFieldInterface = true;">Agregar campo
                      </button>
                    </div>
                  </template>
                  <div class="w-full flex">
                    <div class="m-1">
                      <Toggle v-model="newLead.addProduct"/>
                    </div>
                    <p class="ml-1">Agregar producto?</p>
                  </div>
                  <template v-if="newLead.addProduct">
                    <div>
                      <!--eslint-disable-next-line-->
                      <label class="w-full text-left" for="newLeadCategory">
                        <b>Categoría:</b>
                      </label>
                      <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                              :disabled="companyCategories.length < 1"
                              id="newLeadCategory" v-model="newLead.category"
                              name="leadCategory">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona categoría --
                        </option>
                        <option v-for="(category) in companyCategories"
                                v-bind:key="category.id" :value="category"
                                class="px-3 py-2 text-sm">
                          {{ category.name }}
                        </option>
                      </select>
                    </div>
                    <div>
                      <template v-if="newLead.category">
                        <!--eslint-disable-next-line-->
                        <label class="w-full text-left" for="leadProduct">
                          <b>Producto:</b>
                        </label>
                        <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="newLead.category.products.length < 1"
                                id="leadProduct" v-model="newLead.product" name="leadProduct">
                          <option :value="null" class="px-3 py-2 text-sm">
                            -- Selecciona uno --
                          </option>
                          <option v-for="(prod) in newLead.category.products"
                                  v-bind:key="prod.id" :value="prod"
                                  class="px-3 py-2 text-sm">
                            {{ prod.name }}
                          </option>
                        </select>
                      </template>
                    </div>
                  </template>
                  <div class="w-full flex">
                    <div class="m-1">
                      <Toggle v-model="newLead.addUser"/>
                    </div>
                    <p class="ml-1">Asignar usuario?</p>
                  </div>
                  <template v-if="newLead.addUser">
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="leadUser">
                      <b>Usuario:</b>
                    </label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="datesUsersList.length < 1"
                            id="leadUser" v-model="newLead.user" name="leadUser">
                      <option :value="null" class="px-3 py-2 text-sm">
                        -- Selecciona un asesor --
                      </option>
                      <option v-for="(usr) in datesUsersList"
                              v-bind:key="usr.id" :value="usr"
                              class="px-3 py-2 text-sm">
                        {{ usr.name }}
                      </option>
                    </select>
                  </template>
                </template>
              </template>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showCreateModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="createLead()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--loading-->
          <LoadingBox :showing="isLoadingContact">
            <div class="flex flex-col">
              <img alt="loading" src="@/assets/loading.gif" class="w-20 mx-3">
              <p class="text-center text-white">
                {{ activeUser.name }},
              </p>
              <p class="text-center text-white">
                vamos por más...
              </p>
            </div>
          </LoadingBox>
        </div>
        <!--active lead-->
        <div class="lg:w-3/5 w-full flex-grow h-max flex flex-col  bg-white drop-shadow-2xl
        shadow-2xl" v-bind:class="{ 'flex' : activeContact, 'hidden' : !activeContact }">
          <template v-if="isLoadingContact">
            <p class="text-center my-10">
              {{ activeUser.name }}, vamos por más...
            </p>
          </template>
          <template v-else>
            <template v-if="activeContact">
              <!--top bar -->
              <div class="w-full  flex flex-col bg-gray-100">
                <div class="w-full hidden md:flex ">
                  <div class=" w-full md:w-5/12 flex ">
                    <div class="p-1 content-center relative">
                      <div class="rounded-full absolute left-1 top-6 text-white p-2
                      text-xs h-2 w-2" v-if="activeContact.status"
                           :style="{ backgroundColor: activeContact.status.color}">
                      </div>
                      <img class="w-12 h-12 my-6 rounded-full self-center border-2 border-gray-300"
                           src="@/assets/default-profile.svg"
                           alt="user">

                    </div>
                    <div class="p-1 flex flex-col">
                      <div class="flex text-sm">
                        <!--eslint-disable-next-line-->
                        <input v-if="editName" class="border-1 p-1"
                               v-model="activeContact.patient_name" type="text">
                        <b class="p-1 " v-else>{{ activeContact.patient_name }}</b>
                        <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                       @click="editName = !editName" aria-hidden="true"/>
                      </div>
                      <div class="flex my-1 mt-0 content-center">
                        <img class="w-8 h-8 rounded-lg" :src="source" alt="user">
                        <div class="flex-grow ">
                          <div class="text-md m-2 my-1 self-center">
                            {{ activeContact.connection_name }}
                          </div>
                          <div class="text-md m-2 my-1 self-center">
                            {{ activeContact.medical_center_name }} -
                            {{ activeContact.medical_center_city }}
                          </div>
                        </div>
                      </div>
                      <div class="flex text-sm justify-between m-1">
                        <div>{{ activeContact.date }}</div>
                        <div class="mx-1"><b>Inicio:</b> {{ activeContact.start_time }}</div>
                        <div class="mx-1"><b>Fin:</b> {{ activeContact.end_time }}</div>
                      </div>

                    </div>
                  </div>
                  <div class="flex flex-col w-4/12 justify-start">
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user" v-model="activeContact.user_id" name="user">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un asesor --
                        </option>
                        <option v-for="(user) in datesUsersList"
                                v-bind:key="user.id" :value="user.id"
                                class="px-3 py-2 text-sm">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <NewspaperIcon class="m-1 h-7 w-7 " aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="form" v-model="activeContact.form_id" name="form">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un formulario --
                        </option>
                        <option v-for="(form) in forms"
                                v-bind:key="form.id" :value="form.id"
                                class="px-3 py-2 text-sm">
                          {{ form.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="flex w-3/12 justify-end">
                    <button class="bg-green-720 h-10 flex rounded-lg  m-1 my-2 p-2 text-white"
                            @click="save()">
                      <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                      Guardar
                    </button>
                    <!--eslint-disable-next-line-->
                    <div class="p-2 hover:bg-gray-200 cursor-pointer" @click="closeDate()">
                      <XIcon class=" h-7 w-7  mr-1" aria-hidden="true"/>
                    </div>
                  </div>
                </div>
                <div class="w-full flex flex-col md:hidden">
                  <div class=" w-full flex ">
                    <div class="flex w-7/12">
                      <div class="p-1 flex flex-col flex-grow ml-2 sm:m-0">
                        <div class="flex text-sm">
                          <!--eslint-disable-next-line-->
                          <input v-if="editName" class="border-1 p-1"
                                 v-model="activeContact.patient_name" type="text">
                          <b class="p-1 " v-else>{{ activeContact.patient_name }}</b>
                          <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                         @click="editName = !editName" aria-hidden="true"/>
                        </div>
                        <div class="flex my-1 mt-0 content-center">
                          <img class="w-6 h-6 rounded-lg" :src="source" alt="user">
                          <div class="flex-grow ">
                            <div class="text-sm m-2 my-1 self-center">
                              {{ activeContact.connection_name }}
                            </div>
                          </div>
                        </div>
                        <div class="flex-col text-xs m-1">
                          <div>{{ activeContact.date }}</div>
                          <div><b>Inicio:</b> {{ activeContact.start_time }}</div>
                          <div><b>Fin:</b> {{ activeContact.end_time }}</div>
                        </div>

                        <div class="flex text-sm m-1"
                             v-if="activeContact.referral_id">
                          <b>Anuncio WhatsApp: </b>{{ activeContact.referral_headline }}
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeContact.ad_name">
                          <b>Anuncio leadgen: </b>{{ activeContact.ad_name }}
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeContact.campaign_name">
                          <b>Campaña leadgen: </b>{{ activeContact.campaign_name }}
                        </div>

                        <div class="flex text-sm m-1"
                             v-if="activeContact.campaign_internal_name">
                          <b>Campaña:</b> {{ activeContact.campaign_internal_name }}
                        </div>
                        <div class="flex text-sm m-1"
                             v-if="activeContact.campaign_id">
                          <b>Campaña facebook:</b> {{ activeContact.campaign_id }}
                        </div>
                      </div>
                    </div>
                    <div class="flex w-5/12 justify-end">
                      <button class="bg-green-720 h-10 flex rounded-lg  m-1 my-2 p-2 text-white"
                              @click="save()">
                        <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                        Guardar
                      </button>
                      <!--eslint-disable-next-line-->
                      <div class="p-2 hover:bg-gray-200 cursor-pointer" @click="closeDate()">
                        <XIcon class=" h-7 w-7 mr-1" aria-hidden="true"/>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full justify-start">
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-8
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user1" v-model="activeContact.user_id"
                              name="user">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un asesor --
                        </option>
                        <option v-for="(user) in datesUsersList"
                                v-bind:key="user.id" :value="user.id"
                                class="px-3 py-2 text-sm">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                    <div class="flex px-2">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <NewspaperIcon class="m-1 h-7 w-7 " aria-hidden="true"/>
                      </div>
                      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-8
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                              id="form1" v-model="activeContact.form_id" name="form">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un formulario --
                        </option>
                        <option v-for="(form) in forms"
                                v-bind:key="form.id" :value="form.id"
                                class="px-3 py-2 text-sm">
                          {{ form.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!--section selector-->
              <div class="w-full flex bg-white px-4 overflow-x-auto py-2 h-24">
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('info')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'info' }"
                     @keydown="selectTab('info')">
                  Datos
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('chats')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'chats' }"
                     @keydown="selectTab('chats')">
                  Chats
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900 text-tabs"
                     @click="selectTab('leads')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'leads' }"
                     @keydown="selectTab('leads')">
                  Leads
                </div>
              </div>
              <!--lead content-->
              <div class="w-full overflow-y-auto bg-scroll bg-contain h-full"
                   id="messages_container">
                <template v-if="selectedTab === 'info'">
                  <div class="p-4 pt-1">
                    <!--datos-->
                    <div class="md:flex bg-white">
                      <div class="md:w-6/12 w-full p-1">
                        <h6 class="text-left my-1 title-header"><b>DATOS PERSONALES</b></h6>
                        <p class="m-0 text-sm" v-if="activeContact.patient_email"><b>Email: </b>
                          {{ activeContact.patient_email }}</p>
                        <p class="m-0 text-sm" v-if="activeContact.patient_phone"><b>Teléfono: </b>
                          {{ activeContact.patient_phone }}</p>
                        <p class="m-0 text-sm" v-if="activeContact.patient_document">
                          <b>Identificación: </b>
                          {{ activeContact.patient_document }}</p>
                        <p class="m-0 text-sm" v-if="activeContact.medical_center_name">
                          <b>Consultorio: </b>
                          {{ activeContact.medical_center_name }}</p>
                        <p class="m-0 text-sm" v-if="activeContact.medical_center_city">
                          <b>Ciudad: </b>
                          {{ activeContact.medical_center_city }}</p>
                      </div>
                      <div class="md:w-6/12 w-full p-1">
                        <h6 class="text-left my-1 font-semibold title-header">NOTAS</h6>
                        <template v-if="isLoadingConversationNotes">
                          Cargando Notas...
                        </template>
                        <template v-else>
                          <template v-for="(note) in notes" v-bind:key="note.id">
                            <div class="p-4 flex flex-col rounded-lg border-2 border-black-600
                            shadow-xl shadow-black my-2">
                              <p class="font-semibold">{{ note.data }}</p>
                              <p>{{ note.user?.name }}</p>
                              <p>{{ note.created_at?.formated_date }}</p>
                            </div>
                          </template>
                        </template>
                        <template v-if="showNoteInterface">
                          <div class="form-group">
                            <!--eslint-disable-next-line-->
                            <label for="newNote"><b>Nueva Nota</b></label>
                            <textarea class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md" v-model="note"
                                      name="newNote" id="newNote">
                            </textarea>
                          </div>
                          <div class="flex">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                    @click="showNoteInterface = false; note = '';"
                                    type="button">Cancelar
                            </button>
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="createNote()">Aceptar
                            </button>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex justify-end">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="showNoteInterface = true;">Crear nota
                            </button>
                          </div>
                        </template>
                      </div>
                    </div>
                    <!--buttons abierto cerrrado-->
                    <div class="flex">
                      <div class="w-full py-2 px-1">
                        <button class=" rounded-lg p-2 border-2 w-full bg-sky-100"
                                @click="setStatusType('active')"
                                v-bind:class="{ 'bg-sky-400' : selectedStatusType === 'active',
                              'border-sky-400' : selectedStatusType === 'active',
                              'text-white' : selectedStatusType === 'active'}">
                          Estados en Gestión
                        </button>
                      </div>
                      <div class="w-full py-2">
                        <button class=" rounded-lg p-2 border-2 w-full bg-sky-100"
                                @click="setStatusType('closed')"
                                v-bind:class="{ 'bg-sky-400' : selectedStatusType === 'closed',
                                'border-sky-400' : selectedStatusType === 'closed',
                                'text-white' : selectedStatusType === 'closed'}">
                          Estados Cerrados
                        </button>
                      </div>
                    </div>

                  </div>
                </template>
                <template v-if="selectedTab === 'chats'">
                  <div class="p-4">
                    <template v-if="isLoadingAppointmentConversations">Cargando...</template>
                    <template v-else>
                      <template v-if="appointmentConversations.length>0">
                        <template v-for="(conversation) in appointmentConversations"
                                  v-bind:key="conversation.id">
                          <div @keyDown="goToConversation(conversation.conversation?.uuid)"
                               @click="goToConversation(conversation.conversation?.uuid)"
                               class="p-4 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-2 bg-gray-100 cursor-pointer"
                               v-if="conversation.conversation">
                            <div class="text-md px-2">
                              <img class="w-14 h-14 my-2  rounded-full flex"
                                   src="@/assets/default-profile.svg"
                                   alt="user">
                            </div>
                            <div class="flex flex-col">
                              <p class="text-md">
                                <b>{{ conversation.conversation?.name }}</b>
                              </p>
                              <p class="text-md" v-if="conversation.conversation?.user_id">
                                <b>Asignado a:</b> {{ conversation.conversation?.user?.name }}
                              </p>
                              <p class="text-sm">
                                <b>ultima interacción:</b>
                                {{ conversation.conversation?.formatted_last_interaction }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <h6 class="text-center my-10">Cita no tiene conversaciones
                        </h6></template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'leads'">
                  <template v-if="isLoadingAppointmentLeads">
                    <p class="text-center">Cargando leads...</p>
                  </template>
                  <template v-else>
                    <div class="flex flex-col p-4">
                      <template v-if="appointmentLeads.length < 1">
                        <p class="text-center my-6">Cita no tiene leads</p>
                      </template>
                      <template v-else>
                        <template v-for="(lead) in appointmentLeads" v-bind:key="lead.id">
                          <div class="p-4 flex flex-col rounded-lg border-2 border-gray-300
                            shadow-md shadow-black my-2 cursor-pointer bg-gray-100"
                               @click="goToLead(lead.lead.uuid);" v-if="lead.lead"
                               @keydown="goToLead(lead.lead.uuid);">
                            <p class="font-semibold">{{ lead.lead?.name }}</p>
                            <div class="flex" v-if="lead.lead?.status">
                              <p><b>Estado: </b></p>
                              <div class="rounded-full text-white p-2 text-xs m-1"
                                   :style="{backgroundColor: lead.lead?.status.color}">
                              </div>
                              <p>{{ lead.lead?.status?.name }}</p>
                            </div>
                            <p class="text-small"><b>Fecha de creación: </b>
                              {{ lead.lead?.created_at?.date }}
                            </p>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </template>
                <template v-if="selectedTab === 'contact'">
                  <div class="flex flex-col p-4">
                    <template v-if="activeContact.contact_id">
                      <div class="p-1">
                        <p><b>Nombre:</b> {{ activeContact.contact?.name }}</p>
                      </div>
                      <div class="p-1 flex" v-if="activeContact.contact?.email">
                        <p><b>Email:</b> {{ activeContact.contact?.email }}</p>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeContact.contact?.email" title="Enviar email"
                           :href="'mailto:'+activeContact.contact?.email">
                          <MailIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                        </a>
                      </div>
                      <div class="p-1 flex" v-if="activeContact.contact?.secondary_email">
                        <p><b>Email Secundario:</b> {{ activeContact.contact?.secondary_email }}</p>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeContact.contact?.secondary_email" title="Enviar email"
                           :href="'mailto:'+activeContact.contact?.secondary_email">
                          <MailIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                        </a>
                      </div>
                      <div class="p-1 flex">
                        <p><b>Teléfono: </b>{{ activeContact.contact?.phone }}</p>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeContact.contact?.phone" title="Llamar"
                           :href="'tel:'+activeContact.contact?.phone">
                          <PhoneOutgoingIcon class="mx-2 h-6 w-6" aria-hidden="true"/>
                        </a>
                        <!--eslint-disable-next-line-->
                        <a v-if="activeContact.contact?.phone" title="Enviar mensaje"
                           :href="'https://api.whatsapp.com/send?phone='+activeContact.contact?.phone?.replace('+', '')"
                           target="_blank">
                          <img class="w-6 h-6 mx-2" src="@/assets/whatsapp.png"
                               alt="whatsapp">
                        </a>
                      </div>
                      <div class="p-1" v-if="activeContact.contact?.document">
                        <b>Documento:</b> {{ activeContact.contact?.document }}
                      </div>
                    </template>
                    <template v-else>
                      <p class="text-center my-10">No tiene contacto</p>
                    </template>
                  </div>
                  <div class="flex flex-col px-4 overflow-y-auto h-fit"
                       v-if="activeContact.contact_id">
                    <template v-if="activeContact.contact?.leads.length>0">
                      <h6 class="text-left my-1 font-semibold title-header">Leads</h6>
                      <template v-for="(ld) in activeContact.contact?.leads"
                                v-bind:key="ld.id">
                        <div class="p-4 flex rounded-lg border-2 border-gray-300
                            shadow-md shadow-black my-2 cursor-pointer
                            hover:bg-gray-200"
                             v-bind:class="{
                                'bg-stone-400': ld.uuid === activeContact?.uuid,
                                'bg-gray-100': ld.uuid !== activeContact?.uuid,
                                 }"
                        >
                          <div class="flex-col flex-auto "
                               :title="ld.uuid === activeContact?.uuid ?
                             'Este es el lead abierto':'Has click para abrir este lead'"
                               @click="getDate(ld.uuid);"
                               @keydown="getDate(ld.uuid);">
                            <div class="flex">
                              <p class="font-semibold mx-1">{{ ld.name }}</p>
                              <p v-if="ld.uuid === activeContact?.uuid" class="text-orange-500 ">
                                (lead activo)
                              </p>
                            </div>
                            <div class="flex">
                              <img class="w-6 h-6 rounded-lg mx-1" :src="sourceLead(ld)" alt="user">
                              <p>{{ ld.form_name }}</p>
                            </div>
                            <div class="flex" v-if="ld.status">
                              <p class="mx-1"><b>Estado: </b></p>
                              <div class="rounded-full text-white p-2 text-xs m-1"
                                   :style="{backgroundColor: ld.status?.color}">
                              </div>
                              <p>{{ ld.status?.name }}</p>
                            </div>
                            <div class="flex" v-if="ld.channel_name">
                              <p class="mx-1"><b>Canal: </b></p>
                              <p>{{ ld.channel_name }}</p>
                            </div>
                            <p class="text-small mx-1"><b>Fecha de creación: </b>
                              {{ ld.created_at?.date }}
                            </p>
                          </div>
                          <div class=" flex-none  p-2 hover:bg-gray-300 cursor-pointer"
                               v-if="ld.uuid !== activeContact?.uuid"
                               title="Has click para eliminar este lead"
                               @click="openDeleteContactLeadModal(ld)"
                               @keydown="openDeleteContactLeadModal(ld);">
                            <TrashIcon class="w-7 mr-1 text-red-600" aria-hidden="true"/>
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-if="activeContact.contact?.orders.length>0">
                      <h6 class="text-left my-1 font-semibold title-header">Órdenes</h6>
                      <div v-for="(order) in activeContact.contact?.orders"
                           v-bind:key="order.id"
                           class="p-2 flex flex-col rounded-lg border-2 border-black-600
                               shadow-xl shadow-black my-3 bg-gray-100">
                        <p class="text-center text-lg my-1"><b>Orden No.{{ order.uuid }}</b></p>
                        <div class="bg-white p-2 rounded-lg flex">
                          <div class="w-1/2">
                            <p class="text-center"><b>Método de pago</b></p>
                            <p class="text-center">{{ getPayment(order.payment_method) }}</p>
                          </div>
                          <div class="w-1/2">
                            <p class="text-center"><b>Fecha de compra</b></p>
                            <p class="text-center">{{ order.created_at.formated_date }}</p>
                          </div>
                          <div class="w-1/2">
                            <p class="text-center"><b>Total</b></p>
                            <p class="text-center">$ {{ order.total }}</p>
                          </div>
                        </div>
                        <p class="text-center text-lg my-1"><b>Carrito de compras</b></p>
                        <div class="p-2 w-full overflow-x-auto shadow-md sm:rounded-lg
                            bg-white">
                          <table class="table-auto w-full border border-gray-400">
                            <thead>
                            <tr class="bg-grey-200 border border-gray-200">
                              <th class="text-center border border-gray-200">
                                <b>Producto</b>
                              </th>
                              <th class="text-center border border-gray-200">
                                <b>Cantidad</b></th>
                              <th class="text-center border border-gray-200"><b>Precio</b>
                              </th>
                              <th class="text-center border border-gray-200"><b>Total</b></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(item, key) in order.cart" v-bind:key="key">
                              <tr v-if="item.product" class="border border-gray-200">
                                <td class="flex justify-center border border-gray-200
                                      content-center">
                                  <img class="w-12 h-12 my-2 rounded-full self-center"
                                       :src="'https://leadbox.ec/storage/'+item.product?.image"
                                       :alt="item.product?.name">
                                  <div class="flex flex-col mx-2 content-center">
                                    <p>{{ item.product?.name }} x {{ item.quantity }}</p>
                                    <div v-if="item.note">
                                      <p><b>Observación:</b> {{ item.note }}</p>
                                    </div>
                                  </div>
                                </td>
                                <td class="text-center border border-gray-200">
                                  {{ item.quantity }}
                                </td>
                                <td class="text-center border border-gray-200">
                                  {{ item.price / item.quantity }}
                                </td>
                                <td class="text-center border border-gray-200">
                                  {{ item.price }}
                                </td>
                              </tr>
                            </template>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </template>
                  </div>

                </template>
                <template v-if="selectedTab === 'events'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingLeadEvents">Cargando...</template>
                    <template v-else>
                      <template v-if="leadEvents.length>0">
                        <div v-for="(event) in leadEvents"
                             v-bind:key="event.id">
                          <div class="p-2 mx-4 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-2 bg-grey-100">
                            <div class="rounded-full text-white self-center w-5 h-5 mx-4"
                                 :style="{ 'background-color' : event.color }">
                            </div>
                            <div class="flex-col m-2">
                              <p class="text-md">
                                {{ event.title }}</p>
                              <p>Fecha del evento: <b>
                                {{ event.start_date }}
                              </b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <h6 class="text-center my-10">No hay eventos</h6>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'history'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingAppointmentHistory">Cargando...</template>
                    <template v-else>
                      <template v-if="appointmentHistories.length>0">
                        <div v-for="(history) in appointmentHistories"
                             v-bind:key="history.id"
                             class="p-2 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                          <div class="rounded-full text-white self-center p-4"
                               :style="{ 'background-color' : history.status?.color }">
                          </div>
                          <div class="flex flex-col m-4 my-2">

                            <p class="text-md">
                              {{ history.status?.name }}
                              {{ history.user ? ' - ' + history.user.name : '' }}
                            </p>
                            <p class="text-sm">{{ history.created_at.formated_date }}</p>
                            <p class="text-sm" v-if="history.is_transfer && history.form">
                              Fue transferido desde el formulario
                              <b>{{ history.form?.name }}</b>
                            </p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center text-center">No hay Historial</p>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'integrations'">
                  <div class="p-4">
                    <template v-if="activeContact.integrations">
                      <div v-for="(integration, type) in activeContact.integrations"
                           v-bind:key="type">
                        <div class="mb-5" v-if="type === 'vtiger' || type === 'teojama'">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.id">
                            <strong>ID:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.user_id">
                            <strong>ID de usuario:</strong> {{ integration.user_id }}
                          </div>
                          <div v-if="integration.user_name">
                            <strong>Nombre de usuario:</strong> {{ integration.user_name }}
                          </div>
                          <div v-if="integration.user_id && type === 'vtiger'
                            && !integration.user_name">
                            <strong>Usuario:</strong> {{
                              filterVtigerUser(integration.user_id, type)
                            }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'oracle-sales' || type === 'pilot' ">
                          <div class="mb-3" v-if="type === 'oracle-sales'">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div class="mb-3" v-if="type === 'pilot'">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.id">
                            <strong>ID:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.status">
                            <strong>Estado:</strong> {{ integration.status }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'mailer-lite' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.svg'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.id">
                            <strong>ID:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.status">
                            <strong>Estado:</strong> {{ integration.status }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'nova-scoring' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.response">
                            <strong>Mensaje:</strong> {{ integration.response }}
                          </div>
                          <div v-if="integration.requerimiento">
                            <strong>Requerimiento:</strong> {{ integration.requerimiento }}
                          </div>
                          <div v-if="integration.calificacion">
                            <strong>Calificación:</strong> {{ integration.calificacion }}
                          </div>
                          <div v-if="integration.respuesta">
                            <strong>Respuesta:</strong> {{ integration.respuesta }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                        <div class="mb-5" v-if="type === 'gxm' ">
                          <div class="mb-3">
                            <img
                              :src="'https://leadbox.ec/backend/images/integrations/'+type+'.png'"
                              style="max-width: 100%; height: 20px" alt="{{type}}">
                          </div>
                          <div v-if="integration.id">
                            <strong>ID respuesta:</strong> {{ integration.id }}
                          </div>
                          <div v-if="integration.data">
                            <strong>Datos recibidos:</strong> {{ integration.data }}
                          </div>
                          <div v-if="integration.payload">
                            <strong>Datos enviados:</strong> {{ integration.payload }}
                          </div>
                          <div v-if="integration.response">
                            <strong>Respuesta:</strong> {{ integration.response }}
                          </div>
                          <div v-if="!integration.response">En cola de envío a {{ type }}</div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <p class="text-center">Aquí tendra un estado del lead con respecto a todas
                        las integraciones instaladas</p>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'campaign'">
                  <div class="flex flex-col p-4">
                    <template v-if="activeContact.referral_id">
                      <p><b>ID:</b> {{ activeContact.referral_id }}</p>
                      <p><b>Tipo:</b> {{ activeContact.referral_type }}</p>
                      <p><b>Url:</b> <a :href="activeContact.referral_url" target="_blank"
                                        class="text-sky-500 underline">
                        {{ activeContact.referral_url }}</a></p>
                      <p> {{ activeContact.referral_headline }}</p>
                      <p v-html="activeContact.referral_body"></p>
                    </template>
                    <template
                      v-if="activeContact.ad_id !== null || activeContact.ad_name !== null ||
                      activeContact.campaign_id !== null || activeContact.campaign_name !== null ||
                      activeContact.platform !== null || activeContact.post !== null">
                      <p><b>Id anuncio:</b> {{ activeContact.ad_id }}</p>
                      <p><b>Nombre anuncio:</b> {{ activeContact.ad_name }}</p>
                      <p><b>Id campaña:</b> {{ activeContact.campaign_id }}</p>
                      <p><b>Nombre campaña:</b> {{ activeContact.campaign_name }}</p>
                      <p><b>Plataforma:</b> {{ activeContact.platform }}</p>
                      <p v-if="activeContact.post !== null"><b>Post:</b>
                        <a :href="activeContact.post"
                           target="_blank"
                           class="text-sky-500 underline">
                          {{ activeContact.post }}</a></p>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'documents'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingLeadDocuments">Cargando...</template>
                    <template v-else>
                      <template v-if="leadDocuments.length>0">
                        <div v-for="(document) in leadDocuments"
                             v-bind:key="document.id"
                             class="p-3 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                          <div class="flex flex-col w-full">
                            <p class="text-lg text-center my-1">
                              <b>{{ document.original_name }}</b>
                            </p>
                            <div class="flex text-sm my-1">
                              <div class="w-full md:w-6/12 text-center">
                                <p class=""><b>Creado por:</b></p>
                                <p class="">{{
                                    document.user
                                      ? document.user.name : 'Usuario eliminado'
                                  }}</p>
                              </div>
                              <div class="w-full md:w-6/12 text-center">
                                <p class=""><b>Fecha de creación:</b></p>
                                <p class="">{{ document.created_at.formated_date }}</p>
                              </div>
                            </div>
                            <div class="flex">
                              <button class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-red-600 hover:bg-red-700 m-1" @click="openEmailModal(document)">
                                Compartir
                              </button>
                              <a class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-sky-600 hover:bg-sky-700 m-1" target="_blank"
                                 :href="'https://leadbox.ec'+document.path">Descargar</a>
                              <button class="group relative w-full flex justify-center p-2 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-yellow-600 hover:bg-yellow-700 m-1"
                                      v-if="activeUser.is_superadmin ||
                                      activeUser.is_supervisor || activeUser.is_admin"
                                      @click="openDeleteFileModal(document)">Eliminar
                              </button>
                            </div>

                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center my-10">No hay documentos</p>
                      </template>
                    </template>
                    <div class="w-full flex justify-center p-2">
                      <button class="group relative w-1/3 flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600 mx-auto" type="button"
                              @click="showDocumentModal = true;">Subir documento
                      </button>
                    </div>
                  </div>
                </template>
                <AppointmentStatusModal :showModal="showModal" @close="showModal = false"
                             :status="modalStatus" @close-update="closeModalUpdate"/>
                <!--email modal-->
                <ModalBox :showing="showEmailModal" @close="showEmailModal = false">
                  <div class="flex">
                    <p class="text-black">
                      <b>Enviar documento {{ selectedDocument.original_name }} a:</b></p>
                  </div>
                  <div>
                    <div class="flex flex-col">
                      <!--eslint-disable-next-line-->
                      <label for="title"><b>Email:</b></label>
                      <input type="email" v-model="documentReceptor" id="title"
                             class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                    </div>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showEmailModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendEmail()">Aceptar
                    </button>
                  </div>
                </ModalBox>
                <!--document modal-->
                <ModalBox :showing="showDocumentModal" @close="showDocumentModal = false">
                  <div class="flex">
                    <p class="text-black">
                      <b>Subir un documento a este lead:</b></p>
                  </div>
                  <div>
                    <!--eslint-disable-next-line-->
                    <input ref="fileInput" type="file" id="file-input"
                           @change="onInputChange" class="my-1 appearance-none rounded-none
                           relative block w-full p-2 border border-gray-300 placeholder-gray-500
                           text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm"/>
                  </div>
                  <div class="flex w-full justify-center flex-wrap" v-if="files.length > 0">
                    <template v-for="(file, key) in files" v-bind:key="key">
                      <div class="flex flex-col w-1/2 justify-center ">
                        <img class="m-1" src="@/assets/document.png" :alt="file.file.name"
                             :title="file.file.name"/>
                        <button class="m-1 text-white bg-red-500 rounded-md"
                                @click="removeFile(file)">
                          Eliminar
                        </button>
                      </div>
                    </template>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showDocumentModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendFiles()">Aceptar
                    </button>
                  </div>
                </ModalBox>
                <!--delete file modal-->
                <ModalBox :showing="showDeleteFileModal" @close="showDeleteFileModal = false">
                  <div class="flex">
                    <p class="text-black">
                      <b>¿Quieres eliminar el documento {{ selectedDocument.original_name }}?</b>
                    </p>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showDeleteFileModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="deleteFile()">Aceptar
                    </button>
                  </div>
                </ModalBox>

                <!--delete contact lead modal-->
                <ModalBox :showing="showDeleteContactLeadModal"
                          @close="showDeleteContactLeadModal = false">
                  <div class="flex flex-col p-4">
                    <p class="text-black">
                      <b>¿Estás Seguro?</b>
                    </p>
                    <p class="text-black">
                      ¿Quiéres eliminar el lead <b>{{ selectedContactLead?.name }}</b>,
                      perteneciende al formulario <b>{{ selectedContactLead.form_name }}</b>,
                      proveniente de <b>{{ selectedContactLead.form_provider }}?</b>
                    </p>
                    <p class="text-red-600">Esta acción no se puede revertir</p>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showDeleteContactLeadModal = false"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="deleteContactLead()">Aceptar
                    </button>
                  </div>
                </ModalBox>

              </div>
              <!--bottom actions lead-->
              <div class="flex-col p-1">
                <div class="flex justify-center">
                  <div class="flex-col w-full md:w-5/12 p-3">
                    <p class="text-center"><b>Canal:</b></p>
                    <!-- eslint-disable -->
                    <select v-model="activeContact.channel_id"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                      <option disabled :value="null">-- Seleccione un canal --</option>
                      <option v-for="(channel) in channels" v-bind:key="channel.id"
                              :value="channel.id">
                        {{ channel.name }}
                      </option>
                    </select>
                  </div>
<!--                  <div class="flex-col w-full md:w-5/12 p-3">-->
<!--                    <p class="text-center"><b>Centro Médico:</b></p>-->
<!--                    <select v-model="activeContact.location_id"-->
<!--                            class="my-2 appearance-none rounded-none relative block w-full-->
<!--                                 px-3 py-2 border border-gray-300 placeholder-gray-500-->
<!--                                 text-gray-900 rounded-b-md focus:outline-none-->
<!--                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10-->
<!--                                  sm:text-sm">-->
<!--      <option disabled :value="null">&#45;&#45; Seleccione una ubicacion &#45;&#45;</option>-->
<!--                      <option v-for="(location) in locations" v-bind:key="location.id"-->
<!--                              :value="location.id">-->
<!--                        {{ location.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
                </div>

                <div class="flex w-full justify-center ">
                  <!-- eslint-disable-next-line-->
                  <a :href="'mailto:'+activeContact.email"
                     :title="'Enviar email a '+activeContact.email"
                     v-if="activeContact.email">
                    <MailIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-email" aria-hidden="true"/>
                  </a>
                  <!-- eslint-disable-next-line-->
                  <a :title="'Llamar al 0'+activeContact.phone_national_number"
                     :href="'tel:0'+activeContact.phone_national_number"
                     v-if="activeContact.phone_national_number">
                    <PhoneIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-phone" aria-hidden="true"/>
                  </a>
                  <!-- eslint-disable-next-line-->
                  <a
                    :href="'https://api.whatsapp.com/send?phone='+activeContact.phone.replace('+', '')"
                    v-if="activeContact.phone" target="_blank"
                    :title="'Enviar mensaje de whatsapp a 0'+activeContact.phone_national_number">
                    <ChatIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-chat" aria-hidden="true"/>
                  </a>
                  <!-- eslint-disable-next-line-->
                  <a :href="'sip:0'+activeContact.phone_national_number"
                     v-if="activeContact.phone && activeCompany.uuid
                     === 'b7a26e43-0168-4e20-a22b-5a8186e85a98'"
                     :title="'llamar por Issabel a 0'+activeContact.phone_national_number">
                    <PhoneIcon class="mr-2 h-10 lg:h-12 w-10 lg:w-12 hover:bg-teal-900 p-2 md:p-3
                  rounded-full text-white bg-phone-issabel" aria-hidden="true"/>
                  </a>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="mt-32 text-center">Selecciona un contacto</p>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { ref } from 'vue';
import LitepieDatepicker from 'litepie-datepicker';
import Toggle from '@vueform/toggle';
import {
  UserIcon, ArchiveIcon, CollectionIcon, SearchIcon, XIcon, TrashIcon, UsersIcon,
  PencilAltIcon, RefreshIcon, LockClosedIcon,
  MailIcon, PhoneOutgoingIcon, NewspaperIcon, PhoneIcon, ChatIcon, MenuIcon,
} from '@heroicons/vue/outline';
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';
import {
  getToken, onMessage,
} from 'firebase/messaging';
import DropDown from '@/components/DropDown.vue';
import LeadOptionsDropDown from '@/components/LeadOptionsDropDown.vue';
import AppointmentStatusModal from '@/components/AppointmentStatusModal.vue';
import ModalBox from '@/components/ModalBox.vue';
import LoadingBox from '@/components/LoadingBox.vue';
import AsesorItem from '@/components/chat/AsesorItem.vue';
import StatusItem from '@/components/leads/StatusItem.vue';
import CompanyDropDown from '@/components/CompanyDropDown.vue';
import FormDropDown from '@/components/FormDropDown.vue';
import AreaDropDown from '@/components/AreaDropDown.vue';
import axios from '@/http/axios/index';
import MainDropDown from '@/components/MainDropDown.vue';
import ChannelsDropDown from '@/components/ChannelsDropDown.vue';
import DateItem from '@/components/dates/DateItem.vue';

// Stylesheet
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css';

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export default {
  name: 'LeadboxContacts',
  displayName: 'LeadboxContacts',
  components: {
    LoadingBox,
    AppointmentStatusModal,
    MainDropDown,
    CompanyDropDown,
    AsesorItem,
    StatusItem,
    DropDown,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    LeadOptionsDropDown,
    ModalBox,
    DateItem,
    ArchiveIcon,
    CollectionIcon,
    UserIcon,
    SearchIcon,
    XIcon,
    TrashIcon,
    NewspaperIcon,
    UsersIcon,
    PencilAltIcon,
    MenuIcon,
    RefreshIcon,
    LockClosedIcon,
    MailIcon,
    PhoneOutgoingIcon,
    LitepieDatepicker,
    // eslint-disable-next-line vue/no-unused-components
    FormDropDown,
    AreaDropDown,
    ChatIcon,
    PhoneIcon,
    ChannelsDropDown,
    Toggle,
  },
  data() {
    return {
      files: [],
      showFieldInterface: false,
      showCreateFieldInterface: false,
      showAssignModal: false,
      showAssignOneModal: false,
      isLoadingAssignAuto: false,
      isLoadingAssign: false,
      selectAssignUsers: false,
      assignUsersList: [],
      newField: {
        label: null,
        data: null,
      },
      createNewField: {
        label: null,
        data: null,
        name: null,
      },
      assignUserModal: null,
      translates: {
        response: 'Respuesta',
        is_success: 'Estado',
        id: 'Id',
        user_id: 'Id de usuario',
        user_name: 'Nombre de usuario',
        date: 'Fecha',
        status: 'Estado respuesta',
        payload: 'Datos enviados',
      },
      newLead: {
        form: null,
        fields: [],
        addProduct: false,
        category: null,
        product: null,
        addUser: false,
        user: null,
        name: null,
        email: null,
        phone: null,
      },
      probability: null,
      channel: null,
      location: null,
      documentReceptor: null,
      selectedDocument: null,
      selectedContactLead: null,
      isShow: true,
      showModal: false,
      modalStatus: null,
      selected: '',
      isOpen: false,
      type: 'active',
      selectedTab: 'info',
      selectedStatusType: 'active',
      isLoadingContacts: false,
      isLoadingContactsUsers: false,
      isLoadingDate: false,
      isLoadingMore: false,
      isLoadingCompanies: false,
      isLoadingStatuses: false,
      isLoadingAreas: false,
      isLoadingLeadEvents: false,
      isLoadingLeadDocuments: false,
      isLoadingLeadConversations: false,
      isLoadingAppointmentConversations: false,
      isLoadingAppointmentLeads: false,
      isLoadingLeadLeads: false,
      isLoadingAppointmentHistory: false,
      isLoadingLeadChat: false,
      isLoadingCreateLead: false,
      isLoadingConversationNotes: false,
      showNoteInterface: false,
      showDeleteModal: false,
      showArchiveModal: false,
      showCreateModal: false,
      showEmailModal: false,
      showDocumentModal: false,
      showDeleteFileModal: false,
      showDeleteContactLeadModal: false,
      isOpenA: false,
      note: '',
      saving: false,
      selectedDates: [],
      text: '',
      order: 'DESC',
      filter: 'all',
      allSelected: false,
      search: null,
      editName: false,
      selectedUser: 0,
      selectedStatus: null,
      dateValue: ref({
        startDate: null,
        endDate: null,
      }),
      formatter: ref({
        date: 'DD-MM-YYYY',
        month: 'MMM',
      }),
      options: ref({
        shortcuts: {
          today: 'Hoy',
          yesterday: 'Ayer',
          past: (period) => `últimos ${period} días`,
          currentMonth: 'este mes',
          pastMonth: 'Mes pasado',
        },
        footer: {
          apply: 'Aplicar',
          cancel: 'Cancelar',
        },
      }),
      cssProps: {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/bg-chat.jpg')})`,
      },
      cssProps2: {
        // eslint-disable-next-line global-require
        background: 'white',
      },
      props: {
        msg: String,
      },
    };
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.getActualToken();
      if (this.companies.length > 0) {
        if (this.activeContact) {
          // this.getLeadEvents();
          this.getAppointmentHistory();
          this.getNotes();
          this.getAppointmentConversations();
          this.getAppointmentLeads();
          // this.getLeadDocuments();
        }
        if (this.activeCompany) {
          this.setActiveCompany();
        }
      } else {
        this.getCompanies();
      }
    } else {
      this.$router.push('').catch(() => {
      });
    }
  },
  watch: {
    dateValue(val) {
      console.log('val', val);
      this.getContacts();
      this.getContactsUsers();
      this.getStatuses();
    },
  },
  methods: {
    removeFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) this.files.splice(index, 1);
    },
    openFilePicker() {
      const element = this.$refs.fileInput;
      element.click();
    },
    onInputChange(e) {
      this.addFiles(e.target.files);
      e.target.value = null;
      // reset so that selecting the same file again will still cause it to fire this change
    },
    addFiles(newFiles) {
      const newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id));
      this.files = this.files.concat(newUploadableFiles);
    },
    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId);
    },
    sendFiles() {
      console.log('files', this.files);
      try {
        this.files.forEach((file) => {
          console.log('file', file);
          this.uploadFile(file);
        });

        this.files = [];
        this.showDocumentModal = false;
        setTimeout(() => {
          this.getLeadDocuments();
        }, 3000);
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'hubo un error',
        });
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.set('type', file.file.type);
      formData.set('name', file.file.name);
      formData.append('file', file.file);
      this.isLoading = true;
      console.log('formData', formData);
      this.axios.post(
        this.filesUrl,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data files', data);
        this.$notify({
          type: 'success',
          title: 'documento almacenado',
          text: `Se almacenó el archivo ${file.file.name}`,
        });
      })
        .catch((e) => {
          console.log('error', e);
        });
    },
    addHashToLocation(params) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        null,
        params,
      );
    },
    setFields() {
      this.newLead.fields = [];
      console.log('this.newLead.form.fields', this.newLead.form.fields);
      this.newLead.form.fields.forEach((field) => {
        if (field.key !== 'nombre' && field.key !== 'email' && field.key !== 'name'
          && field.key !== 'phone' && field.key !== 'mail' && field.key !== 'telefono') {
          this.newLead.fields.push({
            key: field.key,
            name: field.name,
            type: field.type,
            options: field.options,
            data: null,
          });
        }
      });
      console.log('this.newLead.fields', this.newLead.fields);
    },
    openEmailModal(document) {
      console.log('selected, document', document);
      this.selectedDocument = document;
      this.documentReceptor = null;
      this.showEmailModal = true;
    },
    openDeleteFileModal(document) {
      console.log('selected, document', document);
      this.selectedDocument = document;
      this.showDeleteFileModal = true;
    },
    openDeleteContactLeadModal(lead) {
      this.selectedContactLead = lead;
      this.showDeleteContactLeadModal = true;
    },
    getPayment(text) {
      switch (text) {
        case 'transfer':
          return 'Transferencia Bancaria';
        case 'cash':
          return 'Efectivo';
        case 'credit_card':
          return 'Tarjeta de crédito';
        default:
          return 'Pago';
      }
    },
    deleteDates() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedDates,
      };
      this.$store.dispatch('deleteDates', payload)
        .then((response) => {
          console.log('respuesta deleteDates', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'leads eliminados',
            text: success,
          });
          this.showDeleteModal = false;
          this.selectedDates = [];
          this.getContacts();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getContactsUsers();
        }, (error) => {
          console.log('error deleteDates', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteDates', error);
        });
    },
    deleteContactLead() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: [this.selectedContactLead.uuid],
      };
      this.$store.dispatch('deleteLeads', payload)
        .then((response) => {
          console.log('respuesta deleteLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: `lead ${this.selectedContactLead.name} eliminado`,
            text: success,
          });
          this.showDeleteContactLeadModal = false;
          this.getDate(this.activeContact.uuid);
          this.getContacts();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getContactsUsers();
          this.selectedContactLead = null;
        }, (error) => {
          console.log('error deleteLead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteLeads', error);
        });
    },
    archiveDates() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedDates,
      };
      this.$store.dispatch('archiveLeads', payload)
        .then((response) => {
          console.log('respuesta archiveLeads', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'leads archivados',
            text: success,
          });
          this.showArchiveModal = false;
          this.selectedDates = [];
          this.getContacts();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getContactsUsers();
        }, (error) => {
          console.log('error archiveLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error archiveLeads', error);
        });
    },
    createLead() {
      const payload = {
        company: this.activeCompany.uuid,
        form: this.newLead.form.uuid,
        name: this.newLead.name,
        email: this.newLead.email,
        phone: this.newLead.phone,
        addProduct: this.newLead.addProduct,
        product: this.newLead.product?.uuid,
        addUser: this.newLead.addUser,
        user: this.newLead.user?.id,
      };
      const fields = {};
      this.newLead.fields.forEach((field) => {
        fields[field.name] = field.data;
      });
      payload.fields = fields;
      this.isLoadingCreateLead = true;
      this.$store.dispatch('createLead', payload)
        .then(
          (response) => {
            this.isLoadingCreateLead = false;
            console.log('response createLead', response);
            this.$notify({
              type: 'success',
              title: 'Lead creado',
              text: response.data.message,
            });
            this.getContacts();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getContactsUsers();
            this.newLead = {
              form: null,
              fields: [],
              addProduct: false,
              category: null,
              product: null,
              addUser: false,
              user: null,
              name: null,
              email: null,
              phone: null,
            };
            this.showCreateModal = false;
          },
          (error) => {
            this.isLoadingCreateLead = false;
            console.log('error createLead', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateLead = false;
          console.log('error createLead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    assignDates() {
      const usersList = [];
      if (this.selectAssignUsers) {
        this.assignUsersList.forEach((user) => {
          if (user.selected) {
            usersList.push(user.id);
          }
        });
      }
      const payload = {
        company: this.activeCompany.uuid,
        selectUsers: this.selectAssignUsers,
        usersList,
      };
      console.log('payload', payload);
      this.isLoadingAssignAuto = true;
      this.$store.dispatch('assignAuto', payload)
        .then(
          (response) => {
            this.isLoadingAssignAuto = false;
            console.log('response assignAuto', response);
            this.$notify({
              type: 'success',
              title: 'Leads asignados',
              text: response.data.success,
            });
            this.getContacts();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getContactsUsers();
            this.showAssignModal = false;
          },
          (error) => {
            this.isLoadingAssignAuto = false;
            console.log('error assignAuto', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignAuto', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    assignOneDates() {
      const payload = {
        company: this.activeCompany.uuid,
        user: this.assignUserModal.uuid,
        ids: this.selectedDates,
      };
      this.isLoadingAssign = true;
      this.$store.dispatch('assignOne', payload)
        .then(
          (response) => {
            this.isLoadingAssign = false;
            console.log('response assignOne', response);
            this.$notify({
              type: 'success',
              title: `Leads asignados a ${this.assignUserModal.name}`,
              text: response.data.success,
            });
            this.getContacts();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getContactsUsers();
            this.isLoadingAssign = false;
            this.showAssignOneModal = false;
            this.selectedDates = [];
            this.assignUserModal = null;
          },
          (error) => {
            this.isLoadingAssign = false;
            console.log('error assignOne', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignOne', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    getProductSections() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productSections', payload)
        .then((response) => {
          console.log('respuesta productSections', response);
        }, (error) => {
          console.log('error productSections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productSections', error);
        });
    },
    getProductCategories() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productCategories', payload)
        .then((response) => {
          console.log('respuesta productCategories', response);
        }, (error) => {
          console.log('error productCategories', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productCategories', error);
        });
    },
    save() {
      if (this.saving) return;
      console.log('guarda', this.activeContact);
      const payload = {
        lead: this.activeContact,
        company: this.activeCompany.uuid,
      };
      this.saving = true;
      this.$store.dispatch('updateLead', payload)
        .then(
          (response) => {
            this.saving = false;
            console.log('response message', response);
            this.$notify({
              type: 'success',
              title: 'conversación actualizada',
              text: response.data.message,
            });
            this.getLeadEvents();
            this.getAppointmentHistory();
            this.getNotes();
            this.getAppointmentConversations();
            this.getAppointmentLeads();
            // this.getLeadDocuments();
            // this.getContacts();
            this.getStatuses();
            this.getAreas();
            this.getLocations();
            this.getChannels();
            this.getContactsUsers();
          },
          (error) => {
            this.saving = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.saving = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    goToConversation(uuid) {
      this.$router.push(`/chat?conversation=${uuid}`).catch(() => {
      });
    },
    goToLead(uuid) {
      this.$router.push(`/leads?lead=${uuid}`).catch(() => {
      });
    },
    getConversation(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      // this.isLoadingConversation = true;
      this.$store.dispatch('conversation', payload)
        .then((response) => {
          // this.isLoadingConversation = false;
          console.log('respuesta conversation', response);
          // this.getMessages();
          // this.getContacts();
          // this.getSender(this.activeConversation.uuid);
          // this.getNotes();
          this.$router.push('/chat').catch(() => {
          });
        }, (error) => {
          // this.isLoadingConversation = false;
          console.log('error conversation', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          // this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversation', error);
        });
    },
    updateSender() {
      if (this.savingSender) return;
      console.log('guarda', this.activeSender);
      const payload = {
        conversation: this.activeConversation,
        company: this.activeCompany.uuid,
        sender: this.activeSender,
      };
      this.savingSender = true;
      this.$store.dispatch('updateSender', payload)
        .then(
          (response) => {
            this.savingSender = false;
            console.log('response sender', response);
            this.$notify({
              type: 'success',
              title: 'contacto actualizado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.savingSender = false;
            console.log('error sender', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.savingSender = false;
          console.log('error sender', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    setProbability(probability) {
      this.probability = probability;
      this.getContacts();
      this.getContactsUsers();
      this.getStatuses();
    },
    setChannel(channel) {
      this.channel = channel;
      this.getContacts();
      this.getContactsUsers();
      this.getStatuses();
    },
    setLocation(location) {
      this.location = location;
      this.getContacts();
      this.getContactsUsers();
      this.getStatuses();
    },
    setStatusType(statusType) {
      this.selectedStatusType = statusType;
    },
    sendMessage(type) {
      if (this.isLoadingMessage) return;
      if (this.activeConversation.is_after) return;
      const payload = {
        body: this.text,
        type,
        id: this.activeConversation.uuid,
      };
      this.isLoadingMessage = true;
      this.$store.dispatch('sendMessage', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response message', response);
            this.text = null;
            this.$notify({
              type: 'success',
              title: 'Mensaje enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    setUser(user) {
      if (this.selectedUser === user.id) {
        this.selectedUser = 0;
      } else {
        this.selectedUser = user.id;
      }
      this.getContacts();
      this.getStatuses();
    },
    selectStatus(status) {
      this.modalStatus = status;
      this.showModal = true;
    },
    setStatus(status) {
      if (this.selectedStatus) {
        if (this.selectedStatus === status.id) {
          this.selectedStatus = null;
        } else {
          this.selectedStatus = status.id;
        }
      } else {
        this.selectedStatus = status.id;
      }
      this.getContacts();
      this.getContactsUsers();
    },
    selectTab(tab) {
      this.selectedTab = tab;
    },
    changeOrder(direction) {
      this.order = direction;
      this.getContacts();
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedDates = [];
      } else {
        this.selectedDates = this.leads.map((lead) => lead.uuid);
      }
      this.allSelected = !this.allSelected;
    },
    setEmoji(emoji) {
      this.text += emoji.native;
    },
    selectDate(date) {
      if (!this.isLoadingDate) {
        this.selectedTab = 'info';
        this.getDate(date);
      }
    },
    selectGroupDate(date) {
      if (this.selectedDates.includes(date.uuid)) {
        const index = this.selectedDates.indexOf(date.uuid);
        this.selectedDates.splice(index, 1);
      } else {
        this.selectedDates.push(date.uuid);
      }
    },
    scrollDown() {
      const element = document.getElementById('messages_container');
      element.scrollTop = element.scrollHeight;
    },
    closeDate() {
      this.$store.dispatch('setActiveContact', null)
        .then((response) => {
          console.log('respuesta', response);
          this.addHashToLocation('/dates');
        }, (error) => {
          console.log('error', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    changedCompany() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeDate();
      this.getContactsUsers();
      this.getContactsUsersList();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getChannels();
      this.getIntegrations();
      this.getContacts();
      this.getForms();
      this.getConnections();
      this.getSaleProbabilities();
      this.getProductCategories();
      this.getProductSections();
      this.getLeadNotifications();
      this.getMessageNotifications();
    },
    changedForm() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeDate();
      this.getContactsUsers();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getContacts();
    },
    changedArea() {
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.closeDate();
      this.getContactsUsers();
      this.getStatuses();
      this.getContacts();
    },
    setToken() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then(
          (response) => {
            console.log('response token', response);
            this.getCompanies();
            this.getActualToken();
          },
          (error) => {
            console.log('error token', error);
          },
        ).catch((error) => {
          console.log('error token', error);
        });
    },
    getCompanies() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then((response) => {
          console.log('respuesta companies', response);
          this.setActiveCompany();
          this.isLoadingCompanies = false;
        }, (error) => {
          console.log('error companies', error);
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error company', error);
        });
    },
    setActiveCompany() {
      const selectedCompany = localStorage.getItem('companyUuid');
      let company = null;
      if (selectedCompany) {
        const index = this.companies.findIndex((comany) => comany.uuid === selectedCompany);
        company = this.companies[index];
      } else {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.getContacts();
          this.getContactsUsers();
          this.getContactsUsersList();
          this.getForms();
          this.getConnections();
          this.getSaleProbabilities();
          this.getStatuses();
          this.getAreas();
          this.getLocations();
          this.getChannels();
          this.getIntegrations();
          this.getProductCategories();
          this.getProductSections();
          this.getLeadNotifications();
          this.getMessageNotifications();
          const id = this.$route.query.date;
          if (id) {
            this.selectDate(id);
          }
        }, (error) => {
          this.isLoading = false;
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    refresh() {
      this.getContacts();
      this.getContactsUsers();
      this.getStatuses();
      this.getAreas();
      this.getLocations();
      this.getChannels();
    },
    getContacts() {
      // TODO: send pagination
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        user: this.selectedUser,
        status: this.selectedStatus,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        probability: this.probability?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
      };
      console.log('payload contacts', payload);
      this.isLoadingContacts = true;
      this.$store.dispatch('contacts', payload)
        .then((response) => {
          console.log('respuesta contacts', response);
          this.isLoadingContacts = false;
        }, (error) => {
          console.log('error contacts', error);
          this.isLoadingContacts = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingContacts = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error contacts', error);
        });
    },
    getLeadNotifications() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingLeadNotifications = true;
      this.$store.dispatch('leadNotifications', payload)
        .then((response) => {
          console.log('respuesta leadNotifications', response);
          this.isLoadingLeadNotifications = false;
        }, (error) => {
          console.log('error leadNotifications', error);
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadNotifications', error);
        });
    },
    getMessageNotifications() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingMessageNotifications = true;
      this.$store.dispatch('messageNotifications', payload)
        .then((response) => {
          console.log('respuesta messageNotifications', response);
          this.isLoadingMessageNotifications = false;
        }, (error) => {
          console.log('error messageNotifications', error);
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messageNotifications', error);
        });
    },
    loadMore() {
      if (this.isLoadingMore) return;
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        user: this.selectedUser,
        status: this.selectedStatus,
        url: this.nextPageUrl,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        probability: this.probability?.id,
        channel: this.channel?.id,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
      };
      this.isLoadingMore = true;
      this.$store.dispatch('nextPageAppointments', payload)
        .then((response) => {
          console.log('respuesta conversations', response);
          this.isLoadingMore = false;
        }, (error) => {
          this.isLoadingMore = false;
          console.log('error conversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMore = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversations', error);
        });
    },
    getConnections() {
      // TODO: send pagination
      this.isLoadingConnections = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('connections', payload)
        .then((response) => {
          this.isLoadingConnections = false;
          console.log('respuesta connections', response);
        }, (error) => {
          this.isLoadingConnections = false;
          console.log('error connections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingConnections = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error connections', error);
        });
    },
    getContactsUsers() {
      // TODO: send pagination

      this.isLoadingContactsUsers = true;
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        status: this.selectedStatus,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
        probability: this.probability?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('appointmentUsers', payload)
        .then((response) => {
          this.isLoadingContactsUsers = false;
          console.log('respuesta leadUsers', response);
        }, (error) => {
          this.isLoadingContactsUsers = error;
          console.log('error leadUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingContactsUsers = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadUsers', error);
        });
    },
    getContactsUsersList() {
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        status: this.selectedStatus,
      };
      this.$store.dispatch('leadUsersList', payload)
        .then((response) => {
          console.log('respuesta leadUsersList', response);
          this.assignUsersList = this.datesUsersList.map((user) => {
            const usr = user;
            usr.selected = false;
            return usr;
          });
        }, (error) => {
          console.log('error leadUsersList', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadUsersList', error);
        });
    },
    createField() {
      const field = {
        data: this.newField.data,
        extra: false,
        is_editable: true,
        is_visible: true,
        key: this.newField.label,
        label: this.newField.label,
      };
      this.activeContact.fields.push(field);
      this.showFieldInterface = false;
      this.newField = {
        label: null,
        data: null,
      };
    },
    addNewField() {
      const field = {
        data: this.createNewField.data,
        extra: false,
        is_editable: true,
        is_visible: true,
        key: this.createNewField.name,
        label: this.createNewField.name,
        name: this.createNewField.name,
      };
      this.newLead.fields.push(field);
      this.showCreateFieldInterface = false;
      this.createNewField = {
        name: null,
        label: null,
        data: null,
      };
    },
    deleteField(key) {
      this.filteredFields.splice(key, 1);
    },
    createNote() {
      const payload = {
        note: this.note,
        lead: this.activeContact.uuid,
      };
      this.isLoadingCreateNote = true;
      this.$store.dispatch('createLeadNote', payload)
        .then(
          (response) => {
            this.isLoadingCreateNote = false;
            this.showNoteInterface = false;
            this.note = '';
            console.log('response createNote', response);
            this.$notify({
              type: 'success',
              title: 'Nota creada',
              text: response.data.message,
            });
            this.getNotes();
          },
          (error) => {
            this.isLoadingCreateNote = false;
            console.log('error createNote', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateNote = false;
          console.log('error createNote', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    sendEmail() {
      const payload = {
        document: this.selectedDocument.uuid,
        email: this.documentReceptor,
        lead: this.activeContact.uuid,
      };
      this.isLoadingDate = true;
      this.$store.dispatch('sendEmail', payload)
        .then(
          (response) => {
            this.isLoadingDate = false;
            this.showEmailModal = false;
            this.selectedDocument = null;
            this.documentReceptor = null;
            console.log('response sendEmail', response);
            this.$notify({
              type: 'success',
              title: 'Documento enviado',
              text: response?.data?.message,
            });
            this.getLeadDocuments();
          },
          (error) => {
            this.isLoadingDate = false;
            console.log('error sendEmail', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingDate = false;
          console.log('error sendEmail', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    deleteFile() {
      const payload = {
        document: this.selectedDocument.uuid,
        lead: this.activeContact.uuid,
        company: this.activeCompany.uuid,
      };
      console.log('payload', payload);
      this.isLoadingDate = true;
      this.$store.dispatch('deleteFile', payload)
        .then(
          (response) => {
            this.isLoadingDate = false;
            this.showDeleteFileModal = false;
            this.getLeadDocuments();
            console.log('response deleteFile', response);
            this.$notify({
              type: 'success',
              title: 'Documento enviado',
              text: response?.data?.message,
            });
            this.getLeadDocuments();
          },
          (error) => {
            this.isLoadingDate = false;
            console.log('error deleteFile', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingDate = false;
          console.log('error deleteFile', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    getNotes() {
      const payload = {
        appointment: this.activeContact.uuid,
      };
      this.isLoadingConversationNotes = true;
      this.$store.dispatch('appointmentNotes', payload)
        .then((response) => {
          this.isLoadingConversationNotes = false;
          console.log('respuesta appointmentNotes', response);
        }, (error) => {
          this.isLoadingConversationNotes = false;
          console.log('error appointmentNotes', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationNotes = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error appointmentNotes', error);
        });
    },
    getStatuses() {
      // TODO: send pagination
      this.isLoadingStatuses = true;
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        user: this.selectedUser,
        form: this.activeForm?.id,
        area: this.activeArea?.id,
        channel: this.channel?.id,
        location: this.location?.id,
        probability: this.probability?.id,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('appointmentStatuses', payload)
        .then((response) => {
          this.isLoadingStatuses = false;
          console.log('respuesta statuses', response);
        }, (error) => {
          this.isLoadingStatuses = false;
          console.log('error statuses', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingStatuses = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error statuses', error);
        });
    },
    getAreas() {
      // TODO: send pagination
      this.isLoadingAreas = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('areas', payload)
        .then((response) => {
          this.isLoadingAreas = false;
          console.log('respuesta areas', response);
        }, (error) => {
          this.isLoadingAreas = false;
          console.log('error areas', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingAreas = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error areas', error);
        });
    },
    getLocations() {
      // TODO: send pagination
      this.isLoadingLocations = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('locations', payload)
        .then((response) => {
          this.isLoadingLocations = false;
          console.log('respuesta isLoadingLocations', response);
        }, (error) => {
          this.isLoadingLocations = false;
          console.log('error areas', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingLocations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error isLoadingLocations', error);
        });
    },
    getChannels() {
      // TODO: send pagination
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        user: this.selectedUser,
      };
      this.$store.dispatch('channels', payload)
        .then((response) => {
          console.log('respuesta channels', response);
        }, (error) => {
          console.log('error channels', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error channels', error);
        });
    },
    getForms() {
      // TODO: send pagination
      this.isLoadingForms = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('forms', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta forms', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error forms', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error forms', error);
        });
    },
    getSaleProbabilities() {
      // TODO: send pagination
      this.isLoadingForms = true;
      console.log('the company', this.activeCompany);
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('probabilities', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta probabilities', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error probabilities', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error probabilities', error);
        });
    },
    getIntegrations() {
      console.log('the company', this.activeCompany);
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('integrations', payload)
        .then((response) => {
          console.log('respuesta integrations', response);
          response.data.success.forEach((integration) => {
            if (integration.type === 'vtiger' || integration.type === 'teojama') {
              this.getIntegrationUsers(integration.type, integration.uuid);
            }
          });
        }, (error) => {
          console.log('error integrations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrations', error);
        });
    },
    getIntegrationUsers(type, integration) {
      console.log('type', type);
      console.log('integration', integration);
      const payload = {
        company: this.activeCompany.uuid,
        type,
        integration,
      };
      console.log('payload', payload);
      this.$store.dispatch('integrationUsers', payload)
        .then((response) => {
          console.log('respuesta integrationUsers', response);
        }, (error) => {
          console.log('error integrationUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrationUsers', error);
        });
    },
    getNumbers() {
      this.$store.dispatch('conversations', this.activeCompany.uuid)
        .then((response) => {
          console.log('respuesta', response);
          const { conversations } = response.data.success;
          console.log('conversations', conversations);
        }, (error) => {
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    closeModalUpdate(uuid) {
      this.showModal = false;
      this.getDate(uuid);
    },
    getDate(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      this.addHashToLocation(`/dates?date=${uuid}`);
      this.isLoadingDate = true;
      this.$store.dispatch('date', payload)
        .then((response) => {
          this.isLoadingDate = false;
          console.log('respuesta date', response);
          // this.getLeadEvents();
          this.getAppointmentHistory();
          this.getNotes();
          this.getAppointmentConversations();
          this.getAppointmentLeads();
          // this.getLeadConversations();
          // this.getLeadDocuments();
        }, (error) => {
          this.isLoadingDate = false;
          console.log('error date', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingDate = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error date', error);
        });
    },
    getDateLeads() {
      const payload = {
        lead: this.activeContact.uuid,
      };
      this.isLoadingLeadLeads = true;
      this.$store.dispatch('leadEvents', payload)
        .then((response) => {
          this.isLoadingLeadLeads = false;
          console.log('respuesta leadLeads', response);
        }, (error) => {
          this.isLoadingLeadLeads = false;
          console.log('error leadLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messages', error);
        });
    },
    getLeadEvents() {
      const payload = {
        lead: this.activeContact.uuid,
      };
      this.isLoadingLeadEvents = true;
      this.$store.dispatch('leadEvents', payload)
        .then((response) => {
          this.isLoadingLeadEvents = false;
          console.log('respuesta leadEvents', response);
        }, (error) => {
          this.isLoadingLeadEvents = false;
          console.log('error leadEvents', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadEvents = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadEvents', error);
        });
    },
    getLeadDocuments() {
      const payload = {
        lead: this.activeContact.uuid,
      };
      this.isLoadingLeadDocuments = true;
      this.$store.dispatch('leadDocuments', payload)
        .then((response) => {
          this.isLoadingLeadDocuments = false;
          console.log('respuesta leadDocuments', response);
        }, (error) => {
          this.isLoadingLeadDocuments = false;
          console.log('error leadDocuments', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadDocuments = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadDocuments', error);
        });
    },
    getLeadConversations() {
      const payload = {
        lead: this.activeContact.uuid,
      };
      this.isLoadingLeadConversations = true;
      this.$store.dispatch('leadConversations', payload)
        .then((response) => {
          this.isLoadingLeadConversations = false;
          console.log('respuesta leadConversations', response);
        }, (error) => {
          this.isLoadingLeadConversations = false;
          console.log('error leadConversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingLeadConversations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadConversations', error);
        });
    },
    getAppointmentConversations() {
      const payload = {
        date: this.activeContact.uuid,
      };
      this.isLoadingAppointmentConversations = true;
      this.$store.dispatch('appointmentConversations', payload)
        .then((response) => {
          this.isLoadingAppointmentConversations = false;
          console.log('respuesta AppointmentConversations', response);
        }, (error) => {
          this.isLoadingAppointmentConversations = false;
          console.log('error AppointmentConversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentConversations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error AppointmentConversations', error);
        });
    },
    getAppointmentLeads() {
      const payload = {
        date: this.activeContact.uuid,
      };
      this.isLoadingAppointmentLeads = true;
      this.$store.dispatch('appointmentLeads', payload)
        .then((response) => {
          this.isLoadingAppointmentLeads = false;
          console.log('respuesta AppointmentLeads', response);
        }, (error) => {
          this.isLoadingAppointmentLeads = false;
          console.log('error AppointmentLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error AppointmentLeads', error);
        });
    },
    getAppointmentHistory() {
      const payload = {
        appointment: this.activeContact.uuid,
      };
      this.isLoadingAppointmentHistory = true;
      this.$store.dispatch('appointmentHistory', payload)
        .then((response) => {
          this.isLoadingAppointmentHistory = false;
          console.log('respuesta AppointmentHistory', response);
        }, (error) => {
          this.isLoadingAppointmentHistory = false;
          console.log('error AppointmentHistory', error);
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: error?.data?.message,
          // });
        })
        .catch((error) => {
          this.isLoadingAppointmentHistory = false;
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: 'hubo un error',
          // });
          console.log('error AppointmentHistory', error);
        });
    },
    setType(type) {
      this.type = type;
      this.selectedUser = 0;
      this.selectedStatus = null;
      this.getContactsUsers();
      this.getStatuses();
      this.getContacts();
    },
    setOnMessage() {
      onMessage(this.$messaging, (payload) => {
        if (payload.data.for === 'user') {
          this.$notify({
            type: 'success',
            title: payload.notification.title,
            text: payload.notification.body,
          });
        }
        this.getContacts();
        this.getMessageNotifications();
        console.log('Message received. ', payload);
        // if (this.activeConversation) {
        //   if (this.activeConversation.uuid === payload.data.conversation_id) {
        //     this.selectedTab = 'chat';
        //     setTimeout(() => {
        //       this.getMessagesAgain();
        //     }, 100);
        //   }
        // } else {
        //   console.log('no esta activa');
        //   console.log(payload.data.conversation_id);
        //   // TODO: check if is in correct company, if no set the correct company
        //   this.getDate(payload.data.conversation_id);
        // }
      });
    },
    getKeys(obj) {
      return Object.keys(obj);
    },
    checkIfExist(items, item) {
      return items.includes(item);
    },
    filterVtigerUser(id, type) {
      const vt = this.companyIntegrations.find((int) => int.type === type);
      console.log('vt', vt);
      if (vt) {
        if (this.integrationUsers[type]) {
          const user = this.integrationUsers[type].filter((usr) => usr.id === id)[0];
          if (user) {
            return `${user.first_name} ${user.last_name}`;
          }
        }
        return `El usuario ya no existe en ${type}`;
      }
      return 'No hay integraciones';
    },
    sourceLead(lead) {
      if (lead) {
        let url = '';
        switch (lead.form_provider) {
          case 'manychat':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'manychat-instagram':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'typeform':
            // eslint-disable-next-line global-require
            url = require('@/assets/typeform.svg');
            break;
          case 'elementor':
            // eslint-disable-next-line global-require
            url = require('@/assets/elementor.png');
            break;
          case 'facebook_leadgen':
            // eslint-disable-next-line global-require
            url = require('@/assets/facebook-leadgen.png');
            break;
          case 'wordpress':
            // eslint-disable-next-line global-require
            url = require('@/assets/wordpress.png');
            break;
          case 'drupal':
            // eslint-disable-next-line global-require
            url = require('@/assets/drupal.png');
            break;
          case 'landing':
            // eslint-disable-next-line global-require
            url = require('@/assets/landing.png');
            break;
          case 'email':
            // eslint-disable-next-line global-require
            url = require('@/assets/email.png');
            break;
          case 'excel':
            // eslint-disable-next-line global-require
            url = require('@/assets/excel.png');
            break;
          case 'patiotuerca':
            // eslint-disable-next-line global-require
            url = require('@/assets/patiotuerca.webp');
            break;
          case 'ecommerce':
            // eslint-disable-next-line global-require
            url = require('@/assets/shop-cart.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'add-sales':
            // eslint-disable-next-line global-require
            url = require('@/assets/add-sales.jpg');
            break;
          case 'channels':
            if (this.activeContact.channel_icon) {
              url = `https://leadbox.ec/storage/${this.activeContact.channel_icon}`;
            } else {
              // eslint-disable-next-line global-require
              url = require('@/assets/filter.png');
            }
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
          default:
            break;
        }
        return url;
      }
      return '';
    },
    getActualToken() {
      getToken(this.$messaging, {
        vapidKey: 'BLfWeuuIdNsHrWx-2XIUiXfn_PEWjUMI6oUwLRSerdWw4JmKxE6O22nxcJ6dO60ZPahGMSBlyUTasq1OkgYogdc',
      }).then((currentToken) => {
        if (currentToken) {
          console.log('token', currentToken);
          this.$store.dispatch('tokens', [currentToken])
            .then((response) => {
              console.log('respuesta', response);
              this.setOnMessage();
            }, (error) => {
              console.log('status', error);
              this.$notify({
                type: 'error',
                title: 'Error',
                text: error?.data?.message,
              });
            })
            .catch((error) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: 'hubo un error',
              });
              console.log('error', error);
            });
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    },

  },
  computed: {
    filesUrl() {
      if (this.activeContact) {
        return `https://leadbox.ec/api/v1/leads/${this.activeContact.uuid}/uploadFile`;
      }
      return '';
    },
    companyCategories() {
      return this.$store.state.productCategories;
    },
    source() {
      if (this.activeContact) {
        let url = '';
        switch (this.activeContact.form_provider) {
          case 'manychat':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'manychat-instagram':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'typeform':
            // eslint-disable-next-line global-require
            url = require('@/assets/typeform.svg');
            break;
          case 'elementor':
            // eslint-disable-next-line global-require
            url = require('@/assets/elementor.png');
            break;
          case 'facebook_leadgen':
            switch (this.activeContact.platform) {
              case 'ig':
              case 'in':
                // eslint-disable-next-line global-require
                url = require('@/assets/logo-instagram.png');
                break;
              case 'fb':
              default:
                // eslint-disable-next-line global-require
                url = require('@/assets/facebook-leadgen.png');
                break;
            }
            break;
          case 'wordpress':
            // eslint-disable-next-line global-require
            url = require('@/assets/wordpress.png');
            break;
          case 'drupal':
            // eslint-disable-next-line global-require
            url = require('@/assets/drupal.png');
            break;
          case 'landing':
            // eslint-disable-next-line global-require
            url = require('@/assets/landing.png');
            break;
          case 'email':
            // eslint-disable-next-line global-require
            url = require('@/assets/email.png');
            break;
          case 'excel':
            // eslint-disable-next-line global-require
            url = require('@/assets/excel.png');
            break;
          case 'patiotuerca':
            // eslint-disable-next-line global-require
            url = require('@/assets/patiotuerca.webp');
            break;
          case 'ecommerce':
            // eslint-disable-next-line global-require
            url = require('@/assets/shop-cart.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'add-sales':
            // eslint-disable-next-line global-require
            url = require('@/assets/add-sales.jpg');
            break;
          case 'channels':
            if (this.activeContact.channel_icon) {
              url = `https://leadbox.ec/storage/${this.activeContact.channel_icon}`;
            } else {
              // eslint-disable-next-line global-require
              url = require('@/assets/filter.png');
            }
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
          default:
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
        }
        return url;
      }
      return '';
    },

    companies() {
      return this.$store.state.companies;
    },
    statuses() {
      return this.$store.state.appointmentStatuses;
    },
    activeStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'assigned' || status.type === 'initial');
      }
      return [];
    },
    closedStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'final_positive' || status.type === 'final_negative');
      }
      return [];
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    activeForm() {
      return this.$store.state.activeForm;
    },
    activeArea() {
      return this.$store.state.activeArea;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    channels() {
      return this.$store.state.channels;
    },
    messages() {
      return this.$store.state.conversationMessages;
    },
    dates() {
      return this.$store.state.dates;
    },
    total() {
      return this.$store.state.total;
    },
    conversations() {
      return this.$store.state.conversations;
    },
    activeContact() {
      return this.$store.state.activeContact;
    },
    datesUsers() {
      return this.$store.state.appointmentUsers;
    },
    datesUsersList() {
      return this.$store.state.appointmentUsersList;
    },
    locations() {
      return this.$store.state.locations;
    },
    forms() {
      return this.$store.state.forms;
    },
    nextPageUrl() {
      return this.$store.state.next_page_url;
    },
    activeSender() {
      return this.$store.state.activeSender;
    },
    leadEvents() {
      return this.$store.state.leadEvents;
    },
    dateLeads() {
      return this.$store.state.dateLeads;
    },
    appointmentHistories() {
      return this.$store.state.appointmentHistories;
    },
    leadConversations() {
      return this.$store.state.leadConversations;
    },
    appointmentConversations() {
      return this.$store.state.appointmentConversations;
    },
    appointmentLeads() {
      return this.$store.state.appointmentLeads;
    },
    leadDocuments() {
      return this.$store.state.leadDocuments;
    },
    notes() {
      return this.$store.state.appointmentNotes;
    },
    areas() {
      return this.$store.state.areas;
    },
    probabilities() {
      return this.$store.state.probabilities;
    },
    companyIntegrations() {
      return this.$store.state.companyIntegrations;
    },
    integrationUsers() {
      return this.$store.state.integrationUsers;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.acor .vcp__body-content {
  background: #f3f4f6 !important;
}

.acor .vcp__header-icon > span[data-v-64321428] {
  color: #4b4e4e !important;
}

.vcpg[data-v-63943e43] {
  border: none !important;
  border-radius: 0px !important;
}

.bg-header {
  background-color: #353E4B;
}

.vcp__header-icon {
  color: #bcc8ca;
}

.text-left {
  text-align: left;
}

.bg-green-720 {
  background-color: #2A6F7F !important;
}

.bg-indigo-600 {
  background: #205662 !important;
}

.hover\:bg-gray-500:hover {
  background-color: #2a6f7f7a;
  border-radius: 5px;
}

.flex.m-1.bg-white.p-2.cursor-pointer.border-r-4 {
  border-right: 4px solid #22d3c5;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100.bg-sky-400.border-sky-400.text-white {
  color: #ffffff;
  background-image: -webkit-gradient(linear, left top, right top, from(#00BFBC), to(#009DB2));
  background-image: linear-gradient(to right, #00BFBC, #009DB2);
  position: relative;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}

button.rounded-lg.p-2.border-2.w-full.bg-sky-100 {
  color: #3a3838;
  background-color: #EAF2F9;
  border-color: #EAF2F9;
  position: relative;
  margin-bottom: 7px;
  border-radius: 10px 10px 10px 10px;
  border: none !important;
}

.btn-states {
  border-radius: 7px;
  min-width: 125px;
  max-width: 145px;
  color: #333;
  padding: 0.25rem 0.5rem;
  font-size: 0.775rem;
  border-color: #5bc0de;
}

.btn-states:hover {
  background: #5bc0de;
  transition: 0.7s;
}

.text-sidebar {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}

button.bg-sky-600 {
  background-color: #2A6F7F !important;
}

.border-leadbox-600 {
  border-color: #2A6F7F !important;
}

.acor .vcpg[data-v-63943e43] .vcp__header {
  background-color: #f9f6f6 !important;
  border: 1px solid #5b5858 !important;
  margin: 10px 0px !important;
  border-radius: 5px;
}

.acor .vcp.vcp--expanded.vcp--expandable .vcp__header {
  background: #bfc8ca !important;
}

.vcpg[data-v-63943e43] .vcp__header {
  background-color: #353e4b !important;
}

.vcpg[data-v-63943e43] .vcp:not(:first-of-type) .vcp__header {
  border-top: 1px solid #4a4e50 !important;
}

.vcp__body-content {
  background: #2c3646;
}

.icons-float svg {
  padding: 10px;
  height: 45px;
  width: 45px;
  border-radius: 100%;
}

.icons-float svg:hover {
  background: #2a6f7f;
}

.bg-hover {
  background: #2a6f7f;
}

.bg-email {
  background: #fbd123;
}

.bg-phone {
  background: #0673f8;
}

.bg-phone-issabel {
  background: #7f0ac4;
}

.bg-chat {
  background: #09ce0d;
}

.icono-email {
  background: #fbd123;
  color: #fff;
}

.icono-phone {
  background: #0673f8;
  color: #fff;
}

.icono-chat {
  background: #09ce0d;
  color: #fff;
}

.icons-float {
  z-index: 99;
  text-align: center;
  display: flex;
}

.notShown {
  visibility: hidden !important;
}

.sidebar-menu.test-sidebar.hidden {
  max-width: 40px;
}

.vcpg {
  border-bottom: none !important;
  border-top: solid 1px #75777a !important;
}

.vcp__header-icon {
  color: white;
}

/*Scroll*/
.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 5px;
}

section.vcp.vcp--expanded.vcp--expandable .vcp__header {
  background: #2a6f7f !important;
  border: none !important;
}

.vcpg {
  border-left: none !important;
}

.border-leadbox-900 {
  border-color: #22d3c5;
}

.text-tabs {
  line-height: 4px;
}

@media (max-width: 600px) {
  .test-sidebar {
    overflow-y: auto;
  }

  .test-sidebar::-webkit-scrollbar-thumb {
    background-color: #22d3c5;
  }

  .test-sidebar::-webkit-scrollbar-track {
    background: #fff;
  }

  .test-sidebar::-webkit-scrollbar {
    width: 5px;
  }

  .area-message {
    left: -115px;
  }

  .area-notification {
    left: -71px;
  }

  .vcp__header[data-v-64321428] {
    padding: 0px !important;
  }

  .vcp__body-content[data-v-64321428] {
    padding: 12px 6px !important;
  }

  .scroll-states {
    overflow-x: auto;
  }

  .title-header {
    font-size: 20px;
  }

  .filter-date {
    padding-right: 0px;
    padding-top: 7px;
    padding-bottom: 11px;
    margin-right: 0px;
    border: none;
  }
  button.mr-1.focus\:outline-none.text-litepie-secondary-400.dark\:text-opacity-70.rounded-md {
    margin-right: 0px;
  }
}
</style>
