import { createApp } from 'vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import VueAxios from 'vue-axios';
import Notifications from '@kyvg/vue3-notification';
import VueCarousel from 'vue-carousel';
import axios from './axios';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './assets/tailwind.css';
import firebaseMessaging from './firebase';

const app = createApp(App);
app.config.globalProperties.$messaging = firebaseMessaging;
app.use(VueGoogleMaps, {
  load: {
    // Add your API key here
    key: 'AIzaSyBqC4_cUWlNZ9PvmDCwkroKLXHfnpe6Wvg',
    libraries: 'places', // This is required if you use the Auto complete plug-in
  },
});
app.use(Notifications);
app.use(VueCarousel);
app.use(VueAxios, axios);
app.use(store);
app.use(router);
app.mount('#app');
