<template>
  <div class="w-full flex flex-col p-2 ">
    <div class="w-full justify-between flex border-leadbox-900 border-b-4 p-2">
      <h1 class="text-xl font-semibold">Leads por formularios</h1>
      <button
        class="text-sm font-medium text-gray-700 cursor-pointer bg-gray-200 rounded-md
        border border-black"
              @click="getLeadsByForms">
        <RefreshIcon class="m-2 h-5 w-5" aria-hidden="true"/>
      </button>
    </div>
    <div class="p-4">
      <template v-if="isLoading" >
        <div class="w-full flex justify-center">
          <img alt="loading" src="@/assets/loading.gif" class="w-20">
        </div>
      </template>
      <template v-else>
<!--        <div class="flex justify-center py-4">-->
<!--          <template v-if="isLoadingExcel">-->
<!--            <div class="flex-col justify-center">-->
<!--              <img alt="loading" src="@/assets/loading.gif" class="w-20">-->
<!--              <p>Generando descarga...</p>-->
<!--            </div>-->
<!--          </template>-->
<!--          <template v-else>-->
<!--            <button @click="downloadLeadsByForms"-->
<!--               class="w-full md:w-1/3 group relative w-full flex justify-center py-2 px-4-->
<!--                 border-transparent text-sm font-medium rounded-md text-white mx-1-->
<!--                               bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2-->
<!--                            focus:ring-offset-2 focus:ring-sky-600 border" type="button">-->
<!--              Descargar reporte-->
<!--            </button>-->
<!--          </template>-->
<!--        </div>-->
        <table class="table-fixed border-collapse border border-black w-3/4 mx-auto">
          <thead class="">
          <tr class="bg-gray-200 text-left font-semibold">
            <th class="p-2 border border-black ">FORMULARIO</th>
<!--            <th class="p-2 border border-black text-left font-semibold">CHAT</th>-->
            <th class="p-2 border border-black text-left font-semibold">ATENDIDO POR:</th>
            <th class="p-2 border border-black text-left font-semibold">NO. DE LEADS</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(formRow, index) in forms" v-bind:key="index">
            <td class="p-2 border border-black">{{ formRow.form }}</td>
<!--            <td class="p-2 border border-black">{{ channelRow.chat }}</td>-->
            <td class="p-2 border border-black">{{ formRow.users }}</td>
            <td class="p-2 border border-black">{{ formRow.number }}</td>
          </tr>
          <tr>
            <td class="p-2 border border-black" colspan="2">TOTAL</td>
            <td class="p-2 border border-black"> {{ total }}</td>
          </tr>
          </tbody>
        </table>
      </template>
    </div>
    <!--    <div class="w-full flex justify-center p-2">-->
    <!--      <pagination-item-->
    <!--        :maxVisibleButtons="options.maxVisibleButtons"-->
    <!--        :totalPages="options.totalPages"-->
    <!--        :perPage="options.perPage"-->
    <!--        :currentPage="options.currentPage"-->
    <!--        @page-changed="changePage"-->
    <!--      />-->
    <!--      <div class="mx-1 border-gray-400 border-2 rounded-md">-->
    <!--        <select v-model="options.perPage" class="p-4">-->
    <!--          <option :value="10">10</option>-->
    <!--          <option :value="50">50</option>-->
    <!--          <option :value="100">100</option>-->
    <!--        </select>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</template>

<script>

// import PaginationItem from '@/components/general/PaginationItem.vue';
import {
  RefreshIcon,
} from '@heroicons/vue/outline';
import { utils, writeFileXLSX } from 'xlsx';

export default {
  name: 'LeadsByForms',
  displayName: 'LeadsByForms',
  components: { RefreshIcon },
  props: {
    dateValue: Object,
    tab: String,
  },
  data() {
    return {
      forms: [],
      total: 0,
      options: {
        maxVisibleButtons: 5,
        totalPages: 10,
        perPage: 10,
        currentPage: 4,
      },
      isLoading: false,
      isLoadingExcel: false,
    };
  },
  mounted() {
    if (this.activeCompany) {
      this.getLeadsByForms();
    }
  },
  watch: {
    dateValue(val) {
      console.log('val', val);
      this.getLeadsByForms();
    },
    activeCompany(company) {
      if (company) {
        this.getLeadsByForms();
      }
    },
    tab(value) {
      if (value === 'leads') {
        if (this.activeCompany) {
          this.getLeadsByForms();
        }
      }
    },
  },
  methods: {
    getLeadsByForms() {
      this.isLoading = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('reportsLeadsByForms', payload)
        .then((response) => {
          console.log('response by reportsLeadsByForms', response);
          this.forms = response.data.success.data;
          this.total = response.data.success.total;
          this.isLoading = false;
          console.log('respuesta reportsLeadsByForms', response);
        }, (error) => {
          this.isLoading = error;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error reportsLeadsByForms', error);
        });
    },
    downloadLeadsByForms() {
      this.isLoadingExcel = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('reportsLeadsByFormsDownload', payload)
        .then((response) => {
          console.log('response reportsLeadsByFormsDownload', response);
          const { data } = response.data.success;
          const wb = utils.book_new();
          data.forEach((row) => {
            const ws = utils.json_to_sheet(row.values);
            utils.book_append_sheet(wb, ws, row.name.slice(0, 30));
          });
          writeFileXLSX(wb, `leadsByForms${new Date().getTime()}.xlsx`);
          this.isLoadingExcel = false;
          console.log('respuesta reportsLeadsByFormsDownload', response);
        }, (error) => {
          console.log('error reportsLeadsByFormsDownload', error);
          this.isLoadingExcel = false;
        })
        .catch((error) => {
          this.isLoadingExcel = false;
          console.log('error reportsLeadsByFormsDownload', error);
        });
    },
    changePage(page) {
      this.options.currentPage = page;
      this.getLeadsByForms();
    },
  },
  computed: {
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>

<style scoped>

</style>
