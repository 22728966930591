<template>
  <LeadboxDates />
</template>

<script>
// @ is an alias to /src
import LeadboxDates from '@/components/Dates.vue';

export default {
  name: 'LeadsView',
  components: {
    LeadboxDates,
  },
};
</script>
