<template>
  <LeadboxCalendar />
</template>

<script>
// @ is an alias to /src
import LeadboxCalendar from '@/components/Calendar.vue';

export default {
  name: 'CalendarView',
  components: {
    LeadboxCalendar,
  },
};
</script>
