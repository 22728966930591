import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCacfxaEneoYQVLvEXF9syjGGOarafe9SI',
  authDomain: 'leadbox-a00ae.firebaseapp.com',
  databaseURL: 'https://leadbox-a00ae.firebaseio.com',
  projectId: 'leadbox-a00ae',
  storageBucket: 'leadbox-a00ae.appspot.com',
  messagingSenderId: '120960600796',
  appId: '1:120960600796:web:b3e6703443093c9300c219',
  measurementId: 'G-0HNV6BLKQR',
};

const app = initializeApp(firebaseConfig);

export default getMessaging(app);
