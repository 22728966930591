<template>
  <div class="p-1 w-full">
    <img :src="loadingUrl" v-if="loading" class="img-fluid" alt="loading">
    <a :href="`https://leadbox.ec${media.link}`"
       v-if="!loading && (type === 'file' || type === 'document') &&
       (media.type !== 'product_datasheet' && media.type !== 'document_file')"
       target="_blank">
      <img src="@/assets/download.png" class="img-fluid" alt="documento" style="width: 100%">
    </a>
    <a :href="media.link"
       v-if="!loading && ( media.type === 'product_datasheet' || media.type === 'document_file')"
       target="_blank">
      <img src="@/assets/download.png" class="img-fluid" alt="documento" style="width: 100%">
    </a>
    <a :href="media.link" v-if="!loading && media.type === 'product_video'" target="_blank">
      <img :src="'/backend/images/video.png'" class="img-fluid" alt="video" style="width: 100%">
    </a>
    <a :href="media.link"
       v-if="!loading && (media.type === 'product_image' || media.type === 'product_promo_image'
       || media.type === 'product_quotation_image')"
       target="_blank">
      <img :src="media.link" class="img-fluid" :alt="media.caption" style="width: 100%">
    </a>
    <a :href="ensureLeadboxUrl" v-if="!loading && type === 'sticker'"
       target="_blank">
      <img :src="ensureLeadboxUrl" :alt="media.caption" style="width: 100%">
    </a>
    <a :href="ensureLeadboxUrl" v-if="!loading && type === 'image' && media.type === 'image'"
       target="_blank">
      <img :src="ensureLeadboxUrl" :alt="media.caption" style="width: 100%">
    </a>
    <a :href="media.link" v-if="!loading && type === 'image' && media.type === 'image_file'"
       target="_blank">
      <img :src="media.link" :alt="media.caption" style="width: 100%">
    </a>
    <!--    eslint-disable-next-line-->
    <audio controls v-if="!loading && (type === 'voice' || type === 'audio')">
      <source :src="media.sha256?'https://leadbox.ec/'+media.link:media.link"
              :type="media.mime_type">
      Your browser does not support the audio element.
    </audio>
    <!--    eslint-disable-next-line-->
    <video width="300" height="auto" controls v-if="!loading && type === 'video'">
      <source :src="media.link" :type="media.mime_type">
      Your browser does not support the video tag.
    </video>
    <template v-if="refreshing"> Descargando ...</template>
    <template v-else>
      <div v-if="!media.link && media.type !== 'list'
    && media.type !== 'button'
    && media.type !== 'template'"
           class="p-1 flex cursor-pointer" @click="refresh()" @keydown="refresh()">
        <CloudDownloadIcon class="w-8 h-8 "
                           aria-hidden="true"
                           data-toggle="tooltip" data-placement="top"
                           title="Descargar" alt="Descargar"/>
        Volver a descargar de whatsapp
      </div>
    </template>
  </div>
</template>

<script>
import { CloudDownloadIcon } from '@heroicons/vue/outline';

export default {
  name: 'MediaItem',
  props: {
    type: String,
    media: Object,
    location: String,
  },
  components: {
    CloudDownloadIcon,
  },
  data() {
    return {
      loading: false,
      refreshing: false,
    };
  },
  computed: {
    activeConversation() {
      return this.$store.state.activeConversation;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    image() {
      switch (this.activeConversation.form_provider) {
        case 'facebook-messenger':
          return this.media.link;
        case 'whatsapp':
        case 'dialog360':
        default:
          return `https://leadbox.ec${this.media.link}`;
      }
    },
    ensureLeadboxUrl() {
      const leadboxUrl = 'https://leadbox.ec';

      // Verificar si el string ya contiene 'https://leadbox.ec'
      if (!this.media.link.startsWith(leadboxUrl)) {
        // Si no lo contiene, agregarlo al inicio
        return leadboxUrl + this.media.link;
      }

      // Si ya lo contiene, devolver el string tal como está
      return this.media.link;
    },
  },
  methods: {
    refresh() {
      const payload = {
        uuid: this.media.uuid,
        conversation: this.activeConversation.uuid,
        company: this.activeCompany.uuid,
      };
      this.refreshing = true;
      this.$store.dispatch('messageMedia', payload)
        .then((response) => {
          this.refreshing = false;
          console.log('respuesta media', response);
          this.$emit('get-messages');
        }, (error) => {
          this.refreshing = false;
          console.log('error media', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.refreshing = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error media', error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
