<template>
  <div class="flex hover:bg-gray-500 cursor-pointer text-white md:w-52" @click="selectUser()"
       @keydown="selectUser()" v-bind:class="{ 'bg-gray-500' : isSelected }">
  <div class="pl-1 md:pl-2 pr-1 p-2 w-9/12 text-left text-white break-words text-sidebar">
    {{user.name ? user.name : user.email}}
  </div>
    <div class="p-2 w-fill">
      <div class="bg-green-500 rounded-full text-white p-1 px-2 text-xs">{{user.count}}</div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'AsesorItem',
  props: {
    user: Object,
    selected: Number,
  },
  methods: {
    selectUser() {
      this.$emit('set-user', this.user);
    },
  },
  computed: {
    isSelected() {
      if (this.user) {
        return this.user.id === this.selected;
      }
      return false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.text-sidebar {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}

.bg-gray-500 {
  background-color: #2a6f7f7a;
  border-radius: 5px;
}

.py-1.flex.hover\:bg-gray-500.cursor-pointer.bg-gray-500 {
  background-color: #2A6F7F;
}
.py-1.flex.hover\:bg-gray-500.cursor-pointer.bg-gray-500:hover {
  background-color: #2A6F7F;
}
</style>
