<template>
  <notifications />
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #495057;
}

nav a.router-link-exact-active {
  color: #22d3c5;
}
</style>
