<template>
  <Menu as="div" class="relative inline-block text-left ">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="md:inline-flex justify-center w-full  shadow-sm px-1
        py-1 text-sm font-medium text-white hover:bg-white-50 hidden">
        <div class="flex my-1">
          <img class=" w-8 h-8 rounded-lg mr-2" src="@/assets/whatsapp_logo.png"
               alt="notificaciones de whatsapp">
          <div class="flex flex-col">
           <div class="bg-red-500 rounded-full relative -left-3 text-white p-1 px-2 text-xs">
              {{total}}
            </div>
          </div>
        </div>
        <ChevronDownIcon class="m-1 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
      <MenuButton
        class="inline-flex justify-center w-full  shadow-sm px-1
        pt-2 text-sm font-medium text-white hover:bg-white-50 md:hidden">
        <div class="flex">
          <img class="w-7 mt-2 rounded-lg md:mr-2 mr-0 logo-company"
               src="@/assets/whatsapp_logo.png" alt="notificaciones de whatsapp">
<!--          <div class="flex flex-col">-->
<!--            <div class="bg-red-500 rounded-full text-white px-2 text-xs text-mobile">{{total}}</div>-->
<!--          </div>-->
        </div>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95" v-if="notifications.length>0">
      <MenuItems
        class="origin-top-right absolute left-0 md:mt-2 md:w-80 w-52 max-h-96 z-20 overflow-auto shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none h-max-screen overflow-auto company-name area-message">
        <div class="py-1 w-full ">
          <MenuItem v-for="(notification, key) in notifications" @click="openConversation(notification)">
            <a href="#"
               :class="'block px-4 py-2 text-sm flex border-b border-gray-600'">
              <div class="flex flex-col w-full text-white">
                <p class="m-2 my-1 break-words text-clip text-company">{{ notification.name }}</p>
                <p class="m-2 my-1 break-words text-clip text-company">{{ notification.text }}</p>
                <p class="m-2 my-1 break-normal text-clip">{{ notification.human_date }}</p>
              </div>
            </a>
          </MenuItem>
          <div class="w-full relative bottom-0" v-if="nextPageUrl">
            <div class="m-2 flex rounded-lg cursor-pointer bg-white text-black place-content-center p-2"
                 @click="loadMoreMessageNotifications()" @keydown="loadMoreMessageNotifications()">
              {{ isLoadingMore ? 'Cargando....' : 'ver más' }}
            </div>
          </div>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  ChevronDownIcon,
} from '@heroicons/vue/solid';

export default {
  name: 'MessageNotificationsDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  props: {
    type: String,
  },
  data() {
    return {
      isLoadingMore: false,
    };
  },
  methods: {
    loadMoreMessageNotifications() {
      if (this.isLoadingMore) return;
      const payload = {
        company: this.activeCompany.uuid,
        url: this.nextPageUrl,
      };
      this.isLoadingMore = true;
      this.$store.dispatch('nextPageMessageNotifications', payload)
        .then((response) => {
          console.log('respuesta nextPageMessageNotifications', response);
          this.isLoadingMore = false;
        }, (error) => {
          this.isLoadingMore = false;
          console.log('error nextPageMessageNotifications', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMore = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error nextPageMessageNotifications', error);
        });
    },
    openConversation(notification) {
      this.$emit('open-conversation', notification.conversation_uuid);
    },
  },
  computed: {
    notifications() {
      return this.$store.state.messageNotifications;
    },
    total() {
      return this.$store.state.total_mn;
    },
    nextPageUrl() {
      return this.$store.state.next_page_url_mn;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>
<style>
  .company-name{
    background: #2a323c;
    color: #fff;
  }
  .text-company{
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
  }

  .text-company span {
    color: #FFCF01;
  }
  button:focus {
    outline: none !important;
  }

  /*Scroll*/
  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: #8ad2e8;
  }

  .overflow-auto::-webkit-scrollbar-track {
    background: #2a323c;
  }

  .overflow-auto::-webkit-scrollbar {
    width: 5px;
  }
  .logo-company{
    margin-top: -5px;
  }
  .text-mobile {
    position: absolute;
    left: 21px;
    top: 7px;
    font-size: 9px;
    padding: 2px;
    height: 20px;
    width: 21px;
  }

  @media (max-width: 600px) {
    .area-message {
      left: -115px;
    }
    .area-notification {
      left: -71px;
    }
  }
</style>
