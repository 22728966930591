<template>
  <div class="flex flex-col m-2 bg-white p-1 cursor-pointer border-gray-400 rounded-md"
       v-bind:class="{
        'border-r-4' : date.uuid === active?.uuid,
        'awarded' : date.attended,
        'bg-red-100' : date.cancelled,
       'warn' : date.already_attended_by }">
    <div class="flex">
      <div class="md:w-8 w-6 my-4 rounded-full">
        <!--      eslint-disable-next-line-->
        <input type="checkbox" class="my-5 w-5" :checked="isSelected" @change="checkDate()">
      </div>
      <!--      eslint-disable-->
      <div class="flex-grow p-1 w-full" @click="selectDate()">
        <div class="flex text-sm justify-between w-full break-words">
          <b class="text-ellipsis break-normal">{{ date.patient_name }}</b>
          <div class="flex">
            <div class="flex p-1 rounded-lg text-white mx-1"
                 v-if="date.contact && date.appointment_count>1"
                 :style="{backgroundColor: '#f90669'}">
              <CalendarIcon class="w-4  cursor-pointer" aria-hidden="true"/>
              <p class="text-md ml-1"><b>{{date.appointment_count}}</b></p>
            </div>
            <div class="flex p-1 rounded-lg text-white" v-if="date.contact && date.lead_count>1"
                 :style="{backgroundColor: '#2A6F7F'}">
              <UsersIcon class="w-4  cursor-pointer" aria-hidden="true"/>
              <p class="text-md ml-1"><b>{{date.lead_count}}</b></p>
            </div>
          </div>
        </div>
        <div class="flex">
          <img class="w-7 h-7 rounded-lg my-2" :src="source" alt="user">
          <div class="flex-grow">
            <div class="flex-col text-xs justify-between m-2 my-1 break-words">
              <div v-if="date.connection_name"> {{ date.connection_name }}</div>
              <div v-if="date.medical_center_city"> {{ date.medical_center_city }}
                {{ date.medical_center_name }}</div>
              <div v-if="date.doctor_service"> {{ date.doctor_service }}</div>
              <div v-if="date.doctor_name"> {{ date.doctor_name }}</div>
            </div>
          </div>
        </div>
        <div class="flex text-xs justify-between m-1 break-words">
          <div><b>Fecha de Creación:</b> {{ creation_date }}</div>
        </div>
        <div class="flex text-xs justify-between m-1 break-words">
          <div><b>Fecha y Hora:</b> {{ dateAttention }}</div>
        </div>
        <div class="flex text-xs justify-between m-1 break-words">
          <div><b>Inicio:</b> {{ date.start_time }} <b>Fin:</b> {{ date.end_time }}</div>
        </div>

        <div v-if="date.status" class="flex text-xs justify-between m-1 break-words">
          <div><b>Estado: </b>{{ date.status.name }}</div>
        </div>
        <div v-if="date.user_name" class="flex text-xs justify-between m-1 break-words">
          <div><b>Asignado a: </b>{{ date.user_name }}</div>
        </div>
        <div v-if="date.referral_id" class="flex text-xs justify-between m-1 break-words">
          <div><b>Anuncio WhatsApp: </b>{{ date.referral_headline }}</div>
        </div>
        <div v-if="date.ad_name" class="flex text-xs justify-between m-1 break-words">
          <div><b>Anuncio dategen: </b>{{ date.ad_name }}</div>
        </div>
        <div v-if="date.campaign_name" class="flex text-xs justify-between m-1 break-words">
          <div><b>Campaña dategen: </b>{{ date.campaign_name }}</div>
        </div>
        <div v-if="date.campaign_id" class="flex text-xs justify-between m-1 break-words">
          <div><b>Campaña facebook: </b>{{ date.campaign_id }}</div>
        </div>
        <div v-if="date.campaign_internal_id" class="flex text-xs justify-between m-1 break-words">
          <div><b>Campaña: </b>{{ date.campaign_internal_id }}</div>
        </div>

      </div>
    </div>
<!--    <div class="flex rounded-b-xl" v-if="probabilities.length>0">-->
<!--      &lt;!&ndash;           eslint-disable-next-line&ndash;&gt;-->
<!--      <template v-for="probability in probabilities" v-bind:key="probability.id">-->
<!--        <div :style="{backgroundColor:probability.color}" class="h-1 flex-1 "-->
<!--             v-bind:class="{'opacity-100': date.sale_probability >= probability.min && date.sale_probability <= probability.max,-->
<!--             'opacity-25': date.sale_probability <= probability.min ||-->
<!--           date.sale_probability >= probability.max}">-->
<!--        </div>-->
<!--      </template>-->

<!--    </div>-->
  </div>
</template>

<script>
import {
  UsersIcon,
  CalendarIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'dateItem',
  components: {
    UsersIcon,
    CalendarIcon,
  },
  props: {
    date: Object,
    selected: Object,
  },
  computed: {
    probabilities() {
      return this.$store.state.probabilities;
    },
    dateAttention() {
      if (!this.date) return 'No cita';
      return `${this.date.date}`;
    },
    creation_date() {
      if (!this.date) return 'No cita';
      return this.date.creation_date.date;
    },
    source() {
      let url = '';
      switch (this.date.form_provider) {
        case 'manychat':
          // eslint-disable-next-line global-require
          url = require('@/assets/logo-messenger.png');
          break;
        case 'manychat-instagram':
          // eslint-disable-next-line global-require
          url = require('@/assets/logo-instagram.png');
          break;
        case 'typeform':
          // eslint-disable-next-line global-require
          url = require('@/assets/typeform.svg');
          break;
        case 'elementor':
          // eslint-disable-next-line global-require
          url = require('@/assets/elementor.png');
          break;
        case 'facebook_leadgen':
          switch (this.date.platform) {
            case 'ig':
            case 'in':
              // eslint-disable-next-line global-require
              url = require('@/assets/logo-instagram.png');
              break;
            case 'fb':
            default:
              // eslint-disable-next-line global-require
              url = require('@/assets/facebook-leadgen.png');
              break;
          }
          break;
        case 'wordpress':
          // eslint-disable-next-line global-require
          url = require('@/assets/wordpress.png');
          break;
        case 'drupal':
          // eslint-disable-next-line global-require
          url = require('@/assets/drupal.png');
          break;
        case 'landing':
          // eslint-disable-next-line global-require
          url = require('@/assets/landing.png');
          break;
        case 'email':
          // eslint-disable-next-line global-require
          url = require('@/assets/email.png');
          break;
        case 'excel':
          // eslint-disable-next-line global-require
          url = require('@/assets/excel.png');
          break;
        case 'add-sales':
          // eslint-disable-next-line global-require
          url = require('@/assets/add-sales.jpg');
          break;
        case 'patiotuerca':
          // eslint-disable-next-line global-require
          url = require('@/assets/patiotuerca.webp');
          break;
        case 'leadbox-chat':
          // eslint-disable-next-line global-require
          url = require('@/assets/lead.png');
          break;
        case 'leadbox-dates':
          // eslint-disable-next-line global-require
          url = require('@/assets/dates.png');
          break;
        case 'ecommerce':
          // eslint-disable-next-line global-require
          url = require('@/assets/shop-cart.png');
          break;
        case 'channels':
          if (this.date.channel_icon) {
            url = `https://leadbox.ec/storage/${this.date.channel_icon}`;
          } else {
            // eslint-disable-next-line global-require
            url = require('@/assets/filter.png');
          }

          break;
        case 'dialog360':
        case 'whatsapp':
        case 'manychat-whatsapp':
        case 'autoresponder':
        case 'autoresponder-fundefa':
        default:
          // eslint-disable-next-line global-require
          url = require('@/assets/whatsapp.png');
          break;
      }
      return url;
    },
    isSelected() {
      return this.selected.includes(this.date.uuid);
    },
    active() {
      return this.$store.state.activeDate;
    },
  },
  methods: {
    checkDate() {
      this.$emit('check-clicked', this.date);
    },
    selectDate() {
      this.$emit('selected-date', this.date.uuid);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.warn{
  background-color: #ffe4d1;
}
.awarded{
  background-color: #f4e1a1;
}
</style>
