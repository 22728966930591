<template>
  <div class="flex flex-col p-3 bg-gray-100 rounded-3xl w-14 absolute right-5 bottom-10
  justify-items-center">
    <template v-for="probability in probabilities" v-bind:key="probability.id">
      <!--      eslint-disable-next-line-->
      <div class="rounded-full w-8 h-8 flex-2 my-1 cursor-pointer"
           :style="{backgroundColor:probability.color}" @click="updateSaleProbability(probability)"
           v-bind:class="{'opacity-100': activeLead.sale_probability >= probability.min &&
           activeLead.sale_probability <= probability.max,
           'opacity-25': activeLead.sale_probability <= probability.min ||
           activeLead.sale_probability >= probability.max}">
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SemaphoreItem',
  methods: {
    updateSaleProbability(probability) {
      console.log('guarda', this.activeLead);
      const newLead = this.activeLead;
      newLead.sale_probability = probability.min + 1;
      this.$store.dispatch('setActiveLead', newLead)
        .then(
          (response) => {
            console.log('response message', response);
            this.$emit('update-lead');
          },
          (error) => {
            console.log('error message', error);
          },
        ).catch((error) => {
          console.log('error message', error);
        });
    },
  },
  computed: {
    probabilities() {
      return this.$store.state.probabilities;
    },
    activeLead() {
      return this.$store.state.activeLead;
    },
  },
};
</script>

<style scoped>

</style>
