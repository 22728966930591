<template>
  <Menu as="div" class="relative inline-block text-left min-w-50">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="md:inline-flex justify-center w-full  shadow-sm px-3
        py-1 text-sm font-medium text-white hover:bg-white-50 hidden">
        <template class="flex" v-if="activeForm">
          <img class="w-8 h-8 rounded-lg m-2" :src="sourceForm(activeForm)"
               :alt="activeForm.name + ' logo'">
          <div class="flex flex-col">
            <p class="m-2 my-2 text-company text-left text-sm w-44">{{ activeForm.name }}</p>
          </div>
        </template>
        <template v-else>
          <ClipboardListIcon class="mr-1 ml-2 my-2 w-8 h-8" aria-hidden="true"/>
          <p class="mt-2">Formularios</p>
        </template>
        <ChevronDownIcon class="-mr-1 md:ml-2 ml-1 my-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
      <MenuButton
        class="inline-flex justify-center w-full  shadow-sm px-1
        py-3 text-sm font-medium text-white hover:bg-white-50 md:hidden">
        <template class="flex" v-if="activeForm">
          <img class="w-8 h-8 rounded-lg m-1 logo-company" :src="sourceForm(activeForm)"
               :alt="activeForm.name + ' logo'" @error="useCompanyFallbackImage">
        </template>
        <template v-else>
          <ClipboardListIcon class="mr-1 ml-2 my-2 h-6 w-6" aria-hidden="true"/>
        </template>
        <ChevronDownIcon class="-mr-1 ml-2 my-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute left-0 md:mt-2 md:w-80 w-52 max-h-96 z-20 overflow-auto shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none h-max-screen overflow-auto company-name overflow-x-hidden">
        <div class="py-1 w-full">
          <MenuItem @click="selectActiveForm(null)">
            <a href="#"
               :class="[activeForm === null ? 'bg-gray-900 text-white' : 'text-white', 'block px-4 py-2 text-sm flex border-b border-gray-600']">
              <ClipboardListIcon class="m-2 w-10 h-10" aria-hidden="true"/>
              <div class="flex flex-col text-ellipsis overflow-hidden">
                <p class="m-2 my-1 mt-2 break-normal text-company text-md">TODOS</p>
              </div>
            </a>
          </MenuItem>
          <MenuItem v-for="(form, key) in forms" @click="selectActiveForm(form)">
            <a href="#"
               :class="[form.uuid === activeForm?.uuid ? 'bg-gray-900 text-white' : 'text-white', 'block px-4 py-2 text-sm flex border-b border-gray-600']">
              <img class="mt-0 w-10 h-10 rounded-lg mr-4" :src="sourceForm(form)"
                   :alt="form.name+' logo'" >
              <div class="flex flex-col text-ellipsis overflow-hidden">
                <p class="m-2 my-1 break-normal text-company text-xs">{{ form.name }}</p>
              </div>
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  ChevronDownIcon, ClipboardListIcon,
} from '@heroicons/vue/solid';
import { useCompanyFallbackImage } from '@/utils/mixins';

export default {
  name: 'FormDropDown',
  mixins: [useCompanyFallbackImage],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ClipboardListIcon,
  },
  props: {
    type: String,
  },
  methods: {
    sourceForm(form) {
      let url = '';
      switch (form.provider) {
        case 'manychat':
          // eslint-disable-next-line global-require
          url = require('@/assets/logo-messenger.png');
          break;
        case 'manychat-instagram':
          // eslint-disable-next-line global-require
          url = require('@/assets/logo-instagram.png');
          break;
        case 'typeform':
          // eslint-disable-next-line global-require
          url = require('@/assets/typeform.svg');
          break;
        case 'elementor':
          // eslint-disable-next-line global-require
          url = require('@/assets/elementor.png');
          break;
        case 'facebook_leadgen':
          // eslint-disable-next-line global-require
          url = require('@/assets/facebook-leadgen.png');
          break;
        case 'wordpress':
          // eslint-disable-next-line global-require
          url = require('@/assets/wordpress.png');
          break;
        case 'drupal':
          // eslint-disable-next-line global-require
          url = require('@/assets/drupal.png');
          break;
        case 'landing':
          // eslint-disable-next-line global-require
          url = require('@/assets/landing.png');
          break;
        case 'leadbox-chat':
          // eslint-disable-next-line global-require
          url = require('@/assets/lead.png');
          break;
        case 'leadbox-dates':
          // eslint-disable-next-line global-require
          url = require('@/assets/dates.png');
          break;
        case 'email':
          // eslint-disable-next-line global-require
          url = require('@/assets/email.png');
          break;
        case 'excel':
          // eslint-disable-next-line global-require
          url = require('@/assets/excel.png');
          break;
        case 'patiotuerca':
          // eslint-disable-next-line global-require
          url = require('@/assets/patiotuerca.webp');
          break;
        case 'ecommerce':
          // eslint-disable-next-line global-require
          url = require('@/assets/shop-cart.png');
          break;
        case 'add-sales':
          // eslint-disable-next-line global-require
          url = require('@/assets/add-sales.jpg');
          break;
        case 'channels':
          if (form?.channel?.icon) {
            url = `https://leadbox.ec/storage/${form?.channel?.icon}`;
          } else {
            // eslint-disable-next-line global-require
            url = require('@/assets/filter.png');
          }

          break;
        case 'dialog360':
        case 'whatsapp':
        case 'manychat-whatsapp':
        case 'autoresponder':
        case 'autoresponder-fundefa':
          // eslint-disable-next-line global-require
          url = require('@/assets/whatsapp.png');
          break;
        default:
          break;
      }
      return url;
    },
    selectActiveForm(form) {
      this.$store.dispatch('setActiveForm', form)
        .then((response) => {
          console.log('respuesta', response);
          this.$emit('changed-form');
        }, (error) => {
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
  },
  computed: {
    forms() {
      return this.$store.state.forms;
    },
    activeForm() {
      return this.$store.state.activeForm;
    },
    source() {
      let url = '';
      switch (this.activeForm.provider) {
        case 'manychat':
          // eslint-disable-next-line global-require
          url = require('@/assets/logo-messenger.png');
          break;
        case 'manychat-instagram':
          // eslint-disable-next-line global-require
          url = require('@/assets/logo-instagram.png');
          break;
        case 'typeform':
          // eslint-disable-next-line global-require
          url = require('@/assets/typeform.svg');
          break;
        case 'elementor':
          // eslint-disable-next-line global-require
          url = require('@/assets/elementor.png');
          break;
        case 'facebook_leadgen':
          // eslint-disable-next-line global-require
          url = require('@/assets/facebook-leadgen.png');
          break;
        case 'wordpress':
          // eslint-disable-next-line global-require
          url = require('@/assets/wordpress.png');
          break;
        case 'drupal':
          // eslint-disable-next-line global-require
          url = require('@/assets/drupal.png');
          break;
        case 'landing':
          // eslint-disable-next-line global-require
          url = require('@/assets/landing.png');
          break;
        case 'email':
          // eslint-disable-next-line global-require
          url = require('@/assets/email.png');
          break;
        case 'excel':
          // eslint-disable-next-line global-require
          url = require('@/assets/excel.png');
          break;
        case 'patiotuerca':
          // eslint-disable-next-line global-require
          url = require('@/assets/patiotuerca.webp');
          break;
        case 'ecommerce':
          // eslint-disable-next-line global-require
          url = require('@/assets/shop-cart.png');
          break;
        case 'channels':
          if (this.activeForm?.channel?.icon) {
            url = `https://leadbox.ec/storage/${this.activeForm?.channel?.icon}`;
          } else {
            // eslint-disable-next-line global-require
            url = require('@/assets/filter.png');
          }

          break;
        case 'dialog360':
        case 'whatsapp':
        case 'manychat-whatsapp':
        case 'autoresponder':
        case 'autoresponder-fundefa':
          // eslint-disable-next-line global-require
          url = require('@/assets/whatsapp.png');
          break;
        default:
          break;
      }
      return url;
    },
  },
};
</script>
<style>
  .company-name{
    background: #2a323c;
    color: #fff;
  }
  .text-company{
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
  }

  .text-company span {
    color: #FFCF01;
  }
  button:focus {
    outline: none !important;
  }
  div#headlessui-menu-items-33 {
    margin-left: -49px;
  }
  /*Scroll*/
  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: #8ad2e8;
  }

  .overflow-auto::-webkit-scrollbar-track {
    background: #2a323c;
  }

  .overflow-auto::-webkit-scrollbar {
    width: 5px;
  }
  .logo-company{
    margin-top: -5px;
  }
</style>
