const useAvatarFallbackImage = {
  methods: {
    useAvatarFallbackImage(event) {
      const { target } = event;
      // eslint-disable-next-line global-require
      target.src = require('@/assets/default-profile.svg');
    },
  },
};
const useCompanyFallbackImage = {
  methods: {
    useCompanyFallbackImage(event) {
      const { target } = event;
      // eslint-disable-next-line global-require
      target.src = require('@/assets/company_icon.jpg');
    },
  },
};

// eslint-disable-next-line import/prefer-default-export
export { useAvatarFallbackImage, useCompanyFallbackImage };
