<template>
  <ul class="pagination">
    <li class="pagination-item mx-1 border-gray-400 border-2 rounded-md">
      <button
        class="p-3"
        type="button"
        @click="onClickFirstPage()"
        :disabled="isInFirstPage"
      >
        Primera
      </button>
    </li>

    <li class="pagination-item mx-1 border-gray-400 border-2 rounded-md">
      <button
        class="p-3"
        type="button"
        @click="onClickPreviousPage()"
        :disabled="isInFirstPage"
      >
        Anterior
      </button>
    </li>

    <!-- Visible Buttons Start -->

    <li
      v-for="page in pages" v-bind:key="page"
      class="pagination-item mx-1 border-gray-400 border-2 rounded-md"
    >
      <button
        class="p-3 px-4"
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
      >
        {{ page.name }}
      </button>
    </li>

    <!-- Visible Buttons End -->

    <li class="pagination-item mx-1 border-gray-400 border-2 rounded-md">
      <button
        class="p-3"
        type="button"
        @click="onClickNextPage()"
        :disabled="isInLastPage"
      >
        Siguiente
      </button>
    </li>

    <li class="pagination-item mx-1 border-gray-400 border-2 rounded-md">
      <button
        class="p-3"
        type="button"
        @click="onClickLastPage()"
        :disabled="isInLastPage"
      >
        Ultima
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PaginationItem',
  displayName: 'Pagination',
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 10,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1;
      }

      // When on the last page
      // if (this.currentPage === this.totalPages) {
      //   return this.totalPages - this.maxVisibleButtons;
      // }

      // When inbetween
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = 1;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        // eslint-disable-next-line no-plusplus
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }

      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      console.log('onClickFirstPage');
      this.$emit('page-changed', 1);
    },
    onClickPreviousPage() {
      console.log('onClickFirstPage');
      this.$emit('page-changed', this.currentPage - 1);
    },
    onClickPage(page) {
      console.log('onClickPreviousPage');
      this.$emit('page-changed', page);
    },
    onClickNextPage() {
      console.log('onClickNextPage');
      this.$emit('page-changed', this.currentPage + 1);
    },
    onClickLastPage() {
      console.log('onClickLastPage');
      this.$emit('page-changed', this.totalPages);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<style>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.active {
  background-color: #22d3c5;
  color: #ffffff;
}
</style>
