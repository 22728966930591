<template>
  <Menu as="div" class="relative inline-block text-left h-full">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="inline-flex justify-center w-full h-full text-sm cursor-pointer font-medium
        text-gray-700">
        <DotsVerticalIcon class="w-6 m-1 " aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-teal-900 ring-1
        ring-black ring-opacity-5 focus:outline-none z-10">
        <div class="py-1">
          <MenuItem v-slot="{ active }" @click="refresh()" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <RefreshIcon class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm">Refrescar</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="inGroup()" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <UserIcon class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm">Ver en grupo</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="create()" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <PlusIcon class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm">Crear lead</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setOrder()" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <ArrowDownIcon v-if="order === 'ASC'" class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <ArrowUpIcon v-if="order === 'DESC'" class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm ">{{order === 'DESC' ? 'Ver más antiguas' : 'Ver más nuevas'}}</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="select()" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' : 'text-white', 'block p-2 text-sm flex']">
              <ClipboardCheckIcon class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="my-1 text-sm">{{all ? 'Deseleccionar todas' : 'Seleccionar todas'}}</p>
            </div>
          </MenuItem>

        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import {
  DotsVerticalIcon, RefreshIcon, ArrowUpIcon, ArrowDownIcon, ClipboardCheckIcon, UserIcon, PlusIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'LeadOptionsDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    // eslint-disable-next-line vue/no-unused-components
    ChevronDownIcon,
    DotsVerticalIcon,
    RefreshIcon,
    ArrowUpIcon,
    ArrowDownIcon,
    ClipboardCheckIcon,
    UserIcon,
    PlusIcon,
  },
  props: {
    order: String,
    all: Boolean,
  },
  methods: {
    select() {
      this.$emit('select-all');
    },
    refresh() {
      this.$emit('refresh');
    },
    create() {
      this.$emit('open-create');
    },
    inGroup() {
      // this.$emit('open-group');
    },
    setOrder() {
      if (this.order === 'DESC') {
        this.$emit('change-order', 'ASC');
      } else {
        this.$emit('change-order', 'DESC');
      }
    },
  },
};
</script>
<style>
  .bg-teal-900 {
    background: #2A6F7F;
  }
</style>
