<template>
  <div class="flex flex-col m-2 bg-white p-1 cursor-pointer border-gray-400 rounded-md"
       v-bind:class="{
        'border-r-4' : lead.uuid === active?.uuid,
        'awardepd' : lead.awarded,
        'bg-amber-100' : lead.awarded || lead.week_awarded,
       'warn' : lead.already_attended_by }">
    <div class="flex">
      <div class="md:w-8 w-6 my-4 rounded-full">
        <!--      eslint-disable-next-line-->
        <input type="checkbox" class="my-5 w-5" :checked="isSelected" @change="checkLead()">
      </div>
      <!--      eslint-disable-->
      <div class="flex-grow p-1 w-full" @click="selectLead()">
        <div class="flex text-sm justify-between w-full break-words">
          <b class="text-ellipsis break-normal">{{ lead.name }}</b>
          <div class="flex">
            <div class="flex p-1 rounded-lg text-white mx-1"
                 v-if="lead.contact && lead.appointment_count>1"
                 :style="{backgroundColor: '#f90669'}">
              <CalendarIcon class="w-4  cursor-pointer" aria-hidden="true"/>
              <p class="text-md ml-1"><b>{{lead.appointment_count}}</b></p>
            </div>
            <div class="flex p-1 rounded-lg text-white" v-if="lead.contact && lead.lead_count>1"
                 :style="{backgroundColor: '#2A6F7F'}">
              <UsersIcon class="w-4  cursor-pointer" aria-hidden="true"/>
              <p class="text-md ml-1"><b>{{lead.lead_count}}</b></p>
            </div>
          </div>
        </div>
        <div class="flex">
          <img class="w-7 h-7 rounded-lg" :src="source" alt="user">
          <div class="flex-grow">
            <div class="flex text-xs justify-between m-2 my-1 break-words">
              <div>{{ lead.form_name }}</div>
            </div>
          </div>
        </div>
        <div class="flex text-xs justify-between m-1 break-words">
          <div>{{ date }}</div>
        </div>
        <div v-if="lead.status" class="flex text-xs justify-between m-1 break-words">
          <div><b>Estado: </b>{{ lead.status.name }}</div>
        </div>
        <div v-if="lead.user_name" class="flex text-xs justify-between m-1 break-words">
          <div><b>Asignado a: </b>{{ lead.user_name }}</div>
        </div>
        <div v-if="lead.referral_id" class="flex text-xs justify-between m-1 break-words">
          <div><b>Anuncio WhatsApp: </b>{{ lead.referral_headline }}</div>
        </div>
        <div v-if="lead.ad_name" class="flex text-xs justify-between m-1 break-words">
          <div><b>Anuncio leadgen: </b>{{ lead.ad_name }}</div>
        </div>
        <div v-if="lead.campaign_name" class="flex text-xs justify-between m-1 break-words">
          <div><b>Campaña leadgen: </b>{{ lead.campaign_name }}</div>
        </div>
        <div v-if="lead.campaign_id" class="flex text-xs justify-between m-1 break-words">
          <div><b>Campaña facebook: </b>{{ lead.campaign_id }}</div>
        </div>
        <div v-if="lead.campaign_internal_id" class="flex text-xs justify-between m-1 break-words">
          <div><b>Campaña: </b>{{ lead.campaign_internal_id }}</div>
        </div>
        <div v-if="lead.awarded" class="flex text-sm justify-between m-1 break-words">
          <div>
            <SparklesIcon class="w-5" aria-hidden="true" style="display: inline-block"/>
            <b >Premiado</b>
          </div>
        </div>
        <div v-if="lead.week_awarded" class="flex text-sm justify-between m-1 break-words">
          <div>
            <SparklesIcon class="w-5" aria-hidden="true" style="display: inline-block"/>
            <b >Premiado Semanal</b>
          </div>
        </div>

      </div>
    </div>
    <div class="flex rounded-b-xl" v-if="probabilities.length>0">
      <!--           eslint-disable-next-line-->
      <template v-for="probability in probabilities" v-bind:key="probability.id">
        <div :style="{backgroundColor:probability.color}" class="h-1 flex-1 "
             v-bind:class="{'opacity-100': lead.sale_probability >= probability.min && lead.sale_probability <= probability.max,
             'opacity-25': lead.sale_probability <= probability.min ||
           lead.sale_probability >= probability.max}">
        </div>
      </template>

    </div>
  </div>
</template>

<script>
import {
  UsersIcon, SparklesIcon, CalendarIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'LeadItem',
  components: {
    UsersIcon,
    SparklesIcon,
    CalendarIcon,
  },
  props: {
    lead: Object,
    selected: Object,
  },
  computed: {
    probabilities() {
      return this.$store.state.probabilities;
    },
    date() {
      if (!this.lead) return 'No date';
      return this.lead.created_at.formated_date;
    },
    source() {
      let url = '';
      switch (this.lead.form_provider) {
        case 'manychat':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/logo-messenger.png');
          break;
        case 'manychat-instagram':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/logo-instagram.png');
          break;
        case 'typeform':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/typeform.svg');
          break;
        case 'elementor':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/elementor.png');
          break;
        case 'facebook_leadgen':
          switch (this.lead.platform) {
            case 'ig':
            case 'in':
              // eslint-disable-next-line global-require,import/no-unresolved
              url = require('@/assets/logo-instagram.png');
              break;
            case 'fb':
            default:
              // eslint-disable-next-line global-require,import/no-unresolved
              url = require('@/assets/facebook-leadgen.png');
              break;
          }
          break;
        case 'wordpress':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/wordpress.png');
          break;
        case 'drupal':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/drupal.png');
          break;
        case 'landing':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/landing.png');
          break;
        case 'email':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/email.png');
          break;
        case 'excel':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/excel.png');
          break;
        case 'add-sales':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/add-sales.jpg');
          break;
        case 'patiotuerca':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/patiotuerca.webp');
          break;
        case 'leadbox-chat':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/lead.png');
          break;
        case 'leadbox-dates':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/dates.png');
          break;
        case 'ecommerce':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/shop-cart.png');
          break;
        case 'channels':
          if (this.lead.channel_icon) {
            url = `https://leadbox.ec/storage/${this.lead.channel_icon}`;
          } else {
            // eslint-disable-next-line global-require,import/no-unresolved
            url = require('@/assets/filter.png');
          }

          break;
        case 'dialog360':
        case 'whatsapp':
        case 'manychat-whatsapp':
        case 'autoresponder':
        case 'autoresponder-fundefa':
          // eslint-disable-next-line global-require,import/no-unresolved
          url = require('@/assets/whatsapp.png');
          break;
        default:
          break;
      }
      return url;
    },
    isSelected() {
      return this.selected.includes(this.lead.uuid);
    },
    active() {
      return this.$store.state.activeLead;
    },
  },
  methods: {
    checkLead() {
      this.$emit('check-clicked', this.lead);
    },
    selectLead() {
      this.$emit('selected-lead', this.lead.uuid);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.warn{
  background-color: #ffe4d1;
}
.awarded{
  background-color: #f4e1a1;
}
</style>
