<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton
        class="justify-center w-full rounded-md border border-gray-500
        shadow-sm px-4 py-1 text-white hover:bg-gray-800 md:inline-flex hidden">
        <img class="w-8 rounded-lg" :src="'https://leadbox.ec/storage/'+activeUser.avatar"
             :alt="activeUser.first_name + ' logo'" @error="useAvatarFallbackImage">
        <p class="m-1 md:m-2 text-xs md:text-md ">
          {{ activeUser.first_name + ' ' + activeUser.last_name }}
        </p>
        <ChevronDownIcon class="-mr-1 ml-2 my-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
    </div>
    <div>
      <MenuButton
        class="inline-flex justify-center w-full rounded-md border border-gray-500
        shadow-sm px-0 py-0 text-sm font-medium text-white hover:bg-gray-800 md:hidden flex mt-2">
        <MenuIcon class="m-1 h-7 w-7 text-white" aria-hidden="true"/>
<!--        <ChevronDownIcon class="ml-0 m-1 my-2 h-5 w-5" aria-hidden="true"/>-->
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute right-3 mt-2 w-40 rounded-md shadow-lg bg-white ring-1
        ring-black ring-opacity-5 focus:outline-none z-50">
        <div class="py-1">
          <MenuItem v-slot="{ active }" @click="goToLeadbox()" class="cursor-pointer flex">
            <div :class="[active ? 'bg-gray-100 text-gray-900 flex' : 'text-gray-700',
               'block px-2 py-2 text-sm']">
              <CogIcon class="w-4 m-1 cursor-pointer" aria-hidden="true"/>
              <p class="my-1 text-sm">Administración</p>
            </div>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }" @click="goToCalendar()" class="cursor-pointer flex">
            <div :class="[active ? 'bg-gray-100 text-gray-900 flex' : 'text-gray-700',
               'block px-2 py-2 text-sm']">
              <CalendarIcon class="w-4 m-1 cursor-pointer" aria-hidden="true"/>
              <p class="my-1 text-sm">Mi calendario</p>
            </div>
          </MenuItem>
        </div>
        <div class="py-1">
          <MenuItem v-slot="{ active }" @click="closeSession()" class="cursor-pointer flex">
            <div :class="[active ? 'bg-gray-100 text-gray-900 flex' : 'text-gray-700',
               'block px-2 py-2 text-sm']">
              <LogoutIcon class="w-4 m-1 cursor-pointer" aria-hidden="true"/>
              <p class="my-1 text-sm">Cerrar Sesión</p>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  ChevronDownIcon, MenuIcon, LogoutIcon, CalendarIcon, CogIcon,
} from '@heroicons/vue/solid';
import { useAvatarFallbackImage } from '@/utils/mixins';

export default {
  name: 'DropDown',
  mixins: [useAvatarFallbackImage],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    MenuIcon,
    LogoutIcon,
    CalendarIcon,
    CogIcon,
  },
  methods: {
    goToCalendar() {
      this.$router.push('/calendar').then(() => {
      }).catch((error) => {
        console.log('error', error);
      });
    },
    goToLeadbox() {
      window.open('https://leadbox.ec/app', '_blank');
    },
    closeSession() {
      this.clearLeads();
      this.clearConversations();
      this.$router.push('/').then(() => {
        localStorage.removeItem('userInfo');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('companyUuid');
      }).catch((error) => {
        console.log('error', error);
      });
    },
    clearLeads() {
      this.$store.dispatch('clearLeads', {})
        .then((response) => {
          console.log('res clearLeads', response);
        }, (error) => {
          console.log('error clearLeads', error);
        })
        .catch((error) => {
          console.log('error clearLeads', error);
        });
    },
    clearConversations() {
      this.$store.dispatch('clearConversations', {})
        .then((response) => {
          console.log('res clearConversations', response);
        }, (error) => {
          console.log('error clearConversations', error);
        })
        .catch((error) => {
          console.log('error clearConversations', error);
        });
    },
  },
  computed: {
    activeUser() {
      return this.$store.state.activeUser;
    },
  },
};
</script>
<style>
  div#headlessui-menu-items-11 {
    z-index: 10;
  }
</style>
