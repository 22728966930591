<template>
  <div class="text-center font-sans  shadow-lg w-full p-8">
    <h2 class="font-bold text-2xl">Grabar mensaje de voz</h2>
    <div>
      <icon-button
        :style="{ 'border-color': buttonColor }"
        :class="buttonClass"
        v-if="recording"
        name="stop"
        @click="toggleRecording"
      />
      <icon-button
        :style="{ 'border-color': buttonColor }"
        :class="buttonClass"
        v-else
        name="mic"
        @click="toggleRecording"
      />
    </div>
    <div>{{ recordedTime }}</div>
    <div class="text-sm font-bold">{{ successMessage }}</div>
    <div class="text-sm">{{ instructionMessage }}</div>
    <div class="text-sm text-red-400">{{ errorMessage }}</div>
    <figure class="mt-8">
<!--      eslint-disable-next-line-->
      <audio controls :src="recordedAudio" type="audio/mpeg" class="mx-auto">
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <figcaption class="text-sm mt-2">Escucha tu grabación antes de enviar.</figcaption>
    </figure>
    <template v-if="isLoading">
      <div class="text-sm font-bold">CARGANDO .....</div>
    </template>
    <template v-else>
      <submit-button @submit="sendData" :color="buttonColor" />
    </template>
  </div>
</template>

<script>
import Recorder from '@/lib/recorder';
import convertTimeMMSS from '@/lib/utils';
import IconButton from './IconButton.vue';
import SubmitButton from './SubmitButton.vue';

const INSTRUCTION_MESSAGE = 'Presiona el micrófono para grabar mensaje.';
const INSTRUCTION_MESSAGE_STOP = 'Presiona nuevamente para detener la grabación.';
const ERROR_MESSAGE = 'Falla al usar el micrófono. Por favor actualice la página e intente nuevamente, permitiendo el uso del micrófono.';
const SUCCESS_MESSAGE = 'Audio correctamente grabado!';
const SUCCESS_MESSAGE_SUBMIT = 'Mensaje de audio enviado correctamente!';
const ERROR_SUBMITTING_MESSAGE = 'Error al enviar el mensaje de audio! Inténtelo nuevamente mas tarde.';
const MP3_FORMAT = 'mp3';
export default {
  name: 'AudioWidget',
  props: {
    // in minutes
    time: { type: Number, default: 1 },
    bitRate: { type: Number, default: 128 },
    sampleRate: { type: Number, default: 44100 },
    backendEndpoint: { type: String, default: '' },
    buttonColor: { type: String, default: 'green' },
    // callback functions
    afterRecording: { type: Function },
    successfulUpload: { type: Function },
    failedUpload: { type: Function },
  },
  data() {
    return {
      recording: false,
      recordedAudio: null,
      recordedBlob: null,
      recorder: null,
      successMessage: null,
      errorMessage: null,
      isLoading: false,
      instructionMessage: INSTRUCTION_MESSAGE,
    };
  },
  computed: {
    buttonClass() {
      return 'mx-auto h-14 w-14 fill-current text-black cursor-pointer rounded-50 border-2'
        + 'm-4 p-2';
    },
    recordedTime() {
      if (this.time && this.recorder?.duration >= this.time * 60) {
        this.toggleRecording();
      }
      return convertTimeMMSS(this.recorder?.duration);
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
  components: {
    IconButton,
    SubmitButton,
  },
  beforeUnmount() {
    if (this.recording) {
      this.stopRecording();
    }
  },
  methods: {
    toggleRecording() {
      this.recording = !this.recording;
      if (this.recording) {
        this.initRecorder();
      } else {
        this.stopRecording();
      }
    },
    initRecorder() {
      this.recorder = new Recorder({
        micFailed: this.micFailed,
        bitRate: this.bitRate,
        sampleRate: this.sampleRate,
        format: MP3_FORMAT,
      });
      this.recorder.start();
      this.successMessage = null;
      this.errorMessage = null;
      this.instructionMessage = INSTRUCTION_MESSAGE_STOP;
    },
    stopRecording() {
      this.recorder.stop();
      const recordList = this.recorder.recordList();
      this.recordedAudio = recordList[0].url;
      this.recordedBlob = recordList[0].blob;
      if (this.recordedAudio) {
        this.successMessage = SUCCESS_MESSAGE;
        this.instructionMessage = null;
      }
      if (this.afterRecording) {
        this.afterRecording();
      }
    },
    async sendData() {
      if (!this.recordedBlob) {
        return;
      }
      const formData = new FormData();
      formData.append('file', this.recordedBlob);
      this.isLoading = true;

      this.axios.post(
        this.backendEndpoint,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data voice', data);
        this.errorMessage = null;
        this.successMessage = SUCCESS_MESSAGE_SUBMIT;
        this.isLoading = false;
        if (this.successfulUpload) {
          this.successfulUpload(data.data.success);
        }
      })
        .catch(() => {
          this.successMessage = null;
          this.errorMessage = ERROR_SUBMITTING_MESSAGE;
          this.isLoading = false;
          if (this.failedUpload) {
            this.failedUpload();
          }
        });
    },
    micFailed() {
      this.recording = false;
      this.instructionMessage = INSTRUCTION_MESSAGE;
      this.errorMessage = ERROR_MESSAGE;
    },
  },
};
</script>
