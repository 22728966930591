<template>
  <div class="w-full flex flex-col p-2 ">
    <div class="w-full justify-between flex border-leadbox-900 border-b-4 p-2">
      <h1 class="text-xl font-semibold">Leads de conversaciones</h1>
      <button
        class="text-sm font-medium text-gray-700 cursor-pointer bg-gray-200 rounded-md
        border border-black"
        @click="getConversationsByHasLeads">
        <RefreshIcon class="m-2 h-5 w-5" aria-hidden="true"/>
      </button>
    </div>
    <div class="p-4">
      <template v-if="isLoading">
        <div class="w-full flex justify-center">
          <img alt="loading" src="@/assets/loading.gif" class="w-20">
        </div>
      </template>
      <template v-else>
        <div class="flex justify-center py-4">
          <Doughnut :data="data" :options="options" />

        </div>
      </template>
    </div>
  </div>
</template>

<script>

// import PaginationItem from '@/components/general/PaginationItem.vue';
import {
  RefreshIcon,
} from '@heroicons/vue/outline';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import { Doughnut } from 'vue-chartjs';

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: 'PieConversationLeads',
  displayName: 'PieConversationLeads',
  components: { RefreshIcon, Doughnut },
  props: {
    dateValue: Object,
    tab: String,
  },
  data() {
    return {
      connections: [],
      connectionChats: [],
      selectedConnection: null,
      total: 0,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      data: {
        labels: ['Con lead', 'Sin lead'],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ['#77CEFF', '#0079AF'],
          },
        ],
      },
      isLoading: false,
      isDetail: false,
      isLoadingExcel: false,
      isLoadingInside: false,
    };
  },
  mounted() {
    if (this.activeCompany) {
      this.getConversationsByHasLeads();
    }
  },
  watch: {
    tab(value) {
      if (value === 'chat') {
        if (this.activeCompany) {
          this.getConversationsByHasLeads();
        }
      }
    },
    dateValue(val) {
      console.log('val', val);
      this.getConversationsByHasLeads();
    },
    activeCompany(company) {
      if (company) {
        this.getConversationsByHasLeads();
      }
    },
  },
  methods: {
    getConversationsByHasLeads() {
      this.isLoading = true;
      const payload = {
        company: this.activeCompany?.uuid,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
      };
      this.$store.dispatch('reportsConversationsByHasLeads', payload)
        .then((response) => {
          console.log('response reportsConversationsByHasLeads', response);
          this.data.datasets[0].data = response.data.success;
          this.isLoading = false;
          console.log('respuesta reportsConversationsByHasLeads', response);
        }, (error) => {
          console.log('error reportsConversationsByHasLeads', error);
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log('error reportsConversationsByHasLeads', error);
        });
    },
  },
  computed: {
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>

<style scoped>

</style>
