<template>
  <Menu as="div" class="relative inline-block text-left ">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="inline-flex justify-center w-full text-sm cursor-pointer font-medium text-gray-700"
        :class="[location !== null ? 'border-b-4 border-leadbox-600':'']">
        <LocationMarkerIcon class="w-6 m-1 mb-2" aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute right-0 mt-2 w-44 rounded-md shadow-lg bg-teal-900 ring-1
        ring-black ring-opacity-5 focus:outline-none z-10">
        <div class="py-1">
          <MenuItem v-slot="{ active }" @click="setLocation(null)" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' :
            'text-white', 'block p-2 text-sm flex']">
              <CheckIcon v-if="location === null" class="h-5 w-5 m-1 mx-2 " aria-hidden="true"/>
              <p class="m-1 text-sm">Todos</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setLocation({ id:'none' })" class="cursor-pointer">
            <div :class="[active ? 'bg-gray-800 text-white' :
            'text-white', 'block p-2 text-sm flex']">
              <CheckIcon v-if="location?.id === 'none'" class="h-5 w-5 m-1 mx-2 "
                         aria-hidden="true"/>
              <p class="m-1 text-sm">Leads sin ubicación</p>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="setLocation(loc)" class="cursor-pointer"
                    v-for="loc in locations" v-bind:key="loc.id">
            <div :class="[active ? 'bg-gray-800 text-white' :
            'text-white', 'block p-2 text-sm flex']">
              <CheckIcon v-if="location?.id === loc.id" class="h-5 w-5 m-1 mx-2 "
                         aria-hidden="true"/>
              <p class="m-1 text-sm">{{ loc.name }}</p>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import { ChevronDownIcon, CheckIcon } from '@heroicons/vue/solid';
import {
  LocationMarkerIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'LocationsDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    // eslint-disable-next-line vue/no-unused-components
    ChevronDownIcon,
    CheckIcon,
    LocationMarkerIcon,
  },
  props: {
    location: Object,
  },
  methods: {
    setLocation(location) {
      this.$emit('set-location', location);
    },
  },
  computed: {
    locations() {
      return this.$store.state.locations;
    },
  },
};
</script>
<style>
.bg-teal-900 {
  background: #2A6F7F;
}
</style>
