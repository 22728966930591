<template>
  <LeadboxChat />
</template>

<script>
// @ is an alias to /src
import LeadboxChat from '@/components/Chat.vue';

export default {
  name: 'ChatView',
  components: {
    LeadboxChat,
  },
};
</script>
